/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
// Code based on https://www.cssscript.com/calendar-date-picke/

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isArray } from '@ember/array';
import { isPresent } from '@ember/utils';
import { TrackedSet } from 'tracked-built-ins';
import {
  isSame,
  datesBetween,
  lastOfMonth,
  dayOfWeek,
  weekOfYear,
  month as getMonth,
  year as getYear,
  addDays,
  addMonths,
  fromJSDate,
  todayInTimeZone,
  isAfter as isAfterDate,
} from 'my-phorest/utils/local-date-helpers';

function getDaysInMonth(month, year) {
  let firstDayOfMonth = `${year}-${month}-01`;
  let lastDayOfMonth = lastOfMonth(firstDayOfMonth);
  return datesBetween(firstDayOfMonth, lastDayOfMonth, { inclusive: true });
}

export default class UiCalendarComponent extends Component {
  @service intl;
  @service session;

  @tracked currentMonth;
  @tracked currentYear;
  @tracked today;
  selectedDates = new TrackedSet(
    Array.isArray(this.args.center)
      ? this.args.center
      : [this.args.center].filter((el) => !!el)
  );

  constructor() {
    super(...arguments);

    if (this.session.currentUser) {
      let { branchTimeZone } = this.session;
      this.today = todayInTimeZone(branchTimeZone);
    } else {
      this.today = fromJSDate(new Date());
    }

    let year, month;
    if (isPresent(this.args.center)) {
      let center = Array.isArray(this.args.center)
        ? this.args.center[0]
        : this.args.center;
      [year, month] = center.split('-');
    } else {
      [year, month] = this.today.split('-');
    }
    this.currentMonth = month;
    this.currentYear = year;
  }

  get showTodayButton() {
    if (typeof this.args.showTodayButton === 'boolean') {
      return this.args.showTodayButton;
    }
    return true;
  }

  get startOfWeek() {
    return Math.min(parseInt(this.args.startOfWeek ?? 1), 1);
  }

  get isWeekView() {
    return !!this.args.weekStartDate;
  }

  get isWeekSelectable() {
    return this.isWeekView || this.args.multiSelect;
  }

  get listOfAllDaysAsText() {
    let list = [
      this.intl.t('global.short-week-days.sun'),
      this.intl.t('global.short-week-days.mon'),
      this.intl.t('global.short-week-days.tue'),
      this.intl.t('global.short-week-days.wed'),
      this.intl.t('global.short-week-days.thu'),
      this.intl.t('global.short-week-days.fri'),
      this.intl.t('global.short-week-days.sat'),
    ];

    let listLength = list.length;
    let first_x_elements = list.slice(0, this.startOfWeek);
    let remaining_elements = list.slice(this.startOfWeek, listLength);

    return [...remaining_elements, ...first_x_elements];
  }

  get monthNameMap() {
    return {
      '01': this.intl.t('global.months.january'),
      '02': this.intl.t('global.months.february'),
      '03': this.intl.t('global.months.march'),
      '04': this.intl.t('global.months.april'),
      '05': this.intl.t('global.months.may'),
      '06': this.intl.t('global.months.june'),
      '07': this.intl.t('global.months.july'),
      '08': this.intl.t('global.months.august'),
      '09': this.intl.t('global.months.september'),
      10: this.intl.t('global.months.october'),
      11: this.intl.t('global.months.november'),
      12: this.intl.t('global.months.december'),
    };
  }

  get monthName() {
    return this.monthNameMap[this.currentMonth];
  }

  get hasLimitedSelectableDays() {
    return isArray(this.args.selectableDates);
  }

  get currentPage() {
    return `${this.currentYear}-${this.currentMonth}-01`;
  }

  get weeks() {
    let arrayOfDays = getDaysInMonth(this.currentMonth, this.currentYear);
    const firstDayOfMonth = dayOfWeek(arrayOfDays[0]);

    if (firstDayOfMonth > 0) {
      arrayOfDays = Array(firstDayOfMonth - this.startOfWeek)
        .fill(false, 0)
        .concat(arrayOfDays);
    } else if (this.startOfWeek === 1) {
      arrayOfDays = Array(6).fill(false, 0).concat(arrayOfDays);
    }

    let dateElements = [];

    arrayOfDays.forEach((date) => {
      if (date) {
        let dayOfMonth = date.split('-')[2].replace(/^0+/, '');
        let isToday = isSame(date, this.today);
        let isSelected = this.selectedDates.has(date);
        let isSelectable = true;
        if (this.hasLimitedSelectableDays) {
          isSelectable = this.args.selectableDates.includes(date);
        } else if (this.args.disablePastDates) {
          isSelectable = isToday || isAfterDate(date, this.today);
        }

        dateElements.push({
          date,
          dayOfMonth,
          isToday,
          isSelected,
          isSelectable,
        });
      } else {
        dateElements.push({
          date,
          dayOfMonth: '',
          isToday: false,
          isSelected: false,
          isSelectable: false,
        });
      }
    });

    let daysByWeeks = [];
    if (dateElements.length > 0) {
      for (let i = 0, len = dateElements.length; i < len; i += 7) {
        daysByWeeks.push(dateElements.slice(i, i + 7));
      }
    }

    daysByWeeks.forEach((week) => {
      const day = week.filter((day) => !!day.date)[0];
      let date = day.date;

      if (this.startOfWeek === 0) {
        date = addDays(date, 1);
      }

      week.weekNumber = weekOfYear(date);
    });

    return daysByWeeks;
  }

  @action
  skipMonths(months) {
    let nextPage = addMonths(this.currentPage, months);
    this.currentMonth = (getMonth(nextPage) + 1).toString().padStart(2, '0');
    this.currentYear = getYear(nextPage);
    this.args.onMonthSwitch?.(this.currentPage);
  }

  selectWeek(weekNumber) {
    const week = this.weeks.find((w) => w.weekNumber.toString() === weekNumber);
    week.forEach((day) => {
      if (day.isSelectable) {
        if (this.selectedDates.has(day.date)) {
          this.selectedDates.delete(day.date);
        } else {
          this.selectedDates.add(day.date);
        }
      }
    });
  }

  @action
  selectDate(event) {
    const date = event.target.getAttribute('data-date');
    const weekNumber = event.target.getAttribute('data-week');

    if (this.args.multiSelect) {
      if (weekNumber) {
        this.selectWeek(weekNumber);
        return;
      }
      if (this.selectedDates.has(date)) {
        this.selectedDates.delete(date);
      } else {
        this.selectedDates.add(date);
      }
    } else {
      this.selectedDates.clear();
      this.selectedDates.add(date);
    }
    let [year, month] = date.split('-');
    this.currentYear = year;
    this.currentMonth = month;
  }

  @action
  handleClick(event) {
    if (this.args.onSelect) {
      this.selectDate(event);
      if (!this.args.multiSelect) {
        this.args.onSelect([...this.selectedDates][0]);
      }
    }
  }

  @action
  confirm() {
    this.args.onSelect([...this.selectedDates]);
  }

  @action
  goToThisMonth() {
    this.currentMonth = (getMonth(this.today) + 1).toString().padStart(2, '0');
    this.currentYear = getYear(this.today);
    this.args.onMonthSwitch?.(this.currentPage);
  }

  @action
  clear() {
    this.args.onSelect(null);
  }
}

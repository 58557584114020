import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class TooltipService extends Service {
  @tracked text;
  @tracked element;
  @tracked placement;
  @tracked styles;

  @action
  showTooltip(element, text, placement, styles) {
    this.element = element;
    this.text = text;
    this.placement = placement;
    this.styles = styles;
  }

  @action
  hideTooltip() {
    this.text = null;
  }
}

import { modifier } from 'ember-modifier';

import { childNodes } from 'my-phorest/utils/button-icon-helpers';

function iconPosition($icon) {
  let nodeList = childNodes($icon.parentElement);

  if (nodeList.length === 1 && nodeList[0] === $icon) {
    return 'solo';
  }

  if (nodeList[0] === $icon) {
    return 'start';
  }

  if (nodeList[nodeList.length - 1] === $icon) {
    return 'end';
  }
}

export default modifier(
  function buttonIcon($el, positional, { positionClasses }) {
    let position = iconPosition($el);

    let classes = positionClasses[position].trim().split(' ');

    for (let cl of classes) {
      $el.classList.add(cl);
    }
  },
  { eager: false }
);

import { action } from '@ember/object';
import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, task, waitForProperty } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import { TrackedArray } from 'tracked-built-ins';
import updateBasketClient from 'my-phorest/gql/mutations/update-basket-client.graphql';

const getStaffFromAnyBasketItem = (basket) => {
  return basket.items.find((item) => item.staffMember)?.staffMember;
};

export class ProfessionalProduct {
  @tracked quantity;

  constructor({ inventory, name, quantity = 1, id } = {}) {
    this.id = id;
    this.inventory = inventory;
    this.name = name;
    this.quantity = quantity;
  }
}

export default class PurchaseBasketService extends Service {
  @tracked isAppointmentsPurchaseFlow = true;
  @tracked lastChosenStaffMember;
  @tracked packageOptionIdFieldName = null;
  @tracked productsToDeduct = new TrackedArray([]);
  @tracked selectedPackageOptionIds = [];
  @tracked serviceEditPackageIdScope = null;
  @tracked showCourseSlideOver = false;
  @tracked showHotKeysSlideOver = false;
  @tracked showMembershipsSlideOver = false;
  @tracked showOpenSaleSlideOver = false;
  @tracked showProductSlideOver = false;
  @tracked showProductsToDeductSlideOver = false;
  @tracked showQuoteOptionsModal = false;
  @tracked showRewardsSlideOver = false;
  @tracked showSaleFeeSlideOver = false;
  @tracked showServiceSlideOver = false;
  @tracked showStaffMemberSlideOver = false;
  @tracked showUpsellsSlideOver = false;
  @tracked showVoucherSlideOver = false;
  @tracked selectedMembership;

  #confirmCourseSelection;
  #confirmOpenSaleSelection;
  #confirmProductSelection;
  #confirmSaleFeeSelection;
  #confirmServiceOrPackageSelection;
  #confirmVoucherSelection;

  @service router;
  @service paymentSlideOver;
  @service voucherSelector;

  @queryManager apollo;

  reward = null;

  @action
  askForStaffMember() {
    this.showStaffMemberSlideOver = true;
    return new Promise((resolve) => {
      this._staffMember = resolve;
    });
  }

  @action
  chooseStaffMember(staffMember) {
    this._staffMember(staffMember);
    this.showStaffMemberSlideOver = false;
  }

  @action
  askForProduct() {
    this.showProductSlideOver = true;
    return new Promise((resolve) => {
      this.#confirmProductSelection = resolve;
    });
  }

  @action
  addProductToDeduct(product) {
    let index = this.productsToDeduct.findIndex(
      (prod) => prod.id === product.id
    );

    if (index >= 0) {
      this.productsToDeduct[index].quantity++;
    } else {
      this.productsToDeduct.push(new ProfessionalProduct(product));
    }
  }

  @action
  removeProductToDeduct(product) {
    this.productsToDeduct.forEach((prod, i) => {
      if (prod.id === product.id) {
        this.productsToDeduct.splice(i, 1);
      }
    });
  }

  @action
  resetProductsToDeduct() {
    this.productsToDeduct = new TrackedArray([]);
  }

  @action
  closeProductSlideOver() {
    this.showProductSlideOver = false;
    this.#confirmProductSelection();
  }

  @action
  selectProduct(product) {
    this.showProductSlideOver = false;
    this.#confirmProductSelection(product);
  }

  @action
  askForServiceOrPackage(options = {}) {
    let { packageId, selectedPackageOptionIds, packageOptionIdFieldName } =
      options;
    this.showServiceSlideOver = true;
    this.serviceEditPackageIdScope = packageId ?? null;
    this.selectedPackageOptionIds = selectedPackageOptionIds ?? [];
    this.packageOptionIdFieldName = packageOptionIdFieldName ?? null;
    return new Promise((resolve) => {
      this.#confirmServiceOrPackageSelection = resolve;
    });
  }

  @action
  selectPackage(selectedPackage, packageOptions) {
    this.showServiceSlideOver = false;
    this.#confirmServiceOrPackageSelection({
      type: 'package',
      package: selectedPackage,
      packageOptions,
    });
  }

  @action
  selectService(service) {
    this.showServiceSlideOver = false;
    this.#confirmServiceOrPackageSelection({ type: 'service', service });
  }

  @action
  closeServiceSlideOver() {
    this.showServiceSlideOver = false;
    this.#confirmServiceOrPackageSelection();
  }

  @action
  askForVoucher() {
    this.showVoucherSlideOver = true;
    return new Promise((resolve) => {
      this.#confirmVoucherSelection = resolve;
    });
  }

  @action
  selectVoucher(voucher) {
    this.showVoucherSlideOver = false;
    this.#confirmVoucherSelection(voucher);
  }

  @action
  closeVoucherSlideOver() {
    this.showVoucherSlideOver = false;
    this.#confirmVoucherSelection();
    this.#confirmVoucherSelection = null;
  }

  @dropTask
  *createEmptyBasketWithClientTask(client) {
    return yield this.paymentSlideOver.createEmptyBasket.perform({
      clientId: client.id,
    });
  }

  @dropTask
  *onClientChangeTask(basket, chosenClient) {
    yield this.apollo.mutate(
      {
        mutation: updateBasketClient,
        variables: {
          basketId: basket.id,
          clientId: chosenClient.id,
        },
      },
      'basket'
    );
    if (!basket.editedPurchaseId) {
      yield this.paymentSlideOver.removeBasketItemByTypesTask.perform(basket, [
        'ServiceRewardBasketItem',
        'ProductRewardBasketItem',
        'ServiceRewardAppointmentBasketItem',
      ]);
    }
    return basket;
  }

  @action
  askForSaleFee() {
    this.showSaleFeeSlideOver = true;
    return new Promise((resolve) => {
      this.#confirmSaleFeeSelection = resolve;
    });
  }

  @action
  selectSaleFee(saleFee) {
    this.showSaleFeeSlideOver = false;
    this.#confirmSaleFeeSelection(saleFee);
  }

  @action
  closeSaleFeeSlideOver() {
    this.showSaleFeeSlideOver = false;
    this.#confirmSaleFeeSelection();
  }

  @action
  closeOpenSaleSlideOver() {
    this.showOpenSaleSlideOver = false;
    this.#confirmOpenSaleSelection();
  }

  @action
  askForOpenSale() {
    this.showOpenSaleSlideOver = true;
    return new Promise((resolve) => {
      this.#confirmOpenSaleSelection = resolve;
    });
  }

  @action
  selectOpenSale(openSale) {
    this.showOpenSaleSlideOver = false;
    this.#confirmOpenSaleSelection(openSale);
  }

  @action
  askForCourse() {
    this.showCourseSlideOver = true;
    return new Promise((resolve) => {
      this.#confirmCourseSelection = resolve;
    });
  }

  @task
  *askForRewardTask() {
    this.showRewardsSlideOver = true;
    yield waitForProperty(this, 'showRewardsSlideOver', false);
    let reward = this.reward;
    this.reward = null;
    return reward;
  }

  @action
  selectReward(closeSlideOver, reward) {
    this.reward = reward;
    closeSlideOver();
  }

  @action
  selectCourse(course) {
    this.#confirmCourseSelection(course);
  }

  @action
  closeCourseSlideOver() {
    this.showCourseSlideOver = false;
    this.#confirmCourseSelection(false);
  }

  @task
  *askForMembership() {
    this.selectedMembership = undefined;
    this.showMembershipsSlideOver = true;
    yield waitForProperty(this, 'selectedMembership', this.#isObjectOrNull);
    return this.selectedMembership;
  }

  #isObjectOrNull(value) {
    return value === null || typeof value === 'object';
  }

  @action
  selectMembership(membership) {
    this.selectedMembership = membership;
  }

  @action
  closeMembershipsSlideOver() {
    this.selectedMembership = null;
    this.showMembershipsSlideOver = false;
  }

  async assignChosenStaffMember(basket) {
    const stillInVoucherTopUpFlow = Boolean(
      this.voucherSelector.selectedVoucher
    );
    // We must not reset the last chosen staff member here. The voucher top-up
    // flow needs to keep the staff member in order to work because it leaves
    // the basket route in order to go the vouchers list.
    if (stillInVoucherTopUpFlow) return;

    const voucherFlowWasInterrupted = this.showVoucherSlideOver;
    if (voucherFlowWasInterrupted) {
      this.closeVoucherSlideOver();
      return;
    }

    this.lastChosenStaffMember = getStaffFromAnyBasketItem(basket);
    if (!this.lastChosenStaffMember) {
      this.lastChosenStaffMember = await this.askForStaffMember();
    }
  }
}

export const FONT_SIZE = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36]; // [pt]
export const FONT_SIZE_MAX = Math.max(...FONT_SIZE);
export const FONT_SIZE_MIN = Math.min(...FONT_SIZE);
export const FONT_SIZE_FORMATS = FONT_SIZE.map((f) => f + 'pt').join(' ');

const FONTS = [
  ['Arial', 'arial,helvetica,sans-serif'],
  ['Comic Sans MS', 'comic sans ms,sans-serif'],
  ['Courier New', 'courier new,courier'],
  ['Georgia', 'georgia,palatino'],
  ['Helvetica', 'helvetica'],
  ['Tahoma', 'tahoma,arial,helvetica,sans-serif'],
  ['Times New Roman', 'times new roman,times'],
  ['Trebuchet MS', 'trebuchet ms,geneva'],
  ['Verdana', 'verdana,geneva'],
];
export const FONT_FORMATS = FONTS.map((f) => `${f[0]}=${f[1]};`).join('');
export const FONT_SUPPORTED_NAMES = FONTS.map((f) => f[0]);

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class UiLinkButton extends Component {
  get normalizedModels() {
    let models = [];

    if (this.args.models) {
      models = Array.isArray(this.args.models)
        ? this.args.models
        : [this.args.models];
    } else if (this.args.model) {
      models = [this.args.model];
    }

    return models;
  }
}

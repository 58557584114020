/* eslint-disable phorest/moment-import */
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { restartableTask, dropTask } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import { AppointmentEvent } from 'my-phorest/utils/calendar-events';
import {
  evictAppointmentsFromResourcesCalendar,
  updateEvent,
  addEventsToCalendarCache,
} from 'my-phorest/utils/calendar-cache';
import sortAppointments from 'my-phorest/utils/sort-appointments';
import {
  addMoney,
  sumMoneyCollection,
  subtractMoney,
} from 'my-phorest/utils/currency';
import {
  evictAppointments,
  evictBookingHistory,
  evictClientCourseItem,
  evictClientCourses,
} from 'my-phorest/utils/graphql';
import updateAppointment from 'my-phorest/gql/mutations/update-appointment.graphql';
import updateAppointmentsPackageOptions from 'my-phorest/gql/mutations/update-appointments-package-options.graphql';
import updateServiceToPackage from 'my-phorest/gql/mutations/update-service-to-package.graphql';
import deleteAppointments from 'my-phorest/gql/mutations/delete-appointments.graphql';
import swapServiceGroupToService from 'my-phorest/gql/mutations/swap-service-group-to-service.graphql';

const SUPPORTED_SCREENS = [
  'appointment-details',
  'client-create',
  'clients-list',
  'services-list',
  'booking-history',
  'recurrence-details',
  'change-staff',
];
const INITIAL_SCREEN = 'clients-list';

export default class AppointmentSlideOverService extends Service {
  @service access;
  @service fullCalendar;
  @service clientSearch;

  // Data
  @tracked appointments = [];
  @tracked client = null;
  @tracked displayDate = null;
  @tracked showOnlyBookingsWithNotes = false;
  @tracked staffMember = null;

  // Flow controls
  @tracked isOpen = false;
  @tracked screen = INITIAL_SCREEN;

  @queryManager apollo;

  close() {
    this.isOpen = false;
    this._resetState();
  }

  get date() {
    if (!Array.isArray(this.appointments) || this.appointments.length === 0)
      return this.displayDate ?? new Date();

    const earliestAppointment = [...this.appointments].sort(
      sortAppointments
    )[0];
    return new Date(
      `${earliestAppointment.date}T${earliestAppointment.startTime}Z`
    );
  }

  /**
   * Opens AppointmentSlideOver and hydrates it with provided data. Please notice that not all data is strictly needed.
   * Some of the arguments are optional for opening.
   *
   * @param {array} [appointments]
   * @param {object} [client=null]
   * @param {date} date
   * @param {string} [screen]
   * @param {object} staffMember
   */
  open({ appointments, client = null, date, screen, staffMember }) {
    if (Array.isArray(appointments)) {
      this.appointments = appointments;
    }

    if (screen) {
      this.setScreen.perform(screen);
    }

    if (date) {
      this.displayDate = date;
    }

    this.client = client;
    this.staffMember = staffMember;
    this.isOpen = true;
  }

  unpaidAppointments(appointments) {
    return appointments.filter((appointment) => appointment.state !== 'PAID');
  }

  hasUnpaidAppointments(appointments) {
    return this.unpaidAppointments(appointments).length > 0;
  }

  hasPaidAppointments(appointments) {
    return appointments.some((appointment) => appointment.state === 'PAID');
  }

  hasCheckedInAppointments(appointments) {
    return appointments.some(
      (appointment) => appointment.state === 'CHECKED_IN'
    );
  }

  totalPrice(appointments) {
    return sumMoneyCollection(this.unpaidAppointments(appointments), 'price');
  }

  priceSubtotal(appointments) {
    if (appointments.length > 0) {
      const subtotal = subtractMoney(
        this.totalPrice(appointments),
        this.depositSubtotal(appointments)
      );
      return {
        amount: subtotal,
        currency: appointments[0].price.currency,
      };
    } else {
      return 0;
    }
  }

  inHouseDepositAmount(appointments) {
    return sumMoneyCollection(
      this.unpaidAppointments(appointments),
      'depositAmount'
    );
  }

  onlineDepositAmount(appointments) {
    return sumMoneyCollection(
      this.unpaidAppointments(appointments),
      'depositPaid'
    );
  }

  depositSubtotal(appointments) {
    return addMoney(
      this.inHouseDepositAmount(appointments),
      this.onlineDepositAmount(appointments)
    );
  }

  removeAppointmentsFromTrackedData(appointmentIds) {
    let appointmentIdsToRemove = appointmentIds
      .map((id) => this._findAppointmentAndIndexById(id).appointment)
      .filter(Boolean)
      .map((appointment) => appointment.id);

    this.appointments = this.appointments.filter(
      (cachedAppointment) =>
        !appointmentIdsToRemove.includes(cachedAppointment.id)
    );
  }

  @restartableTask
  *setScreen(name) {
    assert(
      `Appointment screen "${name}" is not supported`,
      SUPPORTED_SCREENS.includes(name)
    );

    if (name === 'booking-history') {
      const accessAllowed = yield this.access.requirePermission(
        'clients.view-service-history'
      );
      if (!accessAllowed) {
        return;
      }
    }
    yield (this.screen = name);
  }

  updateClient(client) {
    this.client = client;
  }

  upsertAppointmentInTrackedData(appointment, updateClient) {
    if (!updateClient && this.client?.id !== appointment.clientId) {
      // This method is often called after async action. It can be called for a different state of the appointment slideover.
      // In that case, we are not interested in updating anything.
      return;
    }

    if (updateClient) {
      this.client = appointment.client;
    }

    let { appointment: cachedAppointment, index } =
      this._findAppointmentAndIndexById(appointment.id);

    if (cachedAppointment) {
      let newAppointment = new AppointmentEvent(cachedAppointment);
      for (const property in appointment) {
        if (typeof appointment[property] !== 'undefined') {
          newAppointment[property] = appointment[property];
        }
      }

      this.appointments = [
        ...this.appointments.slice(0, index),
        newAppointment,
        ...this.appointments.slice(index + 1),
      ];
    } else {
      this.appointments = [...this.appointments, appointment];
    }
  }

  _findAppointmentAndIndexById(appointmentId) {
    let appointment = this.appointments.find(
      (appointment) => appointment.id === appointmentId
    );

    if (appointment) {
      let index = this.appointments.indexOf(appointment);
      return { appointment, index };
    }

    return { appointment: null, index: -1 };
  }

  _resetState() {
    this.appointments = [];
    this.client = null;
    this.displayDate = null;
    this.showOnlyBookingsWithNotes = false;
    this.staffMember = null;
    this.screen = INITIAL_SCREEN;
    this.clientSearch.reset();
  }

  @dropTask
  *updateAppointmentTask(variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: updateAppointment,
      variables,
      update: (cache, { data: { updateAppointment } }) => {
        evictBookingHistory(cache);
        const { appointment } = updateAppointment;
        if (appointment) {
          updateEvent(cache, {
            date: appointment.date,
            eventId: appointment.id,
            resourceId: appointment.staffMemberId,
          });

          this.upsertAppointmentInTrackedData(
            new AppointmentEvent(appointment)
          );

          if (appointment.clientCourseItem) {
            let { id: clientCourseItemId } = appointment.clientCourseItem;

            evictClientCourseItem(cache, clientCourseItemId);
          }
        }

        cache.gc();
      },
    });

    return response.updateAppointment;
  }

  @dropTask
  *updatePackageTask(variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: updateAppointmentsPackageOptions,
      variables,
      update: (cache, { data: { updateAppointments } }) => {
        evictBookingHistory(cache);
        if (updateAppointments.appointments) {
          updateAppointments.appointments.forEach((appointment) => {
            updateEvent(cache, {
              date: appointment.date,
              eventId: appointment.id,
              resourceId: appointment.staffMemberId,
            });
          });

          cache.gc();
        }
      },
    });

    return response.updateAppointments;
  }

  @dropTask
  *updateServiceToPackageTask(editedAppointment, variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: updateServiceToPackage,
      variables,
      update: (
        cache,
        { data: { updateServiceAppointmentToServiceGroupAppointment } }
      ) => {
        if (updateServiceAppointmentToServiceGroupAppointment.appointments) {
          evictAppointmentsFromResourcesCalendar(cache, [editedAppointment]);
          evictBookingHistory(cache);
          evictClientCourses(cache, editedAppointment.client.id);
          addEventsToCalendarCache(
            cache,
            updateServiceAppointmentToServiceGroupAppointment.appointments
          );
          this.removeAppointmentsFromTrackedData([editedAppointment.id]);
          cache.gc();
        }
      },
    });
    return response.updateServiceAppointmentToServiceGroupAppointment;
  }

  @dropTask
  *deleteAppointmentsTask(appointments) {
    let appointmentsIds = appointments.map((appointment) => appointment.id);
    const response = yield this.apollo.mutate({
      mutation: deleteAppointments,
      variables: { ids: appointmentsIds },
      update: (cache) => {
        evictAppointments(cache, appointmentsIds);
        evictAppointmentsFromResourcesCalendar(cache, appointments);
        evictBookingHistory(cache);
        appointments.forEach((appointment) => {
          evictClientCourses(cache, appointment.client.id);
        });
        cache.gc();
      },
    });
    return response.deleteAppointments;
  }

  preserveAppointmentDateTime({ date, startTime }) {
    this.displayDate = new Date(`${date}T${startTime}Z`);
  }

  get state() {
    return {
      appointments: this.appointments,
      client: this.client,
      date: this.date,
      screen: this.screen,
      showOnlyBookingsWithNotes: this.showOnlyBookingsWithNotes,
      staffMember: this.staffMember,
    };
  }

  @dropTask
  *swapPackageToService(input) {
    return yield this.apollo.mutate(
      {
        mutation: swapServiceGroupToService,
        variables: { input },
      },
      'swapServiceGroupToService'
    );
  }
}

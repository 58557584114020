/* eslint-disable phorest/moment-import */
import { assert } from '@ember/debug';
import { isEmpty, typeOf } from '@ember/utils';
import moment from 'moment';
import { rectifyZeroHourTime } from 'my-phorest/utils/rectify-zero-hour-time';

const TIME_FORMAT_PRESETS = {
  short: { hour: 'numeric' },
  long: { hour: 'numeric', minute: 'numeric' },
  full: { timeStyle: 'medium' },
};

function _validateArg(arg) {
  if (typeof arg === 'string') {
    let m = _asMoment(arg);

    if (m.isValid()) {
      return m;
    }
  }
}

function _asMoment(timeString, format = moment.HTML5_FMT.TIME_MS) {
  return moment.utc(timeString, format, true);
}

function isValid(timeString) {
  return !!_validateArg(timeString);
}

function betweenNew(range = {}, options = {}) {
  let _start = _validateArg(range.start);
  let _end = _validateArg(range.end);

  if (isEmpty(_start) || isEmpty(_end)) {
    return [];
  }

  let start = _start.clone();
  let end = _end.clone();

  let { step, inclusive } = {
    step: 60,
    inclusive: {
      start: false,
      end: false,
    },
    ...options,
  };

  let includeStart, includeEnd;
  if (typeOf(inclusive) === 'object') {
    includeStart = inclusive.start;
    includeEnd = inclusive.end;
  } else {
    includeStart = includeEnd = !!inclusive;
  }

  if (_start.isSameOrAfter(_end)) {
    end.add(1, 'day');
  }

  if (_start.isSame(_end)) {
    includeEnd = false;
  }

  let times = [];

  if (includeStart) {
    times.push(start.format(moment.HTML5_FMT.TIME_MS));
  }

  start.add(step, 'minutes');

  while (start.isBefore(end)) {
    times.push(start.format(moment.HTML5_FMT.TIME_MS));
    start.add(step, 'minutes');
  }

  if (start.isSame(end) && includeEnd) {
    times.push(end.format(moment.HTML5_FMT.TIME_MS));
  }

  return times;
}

function between(range = {}, options = {}) {
  let { inclusive, step, roundStep, include } = {
    inclusive: false,
    step: 60,
    roundStep: true,
    include: [],
    ...options,
  };

  let from = _validateArg(range.from);
  let to = _validateArg(range.to);

  if (!from || !to) {
    return [];
  }

  if (range.to === range.from) {
    return inclusive ? [range.to] : [];
  }

  if (range.to === '00:00:00.000') {
    to.add(moment.duration(1, 'day'));
  }

  if (from.isAfter(to)) {
    return [];
  }

  let times = [];

  if (inclusive) {
    times.push(range.from);
  }

  let duration = moment.duration(step, 'minutes');

  let next = from.clone().add(duration);

  if (roundStep) {
    next.minute(Math.floor(next.minute() / step) * step);
  }

  while (next.isBefore(to)) {
    times.push(next.format(moment.HTML5_FMT.TIME_MS));
    next.add(duration);
  }

  if (include.length) {
    include.forEach((time) => {
      let m = _validateArg(time);

      if (m?.isBetween(from, to)) {
        times.push(time);
      }
    });

    times = times.compact().sort();
  }

  if (inclusive) {
    times.push(range.to);
  }

  return times.uniq();
}

function durationBetween(start, end, options = { addDay: true }) {
  let _start = _validateArg(start);
  let _end = _validateArg(end);

  if (!_start || !_end) {
    return null;
  }

  if (options.addDay && _start.isAfter(_end)) {
    _end.add(1, 'day');
  }

  _start = _start.second(0).millisecond(0);
  _end = _end.second(0).millisecond(0);
  let diff = _end.diff(_start);

  return moment.duration(diff);
}

function hour(timeString) {
  let m = _validateArg(timeString);

  if (m) {
    return m.hour();
  }
}

function minute(timeString) {
  let m = _validateArg(timeString);

  if (m) {
    return m.minute();
  }
}

/** format: format a time using in preferred style for the current user's locale
 * @param theDate a string representing the time only
 * @param intlService
 * @param formatOptions
 *  a string time format preset (see https://my-dev.phorest.com/styleguide?s=i18n&ss=Date%2FTime%20Presets)
 *  or, an object with Intl format options (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat)
 * @returns a formatted time string
 */
function format(timeString, intlService, formatOptions = 'long') {
  assert(
    'You must provide the intl service in order to use this function',
    !isEmpty(intlService)
  );

  if (!timeString) {
    return;
  }

  assert(
    `You must pass a timeString as a string; you passed ${typeof timeString}`,
    typeof timeString === 'string'
  );

  let m = _validateArg(timeString);

  if (!m) {
    console.error(`Unsupported time format: ${timeString}`);
    return;
  }

  if (typeof formatOptions === 'string') {
    if (TIME_FORMAT_PRESETS[formatOptions]) {
      if (
        formatOptions === 'short' &&
        (minute(timeString) !== 0 || intlService.force24HourClock)
      ) {
        formatOptions = { ...TIME_FORMAT_PRESETS['short'], minute: 'numeric' };
      } else {
        formatOptions = TIME_FORMAT_PRESETS[formatOptions];
      }
    } else {
      assert(
        `Invalid time format ${formatOptions}. You must pass a preset or Intl formatOptions.`
      );
    }
  }

  let formattedTimeString = new Intl.DateTimeFormat(
    intlService.defaultLocale,
    enhanceFormatOptions(formatOptions, intlService)
  ).format(new Date(m));

  return removeDotsInAmPm(rectifyZeroHourTime(formattedTimeString));
}

function enhanceFormatOptions(options, intlService) {
  return {
    hour12:
      usesAmPm(intlService.defaultLocale) && !intlService.force24HourClock,
    timeZone: 'UTC',
    ...options,
  };
}

function removeDotsInAmPm(value) {
  let amPmWithDots = /((?:\d{1,2}[:.])?\d{1,2})\s*([ap])\.(m)\.\s*/i;
  return value?.replace(amPmWithDots, '$1 $2$3');
}

function isAfter(time, targetTime) {
  let m1 = _validateArg(time);
  let m2 = _validateArg(targetTime);

  if (!m1 || !m2) {
    return false;
  }

  return m1.isAfter(m2);
}

function isBefore(time, targetTime) {
  let m1 = _validateArg(time);
  let m2 = _validateArg(targetTime);

  if (!m1 || !m2) {
    return false;
  }

  return m1.isBefore(m2);
}

function isSame(time, targetTime) {
  let m1 = _validateArg(time);
  let m2 = _validateArg(targetTime);

  if (!m1 || !m2) {
    return false;
  }

  return m1.isSame(m2);
}

function isSameOrAfter(time, targetTime) {
  let m1 = _validateArg(time);
  let m2 = _validateArg(targetTime);

  if (!m1 || !m2) {
    return false;
  }

  return m1.isSameOrAfter(m2);
}

function isSameOrBefore(time, targetTime) {
  let m1 = _validateArg(time);
  let m2 = _validateArg(targetTime);

  if (!m1 || !m2) {
    return false;
  }

  return m1.isSameOrBefore(m2);
}

function isBetween(time, { start, end }) {
  let momentTime = _validateArg(time);
  let momentStart = _validateArg(start);
  let momentEnd = _validateArg(end);

  if (!momentTime || !momentStart || !momentEnd) {
    return false;
  }

  return (
    momentStart.isSameOrBefore(momentTime) &&
    momentEnd.isSameOrAfter(momentTime)
  );
}

function addTime(time, increment) {
  let m = _validateArg(time);

  if (m) {
    return m
      .add(moment.duration(increment, 'minutes'))
      .format(moment.HTML5_FMT.TIME_MS);
  }
}

function parse(timeString) {
  let m = _asMoment(
    timeString,
    [
      'H',
      'Hmm',
      'H.m',
      'H.mm',
      'H:mm',
      'H:mm:ss',
      'h',
      'ha',
      'h a',
      'hmm',
      'h:m',
      'h:mma',
      'h:mm a',
      'h:mm',
      'h:mm:ss',
      moment.HTML5_FMT.TIME_MS,
    ],
    true
  );

  if (m.isValid()) {
    return m.format(moment.HTML5_FMT.TIME_MS);
  }
}

function fromJSDate(date, format = moment.HTML5_FMT.TIME_MS) {
  return moment.utc(date).format(format);
}

function asLocalTime(input) {
  if (typeof input === 'string' && parse(input)) {
    return input;
  }
  if (input instanceof moment) {
    return input.format(moment.HTML5_FMT.TIME_MS);
  }
  assert(
    `asLocalTime expects input as a local time string, or a moment object. Got ${input}`
  );
}

function usesAmPm(locale) {
  return [
    'en-ie',
    'en-gb',
    'en-us',
    'en-ca',
    'en-au',
    'en-ae',
    'en-pk',
    'ae',
    'en',
  ].includes(locale.toLowerCase());
}

class TimeInterval {
  constructor(start, end) {
    let m1 = _validateArg(start);

    if (!m1) {
      throw new Error(`Invalid local time string: ${start}`);
    }

    let m2 = _validateArg(end);

    if (!m2) {
      throw new Error(`Invalid local time string: ${end}`);
    }

    this.start = start;
    this.end = end;
  }

  duration() {
    let start = _asMoment(this.start).second(0).millisecond(0);
    let end = _asMoment(this.end).second(0).millisecond(0);
    let diff = end.diff(start);

    return moment.duration(diff);
  }

  precedes(other) {
    return isBefore(this.start, other.start) && isBefore(this.end, other.start);
  }

  meets(other) {
    return isBefore(this.start, other.start) && isSame(this.end, other.start);
  }

  overlaps(other) {
    return (
      isBefore(this.start, other.start) &&
      isAfter(this.end, other.start) &&
      isBefore(this.end, other.end)
    );
  }

  finishedBy(other) {
    return other.finishes(this);
  }

  encloses(other) {
    return other.enclosedBy(this);
  }

  starts(other) {
    return isSame(this.start, other.start) && isBefore(this.end, other.end);
  }

  equivalent(other) {
    return isSame(this.start, other.start) && isSame(this.end, other.end);
  }

  startedBy(other) {
    return other.starts(this);
  }

  enclosedBy(other) {
    return (
      isAfter(this.start, other.start) &&
      isBefore(this.end, other.end) &&
      isAfter(this.end, other.start) &&
      isBefore(this.end, other.end)
    );
  }

  finishes(other) {
    return (
      isAfter(this.start, other.start) &&
      isBefore(this.start, other.end) &&
      isSame(this.end, other.end)
    );
  }

  overlappedBy(other) {
    return other.overlaps(this);
  }

  metBy(other) {
    return other.meets(this);
  }

  precededBy(other) {
    return other.precedes(this);
  }
}

export {
  _asMoment,
  asLocalTime,
  between,
  betweenNew,
  durationBetween,
  format,
  fromJSDate,
  isAfter,
  isBefore,
  isSame,
  isSameOrAfter,
  isSameOrBefore,
  isBetween,
  isValid,
  hour,
  minute,
  addTime,
  parse,
  usesAmPm,
  TimeInterval,
  enhanceFormatOptions,
  removeDotsInAmPm,
};

import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import LastOpenBasket from 'my-phorest/gql/queries/last-open-basket.graphql';
import basketQuery from 'my-phorest/gql/queries/basket.graphql';
import { today } from 'my-phorest/utils/local-date-helpers';
import { action } from '@ember/object';

export default class ReuseBasketService extends Service {
  @queryManager apollo;
  @service fullCalendar;
  @service errorHandler;
  @service paymentSlideOver;

  @task
  *loadLastOpenBasket(clientId) {
    let date = today();

    try {
      const response = yield this.apollo.query({
        query: LastOpenBasket,
        variables: {
          input: {
            filterBy: {
              clientId,
              date,
            },
          },
        },
        fetchPolicy: 'network-only',
      });
      if (response.lastOpenBasket) {
        this.apollo.apollo.client.cache.writeQuery({
          query: basketQuery,
          variables: { id: response.lastOpenBasket.id },
          data: { basket: response.lastOpenBasket },
        });

        return response.lastOpenBasket;
      }
    } catch (error) {
      //swallow error e.g. appointment removed
      //show in sentry but no notification
      this.errorHandler.handle(error, { showError: false });
    }
  }

  #findDiscount(item) {
    let noDiscount = {};
    if (item.discount) {
      let { family, type, value, id } = item.discount;
      switch (family) {
        case 'MANUAL':
          return {
            discount: {
              type,
              value,
            },
          };
        case 'CATEGORY':
          return noDiscount; //category discount is applied only by server
        case 'FIXED':
          return {
            discount: {
              id,
            },
          };
      }
    } else if (item.discount === null) {
      //TODO category discount will still be applied in create basket
      return { discount: null };
    }
    return noDiscount;
  }

  @action
  getLineItems(basket) {
    let getProduct = (item) => {
      return {
        productId: item.product.id,
        staffMemberId: item.staffMember.id,
        quantity: item.quantity,
        price: item.overridePrice?.amount ?? undefined,
        ...this.#findDiscount(item),
      };
    };

    let getService = (item) => {
      return {
        serviceId: item.service.id,
        staffMemberId: item.staffMember.id,
        quantity: item.quantity,
        price: item.overridePrice?.amount ?? undefined,
        ...this.#findDiscount(item),
      };
    };

    let getVoucher = (item) => {
      return {
        serial: item.serial,
        creditAmount: item.creditAmount,
        staffMemberId: item.staffMember.id,
        recipientClientId: item.recipientClient?.id ?? undefined,
        ...this.#findDiscount(item),
      };
    };

    let getCourse = (item) => {
      return {
        quantity: item.quantity,
        staffMemberId: item.staffMember.id,
        courseId: item.courseId,
        price: item.overridePrice?.amount ?? undefined,
        ...this.#findDiscount(item),
      };
    };

    let getOpenSale = (item) => {
      return {
        quantity: item.quantity,
        staffMemberId: item.staffMember.id,
        description: item.description,
        price: Number(item.openSalePrice.amount),
        type: item.openSaleType,
        taxRateIds: item.taxRateIds,
        ...this.#findDiscount(item),
      };
    };

    return {
      products: this.#addLineItemsByType(
        basket,
        'ProductBasketItem',
        getProduct
      ),
      services: this.#addLineItemsByType(
        basket,
        'ServiceBasketItem',
        getService
      ),
      vouchers: this.#addLineItemsByType(
        basket,
        'VoucherBasketItem',
        getVoucher
      ),
      courses: this.#addLineItemsByType(basket, 'CourseBasketItem', getCourse),
      openSales: this.#addLineItemsByType(
        basket,
        'OpenSaleBasketItem',
        getOpenSale
      ),
    };
  }

  #addLineItemsByType(basket, type, serializeItem) {
    return basket.items
      .filter((i) => i.__typename === type)
      .map((i) => serializeItem(i));
  }
}

export function isInstagramApp(window) {
  const ua = _getUserAgent(window);
  return ua.indexOf('Instagram') > -1;
}

export function isFacebookApp(window) {
  const ua = _getUserAgent(window);
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

function _getUserAgent(window) {
  return window.navigator.userAgent || window.navigator.vendor;
}

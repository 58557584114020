import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { isTesting } from '@embroider/macros';
import appConfig from 'my-phorest/config/environment';
import * as Sentry from '@sentry/ember';

const POLL_INTERVAL = 10_000;
const WAIT_INTERVAL = 3_000;

export const terminateAfter = (milliseconds) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), milliseconds);
  return controller;
};

export default class InternetService extends Service {
  @service pendo;
  @service('browser/window') window;
  @tracked connected = true;
  lastDisconnectionTime = null;

  constructor() {
    super(...arguments);
    this.window.addEventListener('offline', this.checkConnection.perform);
    this.window.addEventListener('online', this.checkConnection.perform);
    this.window.addEventListener('load', this.checkConnection.perform);
    this.disconnectedEvent = new Event('disconnected');
    this.reconnectedEvent = new Event('reconnected');
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.window.removeEventListener('offline', this.checkConnection.perform);
    this.window.removeEventListener('online', this.checkConnection.perform);
    this.window.removeEventListener('load', this.checkConnection.perform);
  }

  get disconnected() {
    return !this.connected;
  }

  @restartableTask
  *checkConnection() {
    let oldStatus = this.connected;
    let newStatus = yield this.isConnected();
    this.trackDisconnection({ oldStatus, newStatus });
    this.broadcastChange({ oldStatus, newStatus });
    this.connected = newStatus;
    if (isTesting()) {
      return;
    }
    yield timeout(POLL_INTERVAL);
    this.checkConnection.perform();
  }

  broadcastChange({ oldStatus, newStatus }) {
    if (oldStatus && !newStatus) {
      this.window.dispatchEvent(this.disconnectedEvent);
    }
    if (!oldStatus && newStatus) {
      this.window.dispatchEvent(this.reconnectedEvent);
    }
  }

  trackDisconnection({ oldStatus, newStatus }) {
    if (oldStatus && !newStatus) {
      // disconnected
      this.lastDisconnectionTime = new Date().getTime();
      Sentry.addBreadcrumb({
        category: 'network',
        message: 'Offline',
        level: 'info',
      });
    }
    if (!oldStatus && newStatus && this.lastDisconnectionTime) {
      // reconnected
      const reconnectionTime = new Date().getTime();
      const duration = reconnectionTime - this.lastDisconnectionTime;
      this.lastDisconnectionTime = null;
      Sentry.addBreadcrumb({
        category: 'network',
        message: 'Back online',
        level: 'info',
      });

      if (appConfig.deployTarget !== 'production') {
        // eslint-disable-next-line no-console
        console.info(`Disconnection duration: ${duration}ms`);
      }

      this.pendo.trackEvent('Browser - Internet disconnection', {
        duration,
      });
    }
  }

  async isConnected() {
    const { navigator, location, fetch } = this.window;
    if (!navigator.onLine) return false;

    // avoid CORS errors with a request to your own origin
    const url = new URL(location.origin);

    // random value to prevent cached responses
    url.searchParams.set('rand', Math.random().toString(36).substring(2, 15));

    try {
      const response = await fetch(url.toString(), {
        method: 'HEAD',
        signal: terminateAfter(WAIT_INTERVAL).signal,
      });

      return response.ok;
    } catch {
      return false;
    }
  }
}

export function archivedFlagToArchivePolicy(variables) {
  const { filterBy, ...otherVars } = variables;
  if (!filterBy) return variables;

  const { archived, ...rest } = filterBy;
  return {
    ...otherVars,
    filterBy: {
      fetchArchivedPolicy: archived ? 'ARCHIVED' : undefined,
      ...rest,
    },
  };
}

import { modifier } from 'ember-modifier';

export default modifier(
  function registerElement(element, [callback, onDestroy]) {
    callback(element);

    return () => {
      onDestroy?.(element);
    };
  },
  { eager: false }
);

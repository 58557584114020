/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import { task } from 'ember-concurrency';

export default class PrinterSelectorModal extends Component {
  @service electronApp;
  @service printerSetup;
  @service intl;

  @tracked selectedPrinter;
  @tracked availablePrinters = trackedTask(this, this.getAvailablePrinters);
  @tracked showWarning = false;

  get isOpen() {
    return this.printerSetup.showPrinterSelectorDialog;
  }

  @action
  selectPrinter(printer) {
    this.showWarning = false;
    this.selectedPrinter = printer;
  }

  @action
  confirm() {
    if (!this.selectedPrinter) {
      this.showWarning = true;
      return;
    }
    this.printerSetup.onSelect(this.selectedPrinter?.name);
    this.selectedPrinter = null;
    this.showWarning = false;
  }

  @action
  close() {
    this.printerSetup.onSelect();
    this.selectedPrinter = null;
    this.showWarning = false;
  }

  @task
  *getAvailablePrinters() {
    let printers = yield this.electronApp.getPrinters();
    return printers;
  }
}

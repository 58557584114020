export const compareSemVer = (version1, version2) => {
  const parts1 = version1.split('.');
  const parts2 = version2.split('.');

  for (let i = 0; i < 3; i++) {
    const num1 = parseInt(parts1[i]);
    const num2 = parseInt(parts2[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
};

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { dropTask, didCancel } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dasherize } from '@ember/string';
import { action } from '@ember/object';

const INPUT_SCROLL_OFFSET = -140;

export default class UiFormComponent extends Component {
  @service intl;
  @tracked validationMessages = {};
  @tracked isSubmitted = false;

  @action
  submit(event) {
    if (this.args.disabled) return;
    event?.preventDefault();
    this.isSubmitted = true;
    return this.submitTask.perform(event);
  }

  @dropTask
  *submitTask(event) {
    const validate = async (schema) => {
      try {
        // We are extra cautious here because any uncaught exception
        // causes the browser to POST the form and reload the app.
        return await this.validateModelAgainstSchema({
          schema,
          skipScroll: false,
        });
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    if (yield validate(this.args.validationSchema)) {
      yield this.args.onSubmit?.(event, validate);
    }
  }

  normalizeValidationModel(validationModel) {
    let normalizedValidationModel = {};

    Object.keys(validationModel).forEach((key) => {
      if (
        validationModel[key] !== null &&
        validationModel[key] !== undefined &&
        validationModel[key] !== ''
      ) {
        normalizedValidationModel[key] = validationModel[key];
      }
    });

    return normalizedValidationModel;
  }

  scrollIntoView(name) {
    if (!name || Object.keys(this.validationMessages).length) {
      return;
    }

    const element = document.querySelector(`[name="${dasherize(name)}"`);
    if (element) {
      element.scrollIntoView({ block: 'center' });
      element.focus();

      window.scrollBy(0, INPUT_SCROLL_OFFSET);
    }
  }

  getModel() {
    let model;
    if (typeof this.args.validationModel === 'function') {
      model = this.args.validationModel();
    } else {
      model = this.args.validationModel;
    }
    return this.normalizeValidationModel(model);
  }

  getError(validationError) {
    let validationMessage;
    if (validationError.message?.key) {
      let { key, context } = validationError.message;
      validationMessage = this.intl.t(key, context);
    } else if (this.intl.exists(validationError.message)) {
      validationMessage = this.intl.t(validationError.message);
    } else if (this.intl.exists(`validations.${validationError.type}`)) {
      validationMessage = this.intl.t(`validations.${validationError.type}`);
    } else {
      validationMessage = validationError.message;
    }

    return {
      name: validationError.path || validationError.type,
      errorLabel: validationMessage,
    };
  }

  normalizeErrors(errors) {
    errors.inner.forEach((e) => {
      let { name, errorLabel } = this.getError(e);
      this.validationMessages = {
        ...this.validationMessages,
      };
      // when the yup schema contains an array, you end up with an error key like 'courseItems[0].totalUnits'.
      // The period confuses ember resolution of the key, so I'm replacing periods with underscore
      this.validationMessages[name.replaceAll('.', '_')] = errorLabel;
    });
  }

  @action clearValidationMessages() {
    this.validationMessages = {};
  }

  @action
  async validateModelAgainstSchema({
    schema = this.args.validationSchema,
    skipScroll = true,
  } = {}) {
    if (schema) {
      this.clearValidationMessages();
      try {
        await schema.validate(this.getModel(), {
          abortEarly: false,
        });
      } catch (errors) {
        if (!didCancel(errors)) {
          if (!skipScroll) {
            let firstErr = errors.inner.firstObject.path;
            this.scrollIntoView(firstErr);
          }
          this.normalizeErrors(errors);
          return false;
        }
      }
    }

    return true;
  }
}

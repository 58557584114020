import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CashCounterSlideOverService extends Service {
  @tracked isOpen = false;

  @tracked cashNotes;
  @tracked cashCoins;
  @tracked totalAmount;

  @action
  open(actualAmount) {
    if (
      Number(Number(this.totalAmount).toFixed(2)) !==
      Number(Number(actualAmount).toFixed(2))
    ) {
      this.reset();
    }

    this.isOpen = true;
  }

  reset() {
    this.cashNotes = null;
    this.cashCoins = null;
    this.totalAmount = null;
  }

  @action
  close() {
    this.isOpen = false;
  }
}

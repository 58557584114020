function extractIdFrom(ref) {
  if (!ref) {
    return null;
  }

  let parts = ref.split(':');

  if (parts.length === 4) {
    return parts[3];
  }

  return ref;
}

function convertToRef(id, type) {
  if (!id || !type) {
    return null;
  }

  return `urn:x-memento:${type}:${id}`;
}

export { extractIdFrom, convertToRef };

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

const DEFAULT_LEADING_ICON = 'exclamation';

export default class ModalConfirmDialogComponent extends Component {
  @service confirmDialog;
  @service intl;

  get cancelButtonLabel() {
    return (
      this.confirmDialog.options.cancel ??
      this.intl.t('ui.modal.confirm-dialog.button-cancel')
    );
  }

  get continueButtonLabel() {
    return (
      this.confirmDialog.options.continue ??
      this.intl.t('ui.modal.confirm-dialog.button-continue')
    );
  }

  get hideCancelButton() {
    return this.confirmDialog.options.hideCancelButton;
  }

  get description() {
    return this.confirmDialog.options.message;
  }

  get isOpen() {
    return this.confirmDialog.isOpen;
  }

  get leadingIcon() {
    return this.confirmDialog.options.leadingIcon ?? DEFAULT_LEADING_ICON;
  }

  get title() {
    return (
      this.confirmDialog.options.title ??
      this.intl.t('ui.modal.confirm-dialog.title')
    );
  }

  @action
  confirm() {
    this.confirmDialog.confirm();
  }

  @action
  deny(e) {
    if (e) this.confirmDialog.deny();
  }
}

import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { format as formatDate } from 'my-phorest/utils/local-date-helpers';

export default class LocalDateFormatHelper extends Helper {
  @service intl;

  compute([dateString, fmt = 'long', locale]) {
    if (!dateString) {
      return '';
    }

    let intl = this.intl;

    if (locale) {
      intl = { defaultLocale: locale };
    }

    return formatDate(dateString, intl, fmt);
  }
}

import EmberHeadlessUiDialog from 'ember-headlessui/components/dialog.js';
import { action } from '@ember/object';

export default class extends EmberHeadlessUiDialog {
  @action
  allowOutsideClick(e) {
    const target = e.target;

    if (target && target.tagName !== 'BODY') {
      this.outsideClickedElement = target;
    }

    if (!this.#didClickOnNonClosingElement(e.target)) {
      this.onClose();
    }

    return true;
  }

  #didClickOnNonClosingElement($target) {
    let $node = $target;

    while ($node !== null) {
      // A click inside a notification
      if ($node.hasAttribute('data-ui-notification')) {
        return true;
      }

      // A click inside the Pendo Designer UI or Pendo elements put in dialogs
      if (
        $node.classList &&
        [...$node.classList].some((className) => className.includes('pendo'))
      ) {
        return true;
      }

      $node = $node.parentElement;
    }

    return false;
  }
}

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ColumnsPrinterService extends Service {
  @service electronApp;
  @service router;
  @service session;
  @service('browser/window') window;

  @tracked isBrowserColumnPrintOpen = false;
  @tracked isPrintingColumn = false;
  @tracked data;

  print(data, afterCallback) {
    if (this.electronApp.isRunningInElectron) {
      this.isPrintingColumn = true;
      this.printElectron(data, afterCallback, data.staffMemberIds);
    } else {
      this.data = data;
      this.isPrintingColumn = true;
      this.isBrowserColumnPrintOpen = true;
    }
  }

  @action
  async printElectron(data, cb, ids) {
    for (let i = 0; i < ids.length; i++) {
      const path = this.router.urlFor(
        'accounts.account.print.columns',
        this.session.currentAccountId,
        {
          queryParams: {
            withNotes: data.withNotes,
            date: data.date,
            staffMemberIds: [ids[i]],
          },
        }
      );
      const { protocol, host } = this.window.location;
      const printURL = new URL(`${protocol}//${host}${path}`);
      this.electronApp.printReceipt(
        printURL.toString(),
        () => {
          if (i === ids.length - 1) {
            this.isPrintingColumn = false;
            cb?.();
          }
        },
        { waitForDataLoadedAttr: true }
      );
    }
  }

  @action
  onWebPrintDone() {
    this.isBrowserColumnPrintOpen = false;
    this.isPrintingColumn = false;
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class UiDropdownTriggerComponent extends Component {
  @action
  handleClickTrigger(e) {
    this.args.onClick?.(e);
  }
}

import Service, { service } from '@ember/service';

export default class ZendeskService extends Service {
  @service('browser/window') window;
  @service tagManager;

  isInitializing = false;

  identify() {
    this.track({ event: 'Zendesk Identify' });
  }

  track(data) {
    if (!this.hasInitialized) {
      this.initialize();
    }
    this.tagManager.push(data);
  }

  initialize() {
    if (this.isInitializing || this.hasInitialized) {
      return;
    }
    this.isInitializing = true;
    this.track({ event: 'Zendesk Initialize' });
    this.isInitializing = false;
  }

  get hasInitialized() {
    return !!this.window.zE;
  }
}

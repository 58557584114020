/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class UiInputComponent extends Component {
  @action
  handleKeyUp(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.args.form?.submit(event);
    }
  }
}

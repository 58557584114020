/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import tinyMceLogo from 'my-phorest/assets/images/tinymce.svg';
import { variation } from 'ember-launch-darkly';

import config from 'my-phorest/config/environment';
import {
  importTinymce,
  getMacroBadge,
  replaceMacroBadgeInContent,
  moustachify,
} from 'my-phorest/utils/tinymce';

import {
  FONT_FORMATS,
  FONT_SIZE_FORMATS,
} from 'my-phorest/utils/text-settings';

const stylesheets = [
  `${config.assetsHost ?? '/'}assets/styles/tinymce-iframe.css`,
  `${config.assetsHost ?? '/'}assets/vendor.css`,
];
export default class UiEditorComponent extends Component {
  @service intl;

  guid = `rich-text-editor-${guidFor(this)}`;
  tinyMceLogoSrc = tinyMceLogo;

  @tracked showLinkWithCustomTextModal = false;
  @tracked showCustomLinkModal = false;
  @tracked linkText = this.args.defaultCustomText;
  @tracked selectedLink;
  @tracked customLinkText = this.args.defaultCustomLinkText;
  @tracked customLinkUrl = this.args.defaultCustomLinkUrl;

  constructor() {
    super(...arguments);

    // do not track this property, as it breaks tinymce
    this.renderedContent = this.renderContent(this.args.content);
  }

  loadEditorTaskInstance = trackedTask(this, this.loadEditorTask);

  @dropTask
  *loadEditorTask() {
    try {
      this.tinymce = yield importTinymce();
    } catch (e) {
      /* XXX happens if user navigates away too quickly */
    }
  }

  get options() {
    return {
      skin: false,
      content_css: stylesheets,
      height: this.args.height ?? 150,
      menubar: false,
      plugins: this.args.plugins,
      toolbar: this.args.toolbar,
      statusbar: true,
      browser_spellcheck: true,
      contextmenu: false,
      forced_root_block: 'div',
      resize: this.args.variant !== 'inline',
      inline: this.args.variant === 'inline',
      font_family_formats: FONT_FORMATS,
      font_size_formats: FONT_SIZE_FORMATS,
    };
  }

  /** Returns the rendered content, i.e. with all {{MACROS}} handlebar placeholders replaced with macro badges */
  renderContent(sourceContent) {
    let renderedContent = sourceContent;

    if (this.args.macros) {
      this.args.macros.forEach((macro) => {
        renderedContent = renderedContent?.replaceAll(
          moustachify(macro),
          getMacroBadge(macro, this.intl)
        );
      });
    }

    if (this.args.links && !this.args.enableLinkWithCustomText) {
      this.args.links.forEach((link) => {
        renderedContent = renderedContent?.replaceAll(
          moustachify(link),
          getMacroBadge(link, this.intl)
        );
      });
    }

    return renderedContent;
  }

  @action
  handleInput(e) {
    if (!this.args.onInput) return;

    let sourceContent = this.tinymce.activeEditor.getContent();

    if (this.args.macros || this.args.links) {
      let replacers = this.args.macros ? [...this.args.macros] : [];

      if (this.args.links && !this.args.enableLinkWithCustomText)
        replacers = replacers.concat(this.args.links);

      replacers.forEach((macro) => {
        sourceContent = replaceMacroBadgeInContent(sourceContent, macro);
      });
    }
    // remove unwanted tinymce attribute "date-mce-fragment"
    e.target.value = sourceContent.replace(/ data-mce-fragment="1"/g, '');
    this.args.onInput(e);
  }

  @action
  setupLinkModal(link) {
    this.showLinkWithCustomTextModal = true;
    this.selectedLink = link;
  }

  @action
  setupCustomLinkModal() {
    this.showCustomLinkModal = true;
  }

  @action
  insertCustomText() {
    let link = document.createElement('a');
    link.href = moustachify(this.selectedLink);
    link.textContent = this.linkText;
    this.tinymce.activeEditor.insertContent(
      variation('release-marketing-happy-salon-macro-whitespace')
        ? link.outerHTML + '&nbsp;'
        : link.outerHTML
    );
    this.showLinkWithCustomTextModal = false;
  }

  @action
  closeCustomLinkModal() {
    this.invalidURL = false;
    this.showCustomLinkModal = false;
    this.customLinkText = null;
    this.customLinkUrl = null;
  }

  @action
  insertCustomLinkWithText() {
    this._createAndInsertLink(this.customLinkUrl, this.customLinkText);
    this.closeCustomLinkModal();
  }

  _createAndInsertLink(href, textContent) {
    let link = document.createElement('a');
    link.href = href;
    link.textContent = textContent;
    link.style = `color: ${this.linkColor};`;

    this.tinymce.activeEditor.insertContent(
      variation('release-marketing-happy-salon-macro-whitespace')
        ? link.outerHTML + '&nbsp;'
        : link.outerHTML
    );
  }
}

import { modifier } from 'ember-modifier';

export default modifier(
  function onScrollEnd(element, [action]) {
    let handler = function () {
      if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
        action?.();
      }
    };
    element.addEventListener('scroll', handler);

    return () => {
      element.removeEventListener('scroll', handler);
    };
  },
  { eager: false }
);

import Service, { service } from '@ember/service';

export default class PercentageFormatterService extends Service {
  @service intl;

  format(value, maxDigits = 3) {
    return this.intl.formatNumber(value / 100, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: maxDigits,
    });
  }
}

import Service, { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';

import clientCoursesQuery from 'my-phorest/gql/queries/client-courses.graphql';
import clientTreatCardAccount from 'my-phorest/gql/queries/client-treat-card-account.graphql';
import purchaseReceiptQuery from 'my-phorest/gql/queries/purchase-receipt.graphql';

export default class PurchaseReceiptService extends Service {
  @service('browser/window') window;
  @service electronApp;
  @service errorHandler;
  @service purchase;
  @service router;
  @service session;
  @service swingBridge;
  @service terminalLoader;

  @queryManager apollo;

  async fetchReceiptData(purchaseId) {
    const promises = [
      this.apollo.query(
        {
          query: purchaseReceiptQuery,
          variables: {
            id: purchaseId,
          },
        },
        'purchase'
      ),
    ];

    if (this.session.terminalId) {
      promises.push(this.fetchTerminal());
    }

    const [purchase, terminal] = await Promise.all(promises);
    const extraDataPromises = [];
    let nextAppointment, client, clientCourses;

    if (terminal?.receiptSettings.showFutureAppointmentsAndCourses) {
      extraDataPromises.push(
        this.fetchClientNextAppointmentTask(purchase.clientId).then(
          (result) => {
            nextAppointment = result;
            return result;
          }
        )
      );
      extraDataPromises.push(
        this.fetchClientCourses(purchase.clientId).then((result) => {
          clientCourses = result;
          return result;
        })
      );
    }

    if (terminal?.receiptSettings.showTreatCardPoints) {
      extraDataPromises.push(
        this.fetchClientTreatCardAccount(purchase.clientId).then((result) => {
          client = result;
          return result;
        })
      );
    }

    await Promise.all(extraDataPromises);

    return {
      purchase,
      terminal,
      nextAppointment,
      client,
      clientCourses,
    };
  }

  async fetchClientCourses(clientId) {
    try {
      const clientCourses = await this.apollo.query(
        {
          query: clientCoursesQuery,
          variables: {
            filterBy: {
              clientId,
            },
          },
        },
        'clientCourses'
      );
      return clientCourses.edges?.map((e) => e.node);
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }

  async fetchClientNextAppointmentTask(clientId) {
    try {
      return this.purchase.fetchClientNextAppointmentTask.perform(clientId);
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }

  async fetchClientTreatCardAccount(clientId) {
    try {
      return await this.apollo.query(
        {
          query: clientTreatCardAccount,
          variables: {
            id: clientId,
          },
        },
        'client'
      );
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }

  async fetchTerminal() {
    try {
      return await this.terminalLoader.getTerminal.perform(
        this.session.terminalId
      );
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }

  printReceiptInElectron(
    purchaseId,
    { isDuplicate = false, ccCopyMode, successCb } = {}
  ) {
    if (!this.electronApp.isRunningInElectron) return;
    let path;

    try {
      path = this.router.urlFor(
        'accounts.account.purchase.receipt',
        this.session.currentAccountId,
        purchaseId
      );
    } catch (error) {
      console.warn(
        `Issue with generating receipt link. Params: accountId: "${this.session.currentAccountId}", purchaseId: "${purchaseId}"`
      );
      return this.errorHandler.handle(error);
    }

    const { protocol, host } = this.window.location;
    const receiptURL = new URL(`${protocol}//${host}${path}`);

    if (isDuplicate) {
      receiptURL.searchParams.append('duplicate', 'true');
    }

    if (ccCopyMode) {
      receiptURL.searchParams.append('cc_copy_mode', ccCopyMode);
    }

    return this.electronApp.printReceipt(receiptURL.toString(), successCb);
  }
}

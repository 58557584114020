import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const NEW_ITEM = -1;

export default class ServiceGroupItemSlideOverService extends Service {
  @service notifications;
  @service intl;

  @tracked serviceGroupItem;
  itemIndex;
  isManagedServiceGroup;

  @tracked isOpen = false;
  @tracked isServiceSelectionOpen = false;

  get isNewItem() {
    return this.itemIndex === NEW_ITEM;
  }

  openWithServiceGroupItem(serviceGroupItem, itemIndex, isManagedServiceGroup) {
    this.isManagedServiceGroup = isManagedServiceGroup;
    this.serviceGroupItem = { ...serviceGroupItem };
    this.itemIndex = itemIndex;
    this.isOpen = true;
    this.isServiceSelectionOpen = false;
  }

  openWithNew() {
    this.serviceGroupItem = {
      options: [],
    };
    this.isManagedServiceGroup = false;
    this.itemIndex = NEW_ITEM;
    this.isOpen = true;
    this.isServiceSelectionOpen = true;
  }

  close() {
    this.isOpen = false;
    this.isServiceSelectionOpen = false;
  }

  @action
  addOption() {
    this.isServiceSelectionOpen = true;
  }

  @action
  closeServiceSelection() {
    this.isServiceSelectionOpen = false;
    if (this.serviceGroupItem.options.length === 0) {
      this.isOpen = false;
    }
  }

  @action
  onServiceChoose(service) {
    if (this.#itemAlreadyContainsService(service)) {
      return this.notifications.failure(
        this.intl.t(
          'service-group-item-slide-over.existing-service-error-title'
        ),
        this.intl.t(
          'service-group-item-slide-over.existing-service-error-description'
        )
      );
    }

    this.serviceGroupItem = {
      ...this.serviceGroupItem,
      options: [
        ...this.serviceGroupItem.options,
        {
          service,
          parent: {},
        },
      ],
    };

    this.isServiceSelectionOpen = false;
  }

  #itemAlreadyContainsService(service) {
    return this.serviceGroupItem.options.some(
      (option) => option.service?.id === service.id
    );
  }

  @action
  changeOptionPrice(option, price) {
    const newOption = this.isManagedServiceGroup
      ? {
          ...option,
          price,
        }
      : {
          ...option,
          parent: {
            ...option.parent,
            price,
          },
        };

    this.#replaceOption(option, newOption);
  }

  @action
  onUpdatedStaffCategoryPrices(option, staffCategoryPrices) {
    const newOption = this.isManagedServiceGroup
      ? {
          ...option,
          staffCategoryPrices,
        }
      : {
          ...option,
          parent: {
            ...option.parent,
            staffCategoryPrices,
          },
        };

    this.#replaceOption(option, newOption);
  }

  #replaceOption(option, newOption) {
    this.serviceGroupItem = {
      ...this.serviceGroupItem,
      options: this.serviceGroupItem.options.map((o) => {
        return o === option ? newOption : o;
      }),
    };
  }
}

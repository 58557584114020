import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class CurrencySymbol extends Helper {
  @service currencyFormatter;

  compute([value]) {
    return this.currencyFormatter.getCurrencySymbol(value);
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

const DEFAULT_LEADING_ICON = 'exclamation';

export default class ModalTextInputDialogComponent extends Component {
  @service textInputDialog;
  @service intl;

  get cancelButtonLabel() {
    return this.textInputDialog.options.cancel ?? this.intl.t('global.cancel');
  }

  get continueButtonLabel() {
    return this.textInputDialog.options.ok ?? this.intl.t('global.continue');
  }

  get hideCancelButton() {
    return this.textInputDialog.options.hideCancelButton;
  }

  get description() {
    return this.textInputDialog.options.message;
  }

  get isOpen() {
    return this.textInputDialog.isOpen;
  }

  get leadingIcon() {
    return this.textInputDialog.options.leadingIcon ?? DEFAULT_LEADING_ICON;
  }

  get text() {
    return (
      this.textInputDialog.options?.defaultText ?? this.textInputDialog.text
    );
  }

  get title() {
    return (
      this.textInputDialog.options.title ??
      this.intl.t('ui.modal.confirm-dialog.title')
    );
  }

  get hasText() {
    return (
      !!this.textInputDialog.options?.defaultText || !!this.textInputDialog.text
    );
  }

  @action
  setText(e) {
    this.textInputDialog.text = e.target.value;
  }

  @action
  confirm() {
    if (
      this.textInputDialog.options?.defaultText &&
      !this.textInputDialog.text
    ) {
      this.textInputDialog.text = this.textInputDialog.options.defaultText;
    }

    this.textInputDialog.confirm();
  }

  @action
  deny(e) {
    if (e) this.textInputDialog.deny();
  }
}

import { tracked } from '@glimmer/tracking';

export class BookingIntent {
  appointmentPlanner;
  selectedPackage;
  type;

  @tracked _staffRequested = true;
  @tracked service;
  @tracked selectedPackageOptions;
  @tracked staffMember;

  constructor(
    { type, service, selectedPackage, selectedPackageOptions },
    appointmentPlannerService
  ) {
    this.appointmentPlanner = appointmentPlannerService;
    this.type = type;

    if (type === BOOKING_INTENT_TYPE.PACKAGE) {
      this.selectedPackage = selectedPackage;
      this.setPackageOptions(selectedPackageOptions);
    } else {
      this.service = service;
    }
  }

  get durationMins() {
    return this.service?.durationMins;
  }

  get hasStaffMember() {
    return !!this.staffMember;
  }

  get price() {
    return this.service?.price;
  }

  get staffRequested() {
    const canBeRequested =
      !this.appointmentPlanner.isSearchingInOtherBranch && this.hasStaffMember;

    if (canBeRequested) {
      return this._staffRequested;
    } else {
      return false;
    }
  }
  set staffRequested(value) {
    this._staffRequested = value;
  }

  setPackageOptions(options) {
    this.selectedPackageOptions = options.map(
      (option) => new PackageOptionIntent(option, this)
    );
  }
}

export class PackageOptionIntent {
  _bookingIntent;

  @tracked _option;

  constructor(option, bookingIntentParent) {
    this._option = option;
    this._bookingIntent = bookingIntentParent;
  }

  get durationMins() {
    return this._option.duration;
  }

  get hasStaffMember() {
    return !!this.staffMember;
  }

  get id() {
    return this._option.id;
  }

  get option() {
    return this._option;
  }
  set option(value) {
    this._option = value;
  }

  get price() {
    return this._option.price;
  }

  get service() {
    return this._option;
  }

  get serviceGroupId() {
    return this._bookingIntent.selectedPackage.id;
  }

  get serviceGroupItemOptionId() {
    return this._option.id;
  }

  get serviceGroupOptionId() {
    return this._option.serviceGroupOptionId;
  }

  get serviceId() {
    return this._option.serviceId;
  }

  get staffMember() {
    return this._bookingIntent.staffMember;
  }
  set staffMember(value) {
    this._bookingIntent.staffMember = value;
  }

  get staffRequested() {
    return this._bookingIntent.staffRequested;
  }
  set staffRequested(value) {
    this._bookingIntent.staffRequested = value;
  }
}

export const BOOKING_INTENT_TYPE = {
  PACKAGE: 'PACKAGE',
  SERVICE: 'SERVICE',
};

export const PLANNER_ROUTES = {
  AVAILABILITY: 'planner.availability',
  BOOKING_HISTORY: 'planner.booking-history',
  CLIENTS: 'planner.clients',
  EDIT_BOOKING_INTENT: 'planner.edit-booking-intent',
  SERVICES: 'planner.services',
  STAFF: 'planner.staff',
};

const intlToMomentMap = {
  en: 'en-gb',
  'en-us': 'en',
};

function intlToMomentLocale(input) {
  return intlToMomentMap[input] || input;
}

export { intlToMomentLocale };

import Modifier from 'ember-modifier';
import { v4 } from 'uuid';
import { isTesting } from '@embroider/macros';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';

export default class DisableAutocompleteModifier extends Modifier {
  elt;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.unregisterListener);
    registerDestructor(this, this.removeAutocompleteAttribute);
  }

  modify(element) {
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
    // https://stackoverflow.com/questions/53179114/unexpected-chrome-autofill-behaviour-disable-chrome-autofill
    this.elt = element;

    if (!element.hasAttribute('autocomplete')) {
      assert('Form must have a name attribute', element.getAttribute('name'));

      if (!isTesting()) {
        element.setAttribute('name', element.getAttribute('name') + '-' + v4());
      }

      if (element.nodeName == 'FORM') {
        element.setAttribute('autocomplete', 'off');
      } else {
        if (element.type === 'password') {
          if (!isTesting()) {
            element.setAttribute('readonly', 'readonly');
            element.addEventListener(
              'focus',
              this.removeReadonlyAttribute(element)
            );
          }
        }
        element.setAttribute('autocomplete', 'new-password');
      }
    }
  }

  @action
  removeReadonlyAttribute(element) {
    element.removeAttribute('readonly');
  }

  @action
  unregisterListener() {
    this.elt.removeEventListener('focus', this.removeReadonlyAttribute);
  }

  @action
  removeAutocompleteAttribute() {
    this.elt.removeAttribute('autocomplete');
  }
}

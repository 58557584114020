/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class ModalDiscardChangesComponent extends Component {
  @service discardChanges;

  get isOpen() {
    return this.discardChanges.isOpen;
  }

  @action
  confirm() {
    this.discardChanges.confirm();
  }

  @action
  deny(e) {
    if (e) this.discardChanges.deny();
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { format as formatTime } from 'my-phorest/utils/local-time-helpers';

export default class UiInputCurrencyWithKeypadComponent extends Component {
  @service keypad;
  @service intl;

  @action
  openKeypad() {
    this.keypad.openKeypad({
      value: this.args.time,
      headerText: this.intl.t('global.enter-new-time'),
      required: true,
      variant: 'time',
      onChange: this.onKeypadChange,
    });
  }

  get formattedTime() {
    const result = formatTime(this.args.time, this.intl);
    return result;
  }

  @action
  onKeypadChange(time) {
    this.args.onInput(time + ':00.000');
  }
}

import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { debounce, cancel } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import timeoutForEnv from 'my-phorest/utils/timeout-for-env';

export default class WorkingHoursIndicatorsService extends Service {
  @service popper;

  @tracked $lineNodes;

  @action
  updateNodes() {
    this.updateNotesKey = debounce(
      this,
      this.#populateNodes,
      null,
      timeoutForEnv(400)
    );
  }

  @action
  refresh() {
    this.refreshKey = debounce(this, this.#schedule, null, timeoutForEnv(400));
  }

  @action
  cancelSchedulers() {
    if (this.updateNotesKey) {
      cancel(this.updateNotesKey);
    }
    if (this.refreshKey) {
      cancel(this.refreshKey);
    }
  }

  #schedule() {
    this.popper.refresh('start-line');
    this.popper.refresh('end-line');
  }

  #populateNodes() {
    this.$lineNodes = document.querySelectorAll(
      '.fc-timegrid-slot.fc-timegrid-slot-label'
    );
  }
}

import { isBetween, parse, addTime } from 'my-phorest/utils/local-time-helpers';

export const isNotWorking = (staffMember, time, date) => {
  let day = staffMember.calendarDays.find((day) => day.date === date);
  if (day) {
    return day.timeSlots.some((slot) => {
      return (
        slot.type === 'NON_WORKING' &&
        isBetween(addTime(parse(time), 1), {
          start: parse(slot.startTime),
          end: parse(slot.endTime === '00:00' ? '23:59' : slot.endTime),
        })
      );
    });
  }
  return true;
};

import {
  addMoney,
  multiplyMoney,
  divideMoney,
  subtractMoney,
} from 'my-phorest/utils/currency';
import { A } from '@ember/array';

export const DISCOUNT_TYPES = {
  AMOUNT: 'AMOUNT',
  PERCENT: 'PERCENT',
  FIXED: 'FIXED',
};

export const INELIGIBLE_BASKET_ITEMS = [
  'AppointmentCourseSessionBasketItem',
  'SaleFeeBasketItem',
  'CreditAccountBasketItem',
  'CreditAccountAppointmentDepositBasketItem',
  'VoucherTopUpBasketItem',
  'ServiceRewardAppointmentBasketItem',
  'AppointmentServiceRewardBasketItem',
  'ServiceRewardBasketItem',
  'ProductRewardBasketItem',
];

const sumMoney = (arr) => {
  return arr.reduce((sum, value) => addMoney(sum, value), 0);
};

const filterEligibleToDiscountItems = (basketItems) => {
  return basketItems.filter(
    (i) => !INELIGIBLE_BASKET_ITEMS.includes(i.__typename)
  );
};

export const splitProportionally = (amountToSplit, arrayWithValues) => {
  let total = sumMoney(arrayWithValues);
  let discounts = A(
    arrayWithValues.map((value, ix) => {
      if (ix !== arrayWithValues.length - 1) {
        return divideMoney(multiplyMoney(amountToSplit, value), total);
      }
    })
  ).compact();

  discounts.push(subtractMoney(amountToSplit, sumMoney(discounts)));
  return discounts;
};

export class MultipleDiscount {
  type = null;

  #TYPES = {
    AMOUNT: this.#amountVariant,
    PERCENT: this.#percentVariant,
    FIXED: this.#fixedVariant,
  };
  #discount = null;

  constructor(discount) {
    this.type = this.#findDiscountType(discount);
    this.#discount = discount;
  }

  createPayload(basket) {
    if (!this.type) {
      return null;
    }
    let payload = this.#TYPES[this.type](this.#discount, basket);
    payload.items = payload.items.filter(
      (i) => Number(i.discount?.value) !== 0
    );
    return payload;
  }

  createPayloadToRemoveAll(basket) {
    let payload = this.#TYPES[this.type](this.#discount, basket);
    return {
      basketItemIds: payload.items.map((i) => i.basketItemId),
      basketId: payload.basketId,
    };
  }

  #findDiscountType(discount) {
    if (discount.id) {
      return DISCOUNT_TYPES.FIXED;
    } else if (discount.type === DISCOUNT_TYPES.AMOUNT) {
      return DISCOUNT_TYPES.AMOUNT;
    } else if (discount.type === DISCOUNT_TYPES.PERCENT) {
      return DISCOUNT_TYPES.PERCENT;
    }
    throw new Error('Unknown type of discount', { cause: discount });
  }

  #percentVariant(discount, basket) {
    let items = filterEligibleToDiscountItems(basket.items);
    let discountItems = items.map((i) => {
      return {
        discount: {
          type: DISCOUNT_TYPES.PERCENT,
          value: Number(discount.amount),
        },
        basketItemId: i.id,
      };
    });
    return {
      basketId: basket.id,
      items: discountItems,
    };
  }

  #fixedVariant(discount, basket) {
    let items = filterEligibleToDiscountItems(basket.items);

    let discountItems = items.map((i) => {
      return {
        fixedDiscount: { id: discount.id },
        basketItemId: i.id,
      };
    });
    return {
      basketId: basket.id,
      items: discountItems,
    };
  }

  #amountVariant(discount, basket) {
    let items = filterEligibleToDiscountItems(basket.items);
    let prices = items.map((i) => i.totalPrice.amount);
    let discounts = splitProportionally(discount.amount, prices);
    let discountItems = items.map((i, ix) => {
      return {
        discount: {
          type: DISCOUNT_TYPES.AMOUNT,
          value: Number(discounts[ix]),
        },
        basketItemId: i.id,
      };
    });
    return {
      basketId: basket.id,
      items: discountItems,
    };
  }
}

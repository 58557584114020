import Service from '@ember/service';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

const AD_CAMPAIGNS_LIST_PATH =
  'accounts.account.marketing.meta-ads.campaigns.list';

const POLLING_LIMIT = 200; // requests
const VERIFICATION_CALL_INTERVAL = 2000; // [ms]

const BLOCKER_INVALID_REASONS = [
  'UNKNOWN',
  'FB_RATE_LIMITED',
  'ERROR_FETCHING_BUSINESS_CONFIG',
];

export default class FbeLoginService extends Service {
  @service metaAds;
  @service router;

  @tracked newFbeConnection;
  @tracked fbeConnectionWithAdsPermissions;
  @tracked pollingCounter = 0;
  @tracked showCompleteSetupModal = false;

  @action
  hasAdsFeature(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures?.some(
      (enabledFeature) => enabledFeature.name === 'ADS'
    );
  }

  @action
  hasValidPageCta(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures?.some(
      (enabledFeature) => enabledFeature.name === 'PAGE_CTA'
    );
  }

  @action
  hasInvalidToken(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures.some(
      (enabledFeature) => enabledFeature.invalidReason === 'INVALID_TOKEN'
    );
  }

  @action
  hasValidInstagramCta(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures?.some(
      (enabledFeature) => enabledFeature.name === 'INSTAGRAM_CTA'
    );
  }

  @action
  isBlockerInvalidReason(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures.filter((enabledFeature) =>
      BLOCKER_INVALID_REASONS.includes(enabledFeature.invalidReason)
    );
  }

  @action
  isUserNotConfirmed(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures.some(
      (enabledFeature) => enabledFeature.invalidReason === 'USER_NOT_CONFIRMED'
    );
  }

  @action
  isMissingAdAccountId(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures.some(
      (enabledFeature) =>
        enabledFeature.name === 'ADS' &&
        enabledFeature.invalidReason === 'MISSING_AD_ACCOUNT_ID'
    );
  }

  @action
  transitionToAdCampaignList({ queryParams }) {
    if (queryParams) {
      this.router.transitionTo(AD_CAMPAIGNS_LIST_PATH, { queryParams });
    } else {
      this.router.transitionTo(AD_CAMPAIGNS_LIST_PATH, undefined);
    }
  }

  @action
  onCloseSetupModal() {
    this.showCompleteSetupModal = false;

    this.verifyFbIntegration?.cancelAll();
    this.verifyAdsPermissions?.cancelAll();
  }

  @task
  *getSelectedFbeConnection(selectedFbeConnectionId) {
    return yield this.metaAds.getFbeConnections.perform(
      selectedFbeConnectionId
    );
  }

  @task
  *logInToFacebook() {
    this.showCompleteSetupModal = true;
    this.newFbeConnection = yield this.metaAds.createFbeConnection.perform();

    if (this.newFbeConnection) {
      yield this.#handleFbeFlow([
        this.metaAds.login({ fbeConnection: this.newFbeConnection }),
        this.verifyFbIntegration.perform(),
      ]);
    }
  }

  @task
  *reAuthenticateWithAdsPermissions(fbeConnectionId) {
    this.showCompleteSetupModal = true;
    this.fbeConnectionWithAdsPermissions =
      yield this.getSelectedFbeConnection.perform(fbeConnectionId);

    if (this.fbeConnectionWithAdsPermissions) {
      yield this.#handleFbeFlow([
        this.metaAds.login({
          fbeConnection: this.fbeConnectionWithAdsPermissions.firstObject,
          reAuthenticateAdsPermissions: !this.hasAdsFeature(
            this.fbeConnectionWithAdsPermissions.firstObject
          ),
          hasValidPageCta: this.hasValidPageCta(
            this.fbeConnectionWithAdsPermissions.firstObject
          ),
          hasValidInstagramCta: this.hasValidInstagramCta(
            this.fbeConnectionWithAdsPermissions.firstObject
          ),
        }),
        this.verifyAdsPermissions.perform(),
      ]);
    }
  }

  @task
  *verifyFbIntegration() {
    let [newFbeConnection] = yield this.getSelectedFbeConnection.perform(
      this.newFbeConnection?.id
    );

    if (newFbeConnection) {
      this.showCompleteSetupModal = false;

      this.transitionToAdCampaignList({
        queryParams: {
          filters: JSON.stringify({
            fbeConnectionId: this.newFbeConnection.id,
          }),
        },
      });
    } else {
      if (this.#stopPollingAfterLimit()) {
        yield timeout(VERIFICATION_CALL_INTERVAL);
        yield this.verifyFbIntegration.perform();
      }
    }
  }

  @task
  *verifyAdsPermissions() {
    let selectedFbeConnection = yield this.getSelectedFbeConnection.perform(
      this.fbeConnectionWithAdsPermissions?.firstObject.id
    );

    let hasAdsPermissions = this.#hasValidAdsFeature(
      selectedFbeConnection?.firstObject
    );

    if (hasAdsPermissions) {
      this.showCompleteSetupModal = false;

      this.transitionToAdCampaignList({
        queryParams: {
          filters: JSON.stringify({
            fbeConnectionId: selectedFbeConnection?.firstObject.id,
          }),
        },
      });
    } else {
      if (this.#stopPollingAfterLimit()) {
        yield timeout(VERIFICATION_CALL_INTERVAL);
        yield this.verifyAdsPermissions.perform();
      }
    }
  }

  #stopPollingAfterLimit() {
    this.pollingCounter++;
    return this.pollingCounter < POLLING_LIMIT;
  }

  #hasValidAdsFeature(selectedFbeConnection) {
    return selectedFbeConnection?.enabledFeatures?.some(
      (enabledFeature) =>
        enabledFeature.name === 'ADS' && enabledFeature.isValid === true
    );
  }

  async #handleFbeFlow(array) {
    await Promise.all(array).catch(() => {
      // if the promise is unresolved, no error will surface
      return;
    });
  }
}

export function groupByFirstLetter(list, propertyName = 'name') {
  if (!list) {
    return [];
  }

  let groups = [];

  list.forEach((item) => {
    let letter = item[propertyName].trim().charAt(0).toUpperCase();
    let group = groups.find((g) => g.name === letter);

    if (!group) {
      group = { name: letter, items: [] };
      groups.push(group);
    }

    group.items.push(item);
  });

  return groups.sort((a, b) => a.name.localeCompare(b.name));
}

import Service, { service } from '@ember/service';

const ERROR_CODES = {
  1000: null,
  1001: 'NON_DISCRIMINATION_POLICY',
  1002: 'NOT_CONFIRMED_USER',
  1003: 'TOO_MANY_CALLS',
  1004: 'CHANGED_PASSWORD',
  1005: 'INVALID_TOKEN',
  1006: 'MISSING_LOCATION_COORDINATES',
  1007: 'LOCATION_RADIUS_ERROR',
  1008: 'AUDIENCE_SIZE_TOO_SMALL_ERROR',
  1009: 'END_TIME_IN_THE_PAST_ERROR',
  1010: 'EMPTY_AUDIENCE_ERROR',
  1011: 'UNSUPPORTED_GOAL',
  1012: 'BUSINESS_INFO_MISSING_ERROR',
  1013: 'DUPLICATE_CAMPAIGN_BUSINESS_INFO_MISSING_ERROR',
  1014: 'MISSING_FACEBOOK_FEED_PLACEMENT',
  1101: null,
  2001: 'NO_THUMBNAIL',
};

export default class MetaErrorHandlerService extends Service {
  @service errorHandler;
  @service notifications;
  @service intl;

  handle(error) {
    let firstError = error.errors?.[0]; //reading error from an Apollo mutation request
    if (!firstError) {
      firstError = error.graphQLErrors?.[0]; //reading error from an Apollo query request
    }
    const metaError = firstError?.extensions?.errors?.[0];

    if (metaError && metaError.code in ERROR_CODES) {
      const notificationBody = ERROR_CODES[metaError.code]
        ? this.intl.t(
            `marketing.meta-ads.errors.${ERROR_CODES[metaError.code]}`
          )
        : metaError.message;

      this.notifications.failure(
        this.intl.t('global.error-notification-title'),
        notificationBody,
        {
          sticky: true,
        }
      );

      return metaError;
    } else {
      this.errorHandler.handle(error);
      return null;
    }
  }
}

import { modifier } from 'ember-modifier';

export default modifier(
  function selectOnClick(element) {
    let selectValue = (e) => {
      e.target.select();
    };
    element.addEventListener('click', selectValue);

    return () => {
      element.removeEventListener('click', selectValue);
    };
  },
  { eager: false }
);

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  format,
  convertToLocalTime,
} from 'my-phorest/utils/local-datetime-helpers';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import ClockInMutation from 'my-phorest/gql/mutations/clock-in.graphql';
import ClockOutMutation from 'my-phorest/gql/mutations/clock-out.graphql';
import { format as formatTime } from 'my-phorest/utils/local-time-helpers';

export default class ClockInOutModalComponent extends Component {
  @service intl;
  @service notifications;
  @service clockInOut;

  @queryManager apollo;

  get isOpen() {
    return this.clockInOut.isOpen;
  }

  get staffName() {
    return this.clockInOut.staffName;
  }

  get staffId() {
    return this.clockInOut.staffId;
  }

  get clockInTime() {
    return this.clockInOut.clockInTime;
  }

  get clockOutTime() {
    return this.clockInOut.clockOutTime;
  }

  get isClockedIn() {
    return this.clockInOut.isClockedIn;
  }

  get icon() {
    if (this.isClockedIn) {
      return 'check';
    } else {
      return 'x';
    }
  }

  get variantIcon() {
    if (this.isClockedIn) {
      return 'success';
    } else {
      return 'danger';
    }
  }

  get description() {
    if (this.isClockedIn) {
      return `${this.intl.t(
        'app-main-nav.clock-in-out-modal.description.clock-in'
      )} ${format(this.clockInTime, this.intl)}`;
    } else if (this.clockOutTime) {
      return `${this.intl.t(
        'app-main-nav.clock-in-out-modal.description.clock-out'
      )} ${format(this.clockOutTime, this.intl)}`;
    } else {
      return '';
    }
  }

  @action
  closeModal() {
    this.clockInOut.close();
  }

  @dropTask
  *clockInTask() {
    this.closeModal();
    let clock = yield this.apollo.mutate(
      {
        mutation: ClockInMutation,
        variables: {
          input: {
            staffMemberId: this.staffId,
          },
        },
      },
      'clockIn'
    );
    this.notifications.success(
      this.intl.t('app-main-nav.clock-in-out-modal.success-clock-in.title'),
      this.intl.t(
        'app-main-nav.clock-in-out-modal.success-clock-in.description',
        {
          name: this.staffName,
          time: this.#formatTime(clock.localDateTimeIn),
        }
      )
    );
  }

  @dropTask
  *clockOutTask() {
    this.closeModal();
    let clock = yield this.apollo.mutate(
      {
        mutation: ClockOutMutation,
        variables: {
          input: {
            staffMemberId: this.staffId,
          },
        },
      },
      'clockOut'
    );
    this.notifications.success(
      this.intl.t('app-main-nav.clock-in-out-modal.success-clock-out.title'),
      this.intl.t(
        'app-main-nav.clock-in-out-modal.success-clock-out.description',
        {
          name: this.staffName,
          time: this.#formatTime(clock.localDateTimeOut),
        }
      )
    );
  }

  #formatTime(dateTime) {
    return formatTime(
      convertToLocalTime(dateTime, this.intl),
      this.intl,
      'short'
    );
  }
}

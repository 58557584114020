import Service, { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';

import requestFutureAppointmentPrint from 'my-phorest/gql/mutations/request-future-appointment-print.graphql';
import { dropTask } from 'ember-concurrency';

export const DATE_EPOCH_BEGINNING = '2000-01-01';

export default class BookingHistoryService extends Service {
  @service swingBridge;
  @queryManager apollo;

  @dropTask
  *printFutureBookingsReceiptTask(clientId) {
    return yield this.apollo.mutate(
      {
        mutation: requestFutureAppointmentPrint,
        variables: {
          clientId,
          terminalId: this.swingBridge.terminalId,
        },
      },
      'requestFutureAppointmentPrint'
    );
  }
}

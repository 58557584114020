/*
  We can have the same package added multiple times for a given client and day,
  (or to the same basket) so we need to make sure we only try to edit the set of 
  package appointments for the given appointment (basket item).

  So we have to find the "chunk" of appointments (or basket items) in the whole set of items
  that include the item we're dealing with (passed in `itemToFind`) 

  It's also important to point out that the following chunking logic only works
  as long as package appointments cannot be deleted individually,
  but only together with the other appointments created from the package at that time.

  Also, everything I wrote about appointments above can be applied to package basket items as well,
  as the their creation and editing follows the same mechanism.
*/
export function findPackageBoundary(items, itemToFind, package_) {
  const packageLength = package_.serviceGroupOptions.length;
  const chunks = [];
  for (let i = 0; i < items.length; i += packageLength) {
    chunks.push(items.slice(i, i + packageLength));
  }

  return chunks.find((chunk) =>
    chunk.find((item) => item.id === itemToFind.id)
  );
}

export function getCurrencySymbolPosition(locale, currency) {
  const DEFAULT_POSITION = 'start';

  if (Intl) {
    let formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code',
    });
    let currencyString = formatter.format(99); // number doesn't matter here

    if (currencyString.slice(0, 3) === currency) {
      return 'start';
    } else {
      return 'end';
    }
  } else {
    return DEFAULT_POSITION;
  }

  // more:
  // - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/formatToParts
  // - https://gist.github.com/JackNUMBER/a0e294c0fa23f4e6594e550a30a83a37
}

const add = (a, b) => a + b;
const subtract = (a, b) => a - b;
const multiply = (a, b) => a * b;
const divide = (a, b) => a / b;

const calculateMoney = (doMath) => {
  return (a, b) => {
    let numberA = Number(Number(a).toFixed(2));
    let numberB = Number(Number(b).toFixed(2));

    return doMath(numberA, numberB).toFixed(2);
  };
};

export const addMoney = calculateMoney(add);
export const subtractMoney = calculateMoney(subtract);
export const multiplyMoney = calculateMoney(multiply);
export const divideMoney = calculateMoney(divide);

export const sumMoneyCollection = (collection, propertyName) => {
  return collection.reduce((sum, item) => {
    let prop = item[propertyName];

    // check if we're dealing with a Money object here
    if (
      prop &&
      typeof prop === 'object' &&
      Object.prototype.hasOwnProperty.call(prop, 'amount')
    ) {
      prop = prop.amount;
    }

    return addMoney(sum, prop ?? 0);
  }, 0);
};

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export const SelectionMode = {
  SELECT_ALL: 'SELECT_ALL',
  SELECT_NONE: 'SELECT_NONE',
};

export default class TableSelectionService extends Service {
  @tracked selectionMode = SelectionMode.SELECT_NONE;
  @tracked selectedIds = [];
  @tracked unselectedIds = [];

  // Only maintained currently for single selection to allow components
  // access to the full row data of single selected rows. e.g. to disable
  // a button if the selected row is in a certain state.
  @tracked selectedRows = [];

  @action
  isSelected(id) {
    if (this.selectionMode === SelectionMode.SELECT_NONE) {
      return this.selectedIds.includes(id);
    } else {
      return !this.unselectedIds.includes(id);
    }
  }

  @action
  toggleSelected(row, singleSelect = false) {
    const { id } = row;
    if (singleSelect) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = [];
        this.selectedRows = [];
        return;
      }
      this.selectedIds = [id];
      this.selectedRows = [row];
      return;
    }

    if (this.selectionMode === SelectionMode.SELECT_NONE) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = this.selectedIds.filter((i) => i !== id);
      } else {
        this.selectedIds = [...this.selectedIds, id];
      }
    } else {
      if (this.unselectedIds.includes(id)) {
        this.unselectedIds = this.unselectedIds.filter((i) => i !== id);
      } else {
        this.unselectedIds = [...this.unselectedIds, id];
      }
    }
  }

  @action
  toggleSelectionMode() {
    this.selectionMode =
      this.selectionMode === SelectionMode.SELECT_NONE
        ? SelectionMode.SELECT_ALL
        : SelectionMode.SELECT_NONE;
    this.selectedIds = [];
    this.unselectedIds = [];
  }

  get noneSelected() {
    return (
      this.selectionMode === SelectionMode.SELECT_NONE &&
      !this.selectedIds.length
    );
  }

  get oneSelected() {
    return (
      this.selectionMode === SelectionMode.SELECT_NONE &&
      this.selectedIds.length === 1
    );
  }

  @action
  reset() {
    this.selectionMode = SelectionMode.SELECT_NONE;
    this.selectedIds = [];
    this.unselectedIds = [];
    this.selectedRows = [];
  }
}

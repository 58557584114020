import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CommissionBracketsSlideOverService extends Service {
  @tracked brackets;

  @tracked isOpen = false;

  @action
  requestNewBrackets() {
    this.isOpen = true;
    this.brackets = [
      {
        percentage: '0.0',
        limitAmount: null,
      },
    ];
    return new Promise((resolve) => {
      this._newBrackets = resolve;
    });
  }

  done() {
    this._newBrackets(this.brackets);
    this.isOpen = false;
  }

  close() {
    this._newBrackets(null);
    this.isOpen = false;
  }

  @action
  addBracket() {
    this.brackets = [
      ...this.brackets,
      { percentage: '0.0', limitAmount: null },
    ];
  }

  @action
  deleteBracket(bracket) {
    if (this.brackets.length === 1) return;
    this.brackets = this.brackets.filter((b) => b !== bracket);
  }

  @action
  changeBracketPercentage(bracket, percentage) {
    const newBracket = {
      ...bracket,
      percentage: percentage || '0.0',
    };

    this.#replaceBracket(bracket, newBracket);
  }

  @action
  changeBracketLimitAmount(bracket, limitAmount) {
    const newBracket = {
      ...bracket,
      limitAmount,
    };

    this.#replaceBracket(bracket, newBracket);
  }

  #replaceBracket(bracket, newBracket) {
    this.brackets = this.brackets.map((b) => {
      return b === bracket ? newBracket : b;
    });
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

export default class UiDropdownComponent extends Component {
  placement = this.args.popperPlacement || 'bottom-end';

  options = this.args.popperOptions || {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  };

  constructor() {
    super(...arguments);

    this.guid = guidFor(this);
  }

  get popperOptions() {
    return {
      placement: this.placement,
      ...this.options,
    };
  }
}

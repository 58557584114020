import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { variation } from 'ember-launch-darkly';
import { v4 as uuid } from 'uuid';
import { isTesting } from '@embroider/macros';

import config from 'my-phorest/config/environment';
import { durationBetween } from 'my-phorest/utils/local-datetime-helpers';

const { UNSPECIFIED_REVISION_KEY } = config.APP;
const APP_LOADED_AT_KEY = 'my-phorest:app-loaded-at';

const RELOAD_APP_INTERVAL = 60 * 24 * 2;

export default class VersionTrackerService extends Service {
  @tracked newVersionAvailable = false;

  applicationInstanceId;
  latestRevision = UNSPECIFIED_REVISION_KEY;

  @service branchTime;
  @service swingBridge;
  @service timers;
  @service('browser/local-storage') localStorage;
  @service('browser/window') window;
  @service userIdleness;
  @service pendo;

  initialize() {
    this.setApplicationInstanceId();
    this.listenForCurrentVersionChange();
    this.appLoadedAt = this.branchTime.dateAndTime;
    if (!this.swingBridge.isEmbeddedInSwing) {
      this.timers.registerTimer({
        action: this.maybeReloadApp,
        every: 5_000,
        destroyable: this,
      });
    }
  }

  get launchDarklyClient() {
    return window?.__LD__?.client;
  }

  get minutesSinceLastReload() {
    if (!this.appLoadedAt) {
      return -1;
    }

    return durationBetween(this.branchTime.dateAndTime, this.appLoadedAt).as(
      'minutes'
    );
  }

  get shouldReloadApp() {
    return (
      this.newVersionAvailable &&
      this.minutesSinceLastReload >= RELOAD_APP_INTERVAL
    );
  }

  set appLoadedAt(value) {
    this.localStorage.setItem(APP_LOADED_AT_KEY, value);
  }

  get appLoadedAt() {
    return this.localStorage.getItem(APP_LOADED_AT_KEY);
  }

  listenForCurrentVersionChange() {
    const client = this.launchDarklyClient;
    if (!client) {
      return;
    }

    client.on('change:current-version', this.updateRevision);
  }

  setApplicationInstanceId() {
    this.applicationInstanceId = uuid();

    if (isTesting()) {
      this.window._TESTING_applicationInstanceId = this.applicationInstanceId;
    }
  }

  @action
  maybeReloadApp() {
    if (!variation('ops-auto-reload-app')) {
      return;
    }

    if (this.shouldReloadApp && this.userIdleness.isUserIdle()) {
      this.pendo.trackEvent('Browser - new version auto-reload');
      this.window.location.reload();
    }
  }

  @action
  updateRevision(latest, previous) {
    /*
      This can happen when targeting for the flag is off, or
      when a variation (which was not served to this user) is deleted
    */
    if (latest === previous) {
      return;
    }

    /*
      When we're bootstrapping the `current-version` LD flag,
      LD also sends a changed event when the flag's value goes from the
      boostrapped value, UNSPECIFIED_REVISION_KEY, to the actual
      deployment revision key. In that case, there's no new version
      of the app: it's the one we're booting right now.
    */
    if (this.latestRevision !== UNSPECIFIED_REVISION_KEY) {
      this.newVersionAvailable = true;
    }
    this.latestRevision = latest;
  }

  debugInfoForAppReload() {
    return {
      appLoadedAt: this.appLoadedAt,
      minutesSinceLastReload: this.minutesSinceLastReload,
      staleAppVersion: this.minutesSinceLastReload >= RELOAD_APP_INTERVAL,
      newVersionAvailable: this.newVersionAvailable,
      shouldReloadApp: this.shouldReloadApp,
      lastUserActivity: this.userIdleness.lastActivity,
      idleTime: this.userIdleness.idleTime(),
      isUserIdle: this.userIdleness.isUserIdle(),
    };
  }
}

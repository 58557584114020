import Service from '@ember/service';
import { assert } from '@ember/debug';

const ERROR_NO_EVENT =
  'EventPropagator @ broadcast: nothing is subscribed to the given event';
const ERROR_INVALID_ARGS =
  'EventPropagator @ subscribe: at least one of the arguments is invalid';
const WARN_ALREADY_REGISTERED =
  'EventPropagator @ subscribe: you tried to subscribe with a function that was already registered';

export default class EventPropagator extends Service {
  eventsSubscriptions = {};

  /**
   * @param {string} eventName
   * @param {object} [data]
   */
  broadcast(eventName, data) {
    const event = this.eventsSubscriptions[eventName];
    assert(ERROR_NO_EVENT, !!event);
    event?.subscribers.forEach((callbackFn) => callbackFn(data));
  }

  /**
   * @param {string} eventName
   * @param {function} callbackFn
   */
  subscribe(eventName, callbackFn) {
    this._verifySubscribeArgs(eventName, callbackFn);
    this._ensureEventContainerExists(eventName);

    if (this._isAlreadySubscribed(eventName, callbackFn)) {
      console.warn(WARN_ALREADY_REGISTERED);
      return;
    }

    this.eventsSubscriptions[eventName].subscribers.push(callbackFn);
  }

  /**
   * @param {string} eventName
   * @param {function} callbackFn
   */
  unsubscribe(eventName, callbackFn) {
    if (typeof this.eventsSubscriptions[eventName] === 'undefined') {
      return;
    }

    const { subscribers } = this.eventsSubscriptions[eventName];
    const index = subscribers.indexOf(callbackFn);
    if (index > -1) {
      subscribers.splice(index, 1);
    }
  }

  _ensureEventContainerExists(eventName) {
    if (typeof this.eventsSubscriptions[eventName] === 'undefined') {
      this.eventsSubscriptions[eventName] = {
        subscribers: [],
      };
    }
  }

  _isAlreadySubscribed(eventName, callbackFn) {
    return (
      this.eventsSubscriptions[eventName].subscribers.indexOf(callbackFn) > -1
    );
  }

  _verifySubscribeArgs(eventName, callbackFn) {
    const hasValidArguments =
      typeof eventName === 'string' && typeof callbackFn === 'function';

    assert(ERROR_INVALID_ARGS, hasValidArguments);
  }
}

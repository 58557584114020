import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BulkImportService extends Service {
  @tracked
  isOpen = false;

  @tracked
  selectedBranchIds = {};

  @action
  getBranchIds() {
    this.#open();
    return new Promise((resolve) => {
      this._branchIdsResult = resolve;
    });
  }

  @action
  toggleBranchSelected(branchId) {
    if (this.selectedBranchIds[branchId]) {
      const newSelectedBranchIds = { ...this.selectedBranchIds };
      delete newSelectedBranchIds[branchId];
      this.selectedBranchIds = newSelectedBranchIds;
    } else {
      this.setBranchSelected(branchId);
    }
  }

  @action
  setBranchSelected(branchId) {
    this.selectedBranchIds = {
      ...this.selectedBranchIds,
      [branchId]: true,
    };
  }

  confirm() {
    this._branchIdsResult(Object.keys(this.selectedBranchIds));
    this.#close();
  }

  cancel() {
    this._branchIdsResult(null);
    this.#close();
  }

  #open() {
    this.isOpen = true;
  }

  #close() {
    this.isOpen = false;
  }
}

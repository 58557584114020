import Route from '@ember/routing/route';
import { service } from '@ember/service';

export const IS_SCREEN_LOCKED_KEY = 'is-screen-locked';

export default class PinPadRoute extends Route {
  @service router;
  @service('browser/local-storage') localStorage;

  redirect(model, transition) {
    if (!transition.from && transition.sequence === 0) {
      this.router.replaceWith('accounts');
    } else {
      this.localStorage.setItem(IS_SCREEN_LOCKED_KEY, true);
    }
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  setValueForPath,
  valueFromPath,
} from 'my-phorest/utils/value-from-path';

export default class TableEditingService extends Service {
  @tracked editedResourcesByKeyMap = {};
  keyProperty = ['id']; // For tables with no id, can use another property e.g. ['product', 'id']

  @action
  initialize(keyProperty = ['id']) {
    this.reset();
    this.keyProperty = keyProperty;
  }

  get hasChanges() {
    return !!Object.keys(this.editedResourcesByKeyMap).length;
  }

  get itemsForCreateOrUpdate() {
    return Object.values(this.editedResourcesByKeyMap);
  }

  fieldValue(resource, fieldName) {
    let editedResource = this.#getEditedResource(resource);
    return valueFromPath(editedResource, fieldName);
  }

  @action
  setFieldValue(resource, fieldName, fieldValue, filters) {
    let editedResource = this.#getEditedResource(resource);

    if (filters) {
      editedResource.filters = JSON.parse(filters || '{}');
    }

    setValueForPath(editedResource, fieldName, fieldValue);
    this.editedResourcesByKeyMap = {
      ...this.editedResourcesByKeyMap,
      [this.getKey(editedResource)]: editedResource,
    };
  }

  getKey(resource) {
    // Gets the unique key for a resource - usually just 'id', but some tables don't have ids
    return this.keyProperty.reduce((r, i) => r[i], resource);
  }

  #getEditedResource(resource) {
    let editedResource = this.editedResourcesByKeyMap[this.getKey(resource)];
    return { ...(editedResource || resource) };
  }

  reset() {
    this.editedResourcesByKeyMap = {};
  }
}

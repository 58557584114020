import Service, { service } from '@ember/service';
import { isInstagramApp, isFacebookApp } from 'my-phorest/utils/browser-agent';

export default class PendoService extends Service {
  @service('browser/window') window;
  @service tagManager;

  isInitializing = false;

  identify() {
    this.track({ event: 'Pendo Identify' });
  }

  track(data) {
    if (!this.hasInitialized) {
      this.initialize();
    }
    this.tagManager.push(data);
  }

  trackEvent(pendoEventName, properties = {}) {
    const isInstagram = isInstagramApp(this.window);
    const isFacebook = isFacebookApp(this.window);

    if (isInstagram || isFacebook) {
      // Don't send events when our application is displayed in Instagram's
      // or Facebook's internal browser.
      // More details: https://phorest.jira.com/browse/WT-1720
      return;
    }

    this.track({
      event: 'Pendo Event',
      eventPendoEventName: pendoEventName,
      eventPendoEventProperties: {
        ...properties,
      },
    });
  }

  initialize() {
    if (this.isInitializing || this.hasInitialized) {
      return;
    }
    this.isInitializing = true;
    this.track({ event: 'Pendo Initialize' });
    this.isInitializing = false;
  }

  get hasInitialized() {
    return !!this.window.pendo;
  }
}

import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { permission } from 'my-phorest/decorators/permission';

export default class PersistGapTimeService extends Service {
  @tracked _modalIsOpen = false;
  @tracked calendarJSDropEvent;

  @service fullCalendar;

  get calendarApi() {
    return this.fullCalendar.calendarApi;
  }

  get isModalOpen() {
    return this._modalIsOpen;
  }

  @action
  closeModal() {
    this._modalIsOpen = false;
  }

  @action
  openModal() {
    this._modalIsOpen = true;
  }

  @action
  closeModalAndRefetchResources() {
    this.closeModal();
    this.refetchResources();
  }

  @action
  refetchResources() {
    this.calendarApi.refetchResources();
  }

  @action
  shouldAsk(calendarJSDropEvent) {
    let appointments = this.#todayClientAppointments(calendarJSDropEvent);
    return (
      appointments.length > 1 &&
      this.#anyServiceHasGapTime(appointments) &&
      this.#eventHasNewTime(calendarJSDropEvent)
    );
  }

  @dropTask
  @permission('appointments.reschedule', {
    onDeny: function () {
      this.calendarApi.refetchResources();
    },
  })
  *showModal(calendarJSDropEvent) {
    this.calendarJSDropEvent = calendarJSDropEvent;
    yield this.openModal();
  }

  #anyServiceHasGapTime(appointments) {
    return appointments.some(
      (appointment) => appointment.extendedProps.service?.gapTimeMins > 0
    );
  }

  #todayClientAppointments({ event }) {
    let { clientId, date } = event.extendedProps;
    return this.calendarApi
      .getEvents()
      .filter(
        (appointment) =>
          appointment.extendedProps.clientId === clientId &&
          appointment.extendedProps.date === date
      );
  }

  #eventHasNewTime({ delta }) {
    return delta?.milliseconds !== 0;
  }
}

import Service from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';
import { queryManager } from 'ember-apollo-client';

import clientLastPatchTestQuery from 'my-phorest/gql/queries/client-last-patch-test.graphql';

export default class AppointmentPromptsService extends Service {
  #customPromptResolver;

  customPrompts = new TrackedObject({ prompts: [], isModalOpen: false });
  patchTestRequired = new TrackedObject({ lastTest: null, isModalOpen: false });

  @queryManager apollo;

  @dropTask
  *maybeShowCustomPromptsTask(options) {
    const { appointments, type } = options;

    let customPrompts = appointments.map((appointment) => {
      return appointment.customPrompts?.filter(
        (prompt) => prompt.type === type
      );
    });

    if (customPrompts[0]?.length === 0) return;

    this.customPrompts.prompts = customPrompts;
    this.customPrompts.isModalOpen = true;

    yield new Promise((resolve) => {
      this.#customPromptResolver = resolve;
    });
  }

  @action
  resetCustomPrompts() {
    this.customPrompts.isModalOpen = false;
    this.customPrompts.prompts = [];
    this.confirmCustomPrompts();
  }

  @action
  confirmCustomPrompts() {
    this.#customPromptResolver();
  }

  @dropTask
  *maybeShowPatchTestRequiredModalTask(apptInput) {
    const appointments = Array.isArray(apptInput) ? apptInput : [apptInput];
    const doesAnyServiceRequirePatchTest = appointments.some(
      (appointment) => appointment.service.patchTestRequired
    );

    if (doesAnyServiceRequirePatchTest) {
      const clientId = appointments[0].client.id;
      const results = yield this.apollo.query(
        {
          query: clientLastPatchTestQuery,
          variables: {
            clientId,
          },
          fetchPolicy: 'network-only',
        },
        'patchTests'
      );
      const lastPatchTest = results.edges?.[0]?.node;

      if (
        !lastPatchTest ||
        lastPatchTest.isExpired ||
        lastPatchTest.result !== 'PASSED'
      ) {
        this.patchTestRequired.lastTest = lastPatchTest;
        this.patchTestRequired.isModalOpen = true;
      }
    }
  }
}

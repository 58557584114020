/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';

export default class UiSvgComponent extends Component {
  insertSvg = modifier(
    (element, [svgHtml]) => {
      let parser = new DOMParser();
      let { documentElement: svg } = parser.parseFromString(
        svgHtml,
        'image/svg+xml'
      );

      for (let attr of svg.attributes) {
        element.setAttribute(attr.name, attr.value);
      }

      element.innerHTML = svg.innerHTML;
    },
    { eager: false }
  );
}

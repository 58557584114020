import { service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class PhorestIntlService extends IntlService {
  @service session;

  get defaultLocale() {
    return this.primaryLocale;
  }

  get force24HourClock() {
    return this.session.business?.force24HourClock;
  }
}

import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TextInputDialogService extends Service {
  @tracked
  isOpen = false;

  @tracked
  text = '';

  @tracked
  options = {};

  @action
  ask(options = {}) {
    this.options = options;
    this.#open();
    return new Promise((resolve) => {
      this._confirm = resolve;
    });
  }

  confirm() {
    this._confirm({
      confirmed: true,
      text: this.text,
    });
    this.#close();
  }

  deny() {
    this._confirm({
      confirmed: false,
    });
    this.#close();
  }

  #open() {
    this.isOpen = true;
  }

  #close() {
    this.text = '';
    this.isOpen = false;
  }
}

import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';

const LOCAL_STORAGE_SETTING_KEY = 'my-phorest:screen-locked';

export default class AutoLogoutService extends Service {
  @service('browser/local-storage') localStorage;
  @service access;
  @service timers;
  @service swingBridge;
  @service userIdleness;
  @service session;
  @service clockInOut;

  logoutTimeInMinutes = null;
  timerId = null;
  #isLocked;

  set isLocked(value) {
    this.#isLocked = value;
    this.localStorage.setItem(LOCAL_STORAGE_SETTING_KEY, JSON.stringify(value));
  }

  get isLocked() {
    if (typeof this.#isLocked !== 'undefined') {
      return this.#isLocked;
    }

    let value = JSON.parse(
      this.localStorage.getItem(LOCAL_STORAGE_SETTING_KEY) ?? 'false'
    );

    return !!value;
  }

  activate(logoutTime) {
    if (this.swingBridge.isEmbeddedInSwing) return;

    this.logoutTimeInMinutes = logoutTime;

    this.timerId = this.timers.registerTimer({
      action: this.checkUserIdleness,
      every: 5_000,
      destroyable: this,
    });

    if (this.isLocked) {
      this.lockScreen.perform();
    }
  }

  deactivate() {
    if (this.timerId) {
      this.timers.unregisterTimer(this.timerId);
    }
  }

  get logoutTimeInSeconds() {
    return this.logoutTimeInMinutes * 60;
  }

  @action
  checkUserIdleness() {
    if (!this.logoutTimeInMinutes) return;
    if (this.isLocked) return;

    const idleTime = this.userIdleness.idleTime({ granularity: 'seconds' });
    if (idleTime >= this.logoutTimeInSeconds) {
      this.lockScreen.perform();
    }
  }

  @dropTask
  *lockScreen() {
    this.isLocked = true;

    yield this.access.openPinPadAndSwitchUser({
      cancelDisabled: true,
      hideBackground: true,
      onCancel: this.lockScreen.perform,
    });

    this.isLocked = false;

    if (this.session.branch.staffMemberSettings?.clockInOutEnabled) {
      this.clockInOut.checkClockInOut(this.session.currentStaffMember, {
        showOnlyWhenClockedOut: true,
      });
    }
  }
}

import Service, { service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import {
  fromJSDate as dateFromJSDate,
  addMonths,
} from 'my-phorest/utils/local-date-helpers';

export default class BreakSlideOverService extends Service {
  @service intl;
  @service session;

  @tracked break;
  @tracked date;
  @tracked isOpen = false;
  @tracked showPaidBreak;

  open(date, resource, { showPaidBreak }) {
    this.isOpen = true;
    this.date = date;
    this.resource = resource;
    this.showPaidBreak = showPaidBreak;
    this.break = tracked(this.defaults);
  }

  close() {
    this.isOpen = false;
  }

  get defaults() {
    return {
      label: this.intl.t('break-slide-over.default-break-label'),
      durationMin: 30,
      isPaid: !!this.session.business.staffMembersSettings?.breakPaidByDefault,
      repeatOptions: {
        enabled: false,
        untilDate: addMonths(dateFromJSDate(this.date), 12 * 3),
      },
    };
  }
}

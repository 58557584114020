import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const NEW_ID = 'new-id';

export default class BranchOverrideService extends Service {
  @tracked editedResourcesByBranchIdMap = {};
  @tracked chainResource = null;

  get hasChanges() {
    return !!Object.keys(this.editedResourcesByBranchIdMap).length;
  }

  get itemsForCreateOrUpdate() {
    return Object.values(this.editedResourcesByBranchIdMap).map((item) => {
      const { id, ...rest } = item;
      return {
        id: id === NEW_ID ? null : id,
        ...rest,
      };
    });
  }

  @action
  initializeWithChainResource(chainResource) {
    this.reset();
    this.chainResource = chainResource;
  }

  @action
  isEnabled(resource) {
    let resourceToCheck =
      this.editedResourcesByBranchIdMap[resource.branch.id] || resource;
    return !!resourceToCheck.id && !resourceToCheck.archived;
  }

  @action
  toggleEnabled(resource) {
    let editedResource = this.getEditedResource(resource);
    if (!editedResource.id) {
      editedResource = {
        ...editedResource,
        id: NEW_ID,
      };
    } else if (editedResource.id === NEW_ID) {
      editedResource = {
        ...editedResource,
        id: null,
      };
    } else {
      editedResource = {
        ...editedResource,
        archived: !editedResource.archived,
      };
    }

    this.editedResourcesByBranchIdMap = {
      ...this.editedResourcesByBranchIdMap,
      [editedResource.branch.id]: editedResource,
    };
  }

  @action
  fieldValue(resource, fieldName) {
    let editedResource = this.getEditedResource(resource);
    return editedResource[fieldName];
  }

  @action
  setFieldValue(resource, fieldName, fieldValue) {
    let editedResource = this.getEditedResource(resource);
    editedResource[fieldName] = fieldValue;
    this.editedResourcesByBranchIdMap = {
      ...this.editedResourcesByBranchIdMap,
      [editedResource.branch.id]: editedResource,
    };
  }

  getEditedResource(resource) {
    let editedResource = this.editedResourcesByBranchIdMap[resource.branch.id];
    return { ...(editedResource || resource) };
  }

  reset() {
    this.chainResource = null;
    this.editedResourcesByBranchIdMap = {};
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class ProfileActionsComponent extends Component {
  @service session;
  @service electronApp;
  @service access;
  @service router;

  get showLogoutButton() {
    return !this.electronApp.isRunningInElectron || !this.session.isTerminal;
  }

  @action
  logout() {
    this.router.transitionTo('logout');
  }

  @action
  shutdown() {
    this.electronApp.shutdown();
  }

  @action
  minimize() {
    this.electronApp.minimize();
  }

  @action
  lockScreen() {
    if (this.session.isTerminal) {
      this.session.forgetCurrentUser();
    }
    this.router.transitionTo('pin-pad');
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { ref } from 'ember-ref-bucket';

export default class UiInputIntegerWithKeypadComponent extends Component {
  @service keypad;
  @service intl;

  @ref('$input') $input;

  @action
  openKeypad() {
    this.keypad.openKeypad({
      value: this.args.amount || '0',
      headerText: this.args.headerText || this.intl.t('global.enter-amount'),
      variant: 'integer',
      allowZero: this.args.allowZero ?? true,
      allowNegative: this.args.allowNegative ?? true,
      allowNull: this.args.allowNull,
      onChange: this.onKeypadChange,
    });
  }

  @action
  onKeypadChange(amount) {
    this.onSanitizedInput(amount);
  }

  @action
  onSanitizedInput(amount) {
    const regex = new RegExp('^[0-9]*$');
    let isValidInput = regex.test(amount);

    if (isValidInput) {
      let number = parseInt(amount);
      this.args.onInput(
        isNaN(number) ? (this.args.allowNull ? null : 0) : number
      );
    } else {
      this.$input.value = amount.slice(0, -1);
    }
  }

  @action
  handleKeyUp(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.args.form?.submit(event);
    }
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { WEEKDAYS } from 'my-phorest/utils/local-date-helpers';
import { service } from '@ember/service';

export default class UiDayPickerComponent extends Component {
  @service intl;

  isActive = (day, dayIndex) => {
    return (
      this.args.selectedIndex === dayIndex ||
      this.args.selectedDay === day ||
      this.args.selectedIndices?.includes(dayIndex) ||
      this.args.selectedDays?.includes(day)
    );
  };

  @action
  handleClick(selectedDay, selectedDayIndex) {
    if (this.args.multiSelect) {
      let selectedIndices = this.args.selectedIndices;

      if (!selectedIndices) {
        selectedIndices = this.args.selectedDays.map((day) =>
          WEEKDAYS.indexOf(day)
        );
      }

      if (selectedIndices.includes(selectedDayIndex)) {
        selectedIndices = selectedIndices.filter(
          (index) => index != selectedDayIndex
        );
      } else {
        selectedIndices.push(selectedDayIndex);
        selectedIndices.sort();
      }

      let selectedDays = selectedIndices.map((index) => WEEKDAYS[index]);

      this.args.onSelect(selectedDays, selectedIndices);
    } else {
      this.args.onSelect(selectedDay, selectedDayIndex);
    }
  }

  @action
  formattedDay(day) {
    return this.intl.t(
      `global.short-week-days.${day.toLowerCase().substring(0, 3)}`
    );
  }
}

import { modifier } from 'ember-modifier';
import { createFocusTrap } from 'focus-trap';

export default modifier(
  function focusTrap(
    element,
    positional,
    { focusTrapOptions, isDisabled } = {}
  ) {
    let trap = createFocusTrap(element, focusTrapOptions);
    if (!isDisabled) {
      trap.activate();
    }

    return () => {
      trap.deactivate();
    };
  },
  { eager: false }
);

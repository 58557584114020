import Service from '@ember/service';
import { task } from 'ember-concurrency';
import Terminal from 'my-phorest/gql/queries/terminal.graphql';
import { queryManager } from 'ember-apollo-client';

export default class TerminalLoaderService extends Service {
  @queryManager apollo;

  @task
  *getTerminal(terminalId) {
    return yield this.apollo.query(
      {
        query: Terminal,
        variables: {
          id: terminalId,
        },
      },
      'terminal'
    );
  }
}

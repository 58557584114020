/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class UiMultiSelectComponent extends Component {
  @service intl;

  @tracked isOpen = false;
  hasScrolledToSelectedElement = false;

  constructor() {
    super(...arguments);

    this.guid = guidFor(this);
  }

  get selectedOptions() {
    return this.args.selectedOptions;
  }

  get buttonLabel() {
    if (this.args.buttonLabel) {
      return this.args.buttonLabel;
    } else if (!this.selectedOptions || this.selectedOptions.length === 0) {
      return '';
    } else if (this.selectedOptions.length === 1) {
      return this.selectedOptions[0].label || this.selectedOptions[0].name;
    } else {
      return this.intl.t('global.count-selected', {
        count: this.selectedOptions.length,
      });
    }
  }

  get options() {
    return this.args.options?.map((option) => {
      return {
        ...option,
        selected: !!this.selectedOptions?.findBy('id', option.id),
      };
    });
  }

  @action
  handleButtonClick() {
    this.hasScrolledToSelectedElement = false;
    this.isOpen = !this.isOpen;
  }

  @action
  handleClickOutside(e) {
    e.preventDefault();
    this.isOpen = false;
    return true;
  }

  @action
  handleDeactivate() {
    this.isOpen = false;
  }

  @action
  handleEscape(event) {
    if (event.key === 'Escape') {
      this.isOpen = false;
    }
  }

  @action
  handleOptionToggle(option, selected) {
    let selectedOptions = this.selectedOptions?.rejectBy('id', option.id) || [];

    if (selected) {
      delete option.selected;
      selectedOptions.push(option);
    }

    if (this.args.onChange) {
      this.args.onChange(selectedOptions);
    }
  }

  @action
  scrollOptionElement(optionElement, isSelected) {
    if (isSelected && !this.hasScrolledToSelectedElement) {
      optionElement.parentElement.scroll(
        0,
        optionElement.offsetTop - optionElement.parentElement.offsetTop - 50
      );
      this.hasScrolledToSelectedElement = true;
    }
  }
}

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { BREAKPOINT_MD, isAboveBreakpoint } from 'my-phorest/utils/breakpoints';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class AccountsAccountController extends Controller {
  @service access;
  @service router;
  @service session;
  @service swingBridge;

  @tracked isSidebarOpen = isAboveBreakpoint(BREAKPOINT_MD);

  get isEmbeddedInSwing() {
    return this.swingBridge.isEmbeddedInSwing;
  }

  get padSidebar() {
    return !this.isEmbeddedInSwing && this.isSidebarOpen;
  }

  get pageTitle() {
    let branchName = '';
    if (this.session.branch && this.session.branchList?.length > 1) {
      branchName = this.session.branch.shortName;
    }
    return branchName;
  }

  @action
  validateActivePermissions() {
    if (
      this.session.isAuthenticated &&
      (!this.access.activePermissionsValid ||
        !this.session.currentUserHasAccessToCurrentAccount)
    ) {
      this.router.replaceWith('pin-pad');
    }
  }
}

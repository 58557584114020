import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

function uniqueIssuesByType(issues) {
  return issues.reduce((issuesByType, issue) => {
    if (!issuesByType.find((i) => i.issueType === issue.issueType)) {
      issuesByType.push(issue);
    }
    return issuesByType;
  }, []);
}
export default class AppointmentIssuesService extends Service {
  @tracked isOpen = false;
  @tracked issues;
  @tracked context;

  #callback = null;

  @action
  askForConfirmation({ issues, context }) {
    this.context = context;
    this.issues = uniqueIssuesByType(issues);
    this.#open();
    return new Promise((resolve) => {
      this.#callback = resolve;
    });
  }

  @action
  confirm() {
    this.#callback(true);
    this.#close();
  }

  @action
  deny() {
    this.#callback(false);
    this.#close();
  }

  #open() {
    this.isOpen = true;
  }

  #close() {
    this.isOpen = false;
    this.issues = null;
    this.context = null;
  }
}

import Service from '@ember/service';
import { assert } from '@ember/debug';
import { TrackedSet } from 'tracked-built-ins';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';

import recentClientFieldsFragment from 'my-phorest/gql/fragments/recent-client.graphql';

const MAX_RECENT_CLIENTS = 5;

export default class RecentClientsService extends Service {
  @queryManager apollo;

  #ids = new TrackedSet();

  #items = trackedTask(this, this.getRecentClientsTask, () => [this.#ids]);

  get items() {
    return this.#items.value;
  }

  @task
  *getRecentClientsTask() {
    const apolloCache = this.apollo.apollo.client.cache;
    const clients = [];

    for (const id of this.#ids) {
      const client = yield apolloCache.readFragment({
        id: `Client:${id}`,
        fragment: recentClientFieldsFragment,
      });

      assert(`Couldn't find the client with id: ${id}`, client);
      clients.push(client);
    }
    return clients.reverse();
  }

  hasItems() {
    return this.#ids.size > 0;
  }

  remove(client) {
    this.#ids.delete(client.id);
  }

  add(client) {
    const { id } = client;
    if (!id) {
      return;
    }

    this.#ids.add(id);
    if (this.#ids.size > MAX_RECENT_CLIENTS) {
      const oldestId = this.#ids.values().next().value;
      this.#ids.delete(oldestId);
    }
  }

  clear() {
    this.#ids.clear();
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CommissionSettingsService extends Service {
  // When commission settings are created, they are not yet assigned to a
  // commission system because the commission system is not yet created. We
  // keep track of them here until the commission system is saved.
  @tracked newCommissionSettings = [];

  addNewCommissionSetting(commissionSetting) {
    this.newCommissionSettings = [
      commissionSetting,
      ...this.newCommissionSettings,
    ];
  }

  clearCommissionSettings() {
    this.newCommissionSettings = [];
  }
}

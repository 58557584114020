/* eslint-disable phorest/moment-import */
import { assert } from '@ember/debug';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { convertToTimeZone } from './local-datetime-helpers';

const DATE_FORMAT_PRESETS = {
  numeric: { dateStyle: 'short' },
  tiny: { dateStyle: 'medium' },
  short: { year: 'numeric', month: 'long', day: 'numeric' },
  long: { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' },
  full: { dateStyle: 'full' },
  'weekday-short': { weekday: 'short' },
  'weekday-long': { weekday: 'long' },
};

const WEEKDAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const WEEK_OF_MONTH = {
  first: 'FIRST',
  second: 'SECOND',
  third: 'THIRD',
  fourth: 'FOURTH',
  fifth: 'FIFTH',
  last: 'LAST',
};

const momentFormatTimeTokens = [
  'LT',
  'LTS',
  'LLL',
  'lll',
  'LLLL',
  'llll',
  'A',
  'a',
  'H',
  'HH',
  'h',
  'hh',
  'k',
  'kk',
  'm',
  'mm',
  's',
  'ss',
  'S',
  'SS',
  'SSS',
];

function today(formatString = moment.HTML5_FMT.DATE) {
  return moment.utc().format(formatString);
}

function isAfter(thisDate, thatDate) {
  let d1 = asMoment(thisDate);
  let d2 = asMoment(thatDate);

  return d1.isAfter(d2);
}

function isBefore(thisDate, thatDate) {
  let d1 = asMoment(thisDate);
  let d2 = asMoment(thatDate);

  return d1.isBefore(d2);
}

function isBeforeToday(thisDate) {
  let d1 = asMoment(thisDate);
  let today = asMoment().startOf('day');

  return d1.isBefore(today);
}

function isSame(thisDate, thatDate) {
  let d1 = asMoment(thisDate);
  let d2 = asMoment(thatDate);

  return d1.isSame(d2);
}

function isBetween(thisDate, startDate, endDate) {
  return (
    (isSame(thisDate, startDate) || isAfter(thisDate, startDate)) &&
    (isSame(thisDate, endDate) || isBefore(thisDate, endDate))
  );
}

function isValidDate(dateString, format = moment.HTML5_FMT.DATE) {
  let m = asMoment(dateString);

  return m.isValid() && m.format(format) === dateString;
}

function isValidFormat(formatString) {
  const requireTimeData = momentFormatTimeTokens.some((token) => {
    return formatString.includes(token);
  });
  return !requireTimeData;
}

function todayInTimeZone(timezone) {
  return getDate(convertToTimeZone(new Date().toISOString(), timezone));
}

function addDays(dateString, days = 1) {
  return asMoment(dateString)
    .startOf('day')
    .add(days, 'days')
    .format(moment.HTML5_FMT.DATE);
}

function addWeeks(dateString, weeks = 1) {
  return asMoment(dateString)
    .startOf('day')
    .add(weeks, 'weeks')
    .format(moment.HTML5_FMT.DATE);
}

function subtractDays(dateString, days = 1) {
  return asMoment(dateString)
    .startOf('day')
    .subtract(days, 'days')
    .format(moment.HTML5_FMT.DATE);
}

function addMonths(dateString, months = 1) {
  return asMoment(dateString)
    .startOf('day')
    .add(months, 'months')
    .format(moment.HTML5_FMT.DATE);
}

function addTimePeriod(dateString, type, amount = 1) {
  return asMoment(dateString)
    .startOf('day')
    .add(amount, type)
    .format(moment.HTML5_FMT.DATE);
}

function subtractMonths(dateString, months = 1) {
  return asMoment(dateString)
    .startOf('day')
    .subtract(months, 'months')
    .format(moment.HTML5_FMT.DATE);
}

function asWeekDay(dateString, formatString = 'dddd') {
  return asMoment(dateString)
    .startOf('day')
    .locale('en')
    .format(formatString)
    .toUpperCase();
}

function asSchemaWeekDay(dateString) {
  // XXX invoke formatDate with a dummy English locale to return one of `enum WeekDay`

  return format(
    dateString,
    { defaultLocale: ['en-US'] },
    'weekday-long'
  ).toUpperCase();
}

/** format: format a date in the preferred style for the current user's locale
 * @param theDate ISO date string or Date object
 * @param intlService
 * @param formatOptions
 *  a string date format preset (see https://my-dev.phorest.com/styleguide?s=i18n&ss=Date%2FTime%20Presets)
 *  or, an object with Intl format options (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat)
 * @returns a formatted date string
 */
function format(theDate, intlService, formatOptions = 'long') {
  assert(
    'You must provide the intl service in order to use this function',
    !isEmpty(intlService)
  );

  if (!theDate) {
    return;
  }

  assert(
    `You must pass theDate as a string or Date object; you passed ${typeof theDate}`,
    typeof theDate === 'string' || theDate instanceof Date
  );

  if (formatOptions === 'iso') {
    let utcDate = new Date(theDate).toUTCString();
    return new Date(utcDate).toISOString().substring(0, 10);
  }

  if (typeof formatOptions === 'string') {
    if (DATE_FORMAT_PRESETS[formatOptions]) {
      formatOptions = DATE_FORMAT_PRESETS[formatOptions];
    } else {
      assert(
        `Invalid date format ${formatOptions}. You must pass a preset or Intl formatOptions.`
      );
    }
  }

  return new Intl.DateTimeFormat(intlService.defaultLocale, {
    ...formatOptions,
    timeZone: 'UTC',
  }).format(new Date(theDate));
}

function datesBetween(startDateString, endDateString, opts) {
  opts = opts || {};
  let { inclusive = false } = opts;

  let start = asMoment(startDateString).startOf('day');
  let end = asMoment(endDateString).startOf('day');
  let duration = end.diff(start, 'days');

  let dates = [start];
  if (duration > 0) {
    let extraDates = [...Array(duration).keys()].map((value) =>
      start.clone().add(value + 1, 'days')
    );

    dates = [...dates, ...extraDates];
  }

  dates = dates.map((date) => date.format(moment.HTML5_FMT.DATE));

  if (!inclusive) {
    dates.shift();
    dates.pop();
  }

  return dates;
}

function asMoment(dateString) {
  if (typeof dateString === 'undefined') return moment.utc();

  return moment.utc(dateString, moment.HTML5_FMT.DATE);
}

function asJSDate(dateString) {
  return asMoment(dateString).toDate();
}

function fromJSDate(date) {
  return moment.utc(date).format(moment.HTML5_FMT.DATE);
}

function asLocalDate(input) {
  if (typeof input === 'string' && isValidDate(input)) {
    return input;
  }
  if (input instanceof Date) {
    return fromJSDate(input);
  }
  if (input instanceof moment) {
    return input.format(moment.HTML5_FMT.DATE);
  }
  assert(
    `asLocalDate expects input as a date string, a native JS Date object, or a moment object. Got ${input}`
  );
}

function dayWithOrdinal(dateString) {
  return asMoment(dateString).format('Do');
}

function dayOfMonth(dateString) {
  return asMoment(dateString).format('D');
}

function monthName(dateString) {
  return asMoment(dateString).format('MMMM');
}

function month(dateString) {
  return asMoment(dateString).month();
}

function year(dateString) {
  return asMoment(dateString).year();
}

function setYear(dateString, year) {
  return asMoment(dateString).year(year);
}

function weekOfYear(dateString) {
  return asMoment(dateString).isoWeek();
}

function dayOfWeek(dateString) {
  return asMoment(dateString).day();
}

function firstOfMonth(dateString) {
  return asMoment(dateString).startOf('month').format(moment.HTML5_FMT.DATE);
}

function lastOfMonth(dateString) {
  return asMoment(dateString).endOf('month').format(moment.HTML5_FMT.DATE);
}

function dateOfDayBeforeDate(day, dateString) {
  if (asWeekDay(dateString) === day) {
    return dateString;
  }

  return dateOfDayBeforeDate(day, subtractDays(dateString, 1));
}

function dateOfDayAfterDate(day, dateString) {
  if (asWeekDay(dateString) === day) {
    return dateString;
  }

  return dateOfDayAfterDate(day, addDays(dateString, 1));
}

function getDate(dateString) {
  let date = dateString.split('T')[0];
  return /\d{4}-\d{2}-\d{2}/.test(date) && date;
}

function getHourAndMinutes(dateString) {
  let [, time] = dateString.split('T');
  if (!time) {
    return;
  }

  if (!/\d{2}:\d{2}(:\d{2})?/.test(time)) {
    return;
  }
  return time.split(':').slice(0, 2).join(':');
}

export {
  today,
  addDays,
  addWeeks,
  addMonths,
  addTimePeriod,
  asLocalDate,
  asJSDate,
  asMoment,
  asWeekDay,
  asSchemaWeekDay,
  dateOfDayBeforeDate,
  dateOfDayAfterDate,
  datesBetween,
  dayOfWeek,
  format,
  fromJSDate,
  getDate,
  getHourAndMinutes,
  isAfter,
  isBefore,
  isBeforeToday,
  isSame,
  isBetween,
  isValidDate,
  isValidFormat,
  dayWithOrdinal,
  dayOfMonth,
  monthName,
  month,
  year,
  setYear,
  subtractDays,
  subtractMonths,
  weekOfYear,
  firstOfMonth,
  lastOfMonth,
  todayInTimeZone,
  WEEKDAYS,
};

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class UiFormErrorPropagatorComponent extends Component {
  @tracked
  blurredItems = [];

  onBlur = modifier(
    (element, [errorKey]) => {
      let handler = () => {
        if (!this.blurredItems.includes(errorKey)) {
          next(() => {
            this.blurredItems = [...this.blurredItems, errorKey];
          });
        }
      };

      element.addEventListener('blur', handler);

      return () => {
        element.removeEventListener('blur', handler);
      };
    },
    { eager: false }
  );

  get errors() {
    if (this.args.showAllErrors) return this.args.errors;
    let errorsToShow = {};
    this.blurredItems.forEach((i) => {
      errorsToShow[i] = this.args.errors[i];
    });
    return errorsToShow;
  }
}

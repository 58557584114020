/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

const PADDINGS_SIZE = {
  xs: ' px-2.5 py-1.5',
  sm: ' px-3 py-2',
  md: ' px-4 py-2',
  lg: ' px-4 py-2',
  xl: ' px-6 py-3',
};

// By default, x and y paddings are different. Shaping a button into a rectangular form.
// Some shapes like squares or circles need paddings to be symmetrical.
const SYMMETRIC_PADDINGS_SIZE = {
  xs: ' p-1',
  sm: ' p-1.5',
  md: ' p-2',
  lg: ' p-2',
  xl: ' p-3',
};

export default class UiButtonComponent extends Component {
  get paddings() {
    if (this.args.shape) {
      return (
        SYMMETRIC_PADDINGS_SIZE[this.args.size] || SYMMETRIC_PADDINGS_SIZE.md
      );
    }
    return PADDINGS_SIZE[this.args.size] || PADDINGS_SIZE.md;
  }
}

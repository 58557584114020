import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class TableReorderingService extends Service {
  @tracked reorderedRows = null;

  @action
  reset() {
    this.reorderedRows = null;
  }

  get hasReorderedRows() {
    return !!this.reorderedRows;
  }
}

import Service from '@ember/service';
import { service } from '@ember/service';
import config from '../config/environment';
import supportedCurrency from 'my-phorest/utils/supported-meta-currency';
import { dropTask } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import createFbeConnectionMutation from 'my-phorest/gql/mutations/create-fbe-connection.graphql';

export default class FacebookService extends Service {
  @queryManager apollo;
  @service session;
  @service metaErrorHandler;

  getBusinessSetUpExtras(integration) {
    let { branch } = this.session;
    let { timezone, shortName: name } = branch;
    let { countryCode } = branch.locale;
    let currency = supportedCurrency(countryCode);

    return {
      setup: {
        external_business_id: integration.id,
        timezone,
        currency,
        business_vertical: 'APPOINTMENTS',
      },
      business_config: {
        business: {
          name,
        },
        ig_cta: this._getIgCtaSetup(),
        page_cta: this._getFbPageCtaSetup(),
      },
      repeat: false,
    };
  }

  @dropTask
  *createFbeIntegration(label) {
    try {
      return yield this.apollo.mutate(
        {
          mutation: createFbeConnectionMutation,
          variables: {
            input: {
              label,
            },
          },
        },
        'createFbeConnection'
      );
    } catch (error) {
      const metaError = this.metaErrorHandler.handle(error);
      throw metaError;
    }
  }

  _getCtaButtonUrl() {
    let { business, branch } = this.session;

    const onlineBookingURL = `${config.APP.onlineBookingRootURL}/${branch.domainName}`;
    const onlineBookingBranchPickerURL =
      config.APP.onlineBookingBranchPickerURL.replace(
        '$DOMAIN_NAME',
        business.domainName
      );

    return business.isSingleBranch
      ? onlineBookingURL
      : onlineBookingBranchPickerURL;
  }

  _getFbPageCtaSetup() {
    const ctaUrl = this._getCtaButtonUrl();
    return {
      enabled: true,
      cta_button_text: 'Book Now',
      cta_button_url: `${ctaUrl}?utm_source=fb_book_now`,
    };
  }

  _getIgCtaSetup() {
    const ctaUrl = this._getCtaButtonUrl();
    return {
      enabled: true,
      cta_button_text: 'Book Now',
      cta_button_url: `${ctaUrl}?utm_source=insta_book_now`,
    };
  }
}

export const PAYMENT_TYPES = {
  ACCOUNT: 'ACCOUNT',
  APPOINTMENT_DEPOSIT: 'APPOINTMENT_DEPOSIT',
  CASH: 'CASH',
  CHEQUE: 'CHEQUE',
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  MEMBERSHIP: 'MEMBERSHIP',
  OTHER: 'OTHER',
  PHOREST_PAY_CREDIT_CARD: 'PHOREST_PAY_CREDIT_CARD',
  PHOREST_PAY_DEBIT_CARD: 'PHOREST_PAY_DEBIT_CARD',
  VOUCHER: 'VOUCHER',
};

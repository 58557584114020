import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

const MODE_IDLE = 'IDLE';
const MODE_RESCHEDULING = 'RESCHEDULING'; // for moving/copying *existing* appointments
const MODE_WAITLIST = 'WAITLIST'; // for placing *new* appointments from the waitlist

export const RESCHEDULE_ACTIONS = {
  COPY: 'COPY',
  MOVE: 'MOVE',
};
const noop = () => {};

export default class SchedulingProviderService extends Service {
  @tracked mode = MODE_IDLE;
  @tracked bookingsToSchedule = [];
  @tracked isGroupBookingReschedule = false;
  @tracked showGroupBookingOptionsModal = false;
  @tracked showGroupBookingAppointmentsSelectionModal = false;
  @tracked originalServicesToReschedule = [];
  allowedRescheduleActions = [];
  openAppointmentsSlideOverAfterCopy = true;
  cancelCallback = noop;

  get isScheduling() {
    return this.mode !== MODE_IDLE;
  }

  get isModeRescheduling() {
    return this.mode === MODE_RESCHEDULING;
  }

  get isModeWaitlistScheduling() {
    return this.mode === MODE_WAITLIST;
  }

  startSchedulingWaitlistBookings(bookings) {
    this.mode = MODE_WAITLIST;
    this.bookingsToSchedule = bookings;
  }

  startReschedulingBookings(
    bookings,
    {
      allowedActions = [RESCHEDULE_ACTIONS.COPY, RESCHEDULE_ACTIONS.MOVE],
      openAppointmentsSlideOverAfterCopy = true,
      callback,
    } = {}
  ) {
    this.mode = MODE_RESCHEDULING;
    this.bookingsToSchedule = bookings;
    this.originalServicesToReschedule = bookings;

    this.isGroupBookingReschedule = bookings.some(
      (booking) => !!booking.groupBookingId
    );
    this.showGroupBookingOptionsModal = this.isGroupBookingReschedule;

    this.allowedRescheduleActions = allowedActions;
    this.openAppointmentsSlideOverAfterCopy =
      openAppointmentsSlideOverAfterCopy;

    if (typeof callback === 'function') {
      this.cancelCallback = callback;
    }
  }

  endScheduling() {
    this.mode = MODE_IDLE;
    this.bookingsToSchedule = [];
    this.originalServicesToReschedule = [];
    this.allowedRescheduleActions = [];
    this.openAppointmentsSlideOverAfterCopy = true;
    this.isGroupBookingReschedule = false;
    this.showGroupBookingOptionsModal = false;
    this.showGroupBookingAppointmentsSelectionModal = false;
    this.cancelCallback = noop;
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import registerOptionElement from './register-option-element';
import { action } from '@ember/object';

export default class UiMultiSelectOptionComponent extends Component {
  registerOptionElement = registerOptionElement;

  @action
  handleKeyPress(keyPressAction, event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      keyPressAction();
    }
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task, waitForProperty } from 'ember-concurrency';

export default class RemoveRecurrentBreakModalService extends Service {
  @tracked isModalOpen = false;
  @tracked chosenOption = false;
  perform = false;

  @action
  open() {
    this.isModalOpen = true;
  }

  @action
  close() {
    this.isModalOpen = false;
  }

  @action
  remove() {
    this.perform = true;
    this.close();
  }

  @task
  *ask() {
    this.open();
    yield waitForProperty(this, 'isModalOpen', false);
    let values = {
      removeRecurrent: this.chosenOption,
      perform: this.perform,
    };
    this.reset();
    return values;
  }

  @action
  reset() {
    this.chosenOption = false;
    this.perform = false;
  }
}

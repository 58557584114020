/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import config from 'my-phorest/config/environment';
import { format as formatDate } from 'my-phorest/utils/local-date-helpers';
import { format as formatTime } from 'my-phorest/utils/local-time-helpers';
import { BREAKPOINT_MD, isAboveBreakpoint } from 'my-phorest/utils/breakpoints';
import { dropTask } from 'ember-concurrency';
import { isBlank } from '@ember/utils';
import userByPinQuery from 'my-phorest/gql/queries/user-by-pin.graphql';
import { queryManager } from 'ember-apollo-client';

export default class SidebarController extends Component {
  @service session;
  @service electronApp;
  @service revisionKey;
  @service('browser/window') window;
  @service router;
  @service branchTime;
  @service intl;
  @service keypad;
  @service notifications;
  @service clockInOut;

  @queryManager apollo;

  get branchDayOfWeek() {
    return formatDate(this.branchTime.today, this.intl, 'weekday-long');
  }

  get branchFormattedTime() {
    return formatTime(this.branchTime.time, this.intl, 'long');
  }

  get branchFormattedDate() {
    return formatDate(this.branchTime.today, this.intl, 'short');
  }

  get businessId() {
    return this.session.business.id;
  }

  get branchId() {
    return this.session.branch.id;
  }

  get isRunningInElectron() {
    return this.electronApp.isRunningInElectron;
  }

  get readFeatureFlagsFromLocal() {
    return config.launchDarkly.mode === 'local';
  }

  @action
  gotoFeatureFlags() {
    this.router.transitionTo('accounts.account.feature-flags');
  }

  @action onAfterClickMenuItem() {
    if (isAboveBreakpoint(BREAKPOINT_MD)) return;

    this.args.toggle();
  }

  @dropTask
  *getUserByPin(pin) {
    if (isBlank(pin)) return false;

    let newUser = yield this.apollo.query(
      {
        query: userByPinQuery,
        variables: {
          pin,
        },
        fetchPolicy: 'network-only',
      },
      'user'
    );

    newUser = this.#findStaffForCurrentBranch(newUser, this.branchId);
    if (!newUser) {
      this.notifications.failure(
        this.intl.t('access.error-title'),
        this.intl.t('access.not-found-error')
      );
      return false;
    }
    this.clockInOut.checkClockInOut(newUser);
  }

  @action
  openPinPadAndGetUser() {
    this.keypad.openKeypad({
      headerText: this.intl.t('app-main-nav.clock-in-out'),
      value: '',
      variant: 'pin',
      onChange: this.getUserByPin.perform,
    });
  }

  #findStaffForCurrentBranch(user, branchId) {
    return user?.staff.find((staff) => staff.branch.id === branchId);
  }
}

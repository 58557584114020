/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import appConfig from 'my-phorest/config/environment';

export default class DevInfoComponent extends Component {
  @service revisionKey;
  @service('browser/window') window;

  deployTarget = appConfig.deployTarget;
}

import { getObservable } from 'ember-apollo-client';

export async function fetchAllPaginatedResults(
  apollo,
  graphParameters,
  graphQuery
) {
  let results = [],
    queryResult = await apollo.watchQuery(graphParameters, graphQuery);

  queryResult.edges.forEach((edge) => results.push(edge.node));

  let observable = getObservable(queryResult);

  while (queryResult.pageInfo.hasNextPage) {
    queryResult = await observable.fetchMore({
      variables: {
        after: queryResult.pageInfo.endCursor,
      },
    });

    queryResult = queryResult.data[graphQuery];

    queryResult.edges.forEach((edge) => results.push(edge.node));
  }

  return results;
}

/**
 * This policy merges incoming calendar days with existing calendar days.
 * Calendar days are part of a calendar resource like StaffCalendar (Staff), Room or Machine.
 * They do not have an `id` but in the context of the aforementioned calendar resource
 * they can be easily identified by `date` field.
 */
export function mergeCalendarDaysPolicy(existing = [], incoming) {
  const merged = [...existing];

  if (!incoming) {
    return merged;
  }

  incoming.forEach((incomingDay) => {
    const hasDayInCache = existing.some(
      (existingDay) => existingDay.date === incomingDay.date
    );

    if (!hasDayInCache) {
      merged.push(incomingDay);
    }
  });

  return merged;
}

/**
 * Feed a.k.a infinite scroll policy.
 *
 * It tells Apollo how to merge incoming resources with already existing in cache.
 * It assumes you work with a Connection (notice edges here).
 * As a result, instead of replacing existing cache with new entries, it appends new records to existing ones.
 *
 * The method deals with duplicates.
 *
 * Learn more:
 *  - https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
 *  - https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
 *  - https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
 */
export function mergeFeedConnectionPolicy(existing = {}, incoming = {}) {
  const mergedEdges = existing.edges ? existing.edges.slice(0) : [];

  incoming.edges?.forEach((incomingEdge) => {
    const ref = incomingEdge.node.__ref;

    if (!mergedEdges.some((mergedEdge) => mergedEdge.node.__ref === ref)) {
      mergedEdges.push(incomingEdge);
    }
  });

  return {
    ...incoming,
    edges: mergedEdges,
  };
}

export function evictBookingHistory(cache) {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'serviceHistory' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'clientNextAppointment' });
}

/**
 * Clear all appointments queries from the cache.
 *
 * @param {object} cache - The Apollo cache object
 * @param {string?} groupBookingId - If provided, clear only queries that filter by the given group booking ID
 * @returns {void}
 */
export function evictGroupBookings(cache, groupBookingId) {
  const options = { id: 'ROOT_QUERY', fieldName: 'appointments' };

  if (groupBookingId) {
    options.args = {
      filterBy: {
        groupBookingId,
      },
    };
  }

  cache.evict(options);
}

/**
 * Clear appointments from GraphQL cache.
 *
 * @param {object} cache - The Apollo cache object
 * @param {array} ids - List of appointment IDs
 * @returns {void}
 */
export function evictAppointments(cache, ids) {
  for (let id of ids) {
    const normalizedId = cache.identify({
      id,
      __typename: 'Appointment',
    });
    cache.evict({ id: normalizedId });
  }
}

/**
 * Clear a client course item from the GraphQL cache.
 *
 * @param {object} cache - The Apollo cache object
 * @param {string} id - ID of the client course item
 * @returns {void}
 */
export function evictClientCourseItem(cache, id) {
  const normalizedId = cache.identify({
    id,
    __typename: 'ClientCourseItem',
  });
  cache.evict({ id: normalizedId });
}

/**
 * Clear client courses from the GraphQL cache.
 *
 * @param {object} cache - The Apollo cache object
 * @param {string} clientId - ID of the client
 * @returns {void}
 */
export function evictClientCourses(cache, clientId) {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'clientCourses',
    args: { filterBy: { clientId } },
  });
}

/**
 * Clear waitlist queries from GraphQL cache.
 *
 * @param {object} cache - The Apollo cache object
 * @returns {void}
 */
export function evictWaitlistQueries(cache) {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'waitlistBookings' });
}

/**
 * Clear client query from GraphQL cache.
 *
 * @param {object} cache - The Apollo cache object
 * @param {string} clientId - ID of the client
 * @returns {void}
 */
export function evictClientById(cache, clientId) {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'client',
    args: { id: clientId },
  });
}

/**
 * Clear serviceRewards query from GraphQL cache.
 *
 * @param {object} cache - The Apollo cache object
 * @param {string} clientId - ID of the client
 * @param {array} branchList - List of branches
 * @returns {void}
 */
export function evictServiceRewardsForClient(cache, clientId, branchList) {
  branchList.forEach((branch) => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'serviceRewards',
      args: { filterBy: { clientId }, branchId: branch.id },
    });
  });
}

export function evictProductRewardsForClient(cache, clientId, branchList) {
  branchList.forEach((branch) => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'productRewards',
      args: {
        filterBy: { clientId },
        branchId: branch.id,
      },
    });
  });
}

export async function fetchMore(paginableFetchTaskInstance) {
  let task = paginableFetchTaskInstance;

  if (task && task.pageInfo.hasNextPage) {
    let observable = getObservable(task);
    await observable.fetchMore({
      variables: {
        after: task.pageInfo.endCursor,
      },
    });
  }
}

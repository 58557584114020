/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { DISCOUNT_TYPES } from 'my-phorest/utils/discounts';

export default class KeypadTypeDiscountComponent extends Component {
  @service intl;
  @service currencyFormatter;
  @service percentageFormatter;

  DISCOUNT_TYPES = DISCOUNT_TYPES;

  get fixedDiscountName() {
    let selectedDiscount = this.args.fixedDiscounts?.find(
      (d) => d.id === this.args.selectedFixedDiscountId
    );

    if (selectedDiscount) {
      return selectedDiscount.name;
    }

    return '';
  }

  @action
  formatDiscount(discount) {
    if (discount.type === DISCOUNT_TYPES.PERCENT) {
      return this.percentageFormatter.format(discount.value);
    } else {
      return this.currencyFormatter.format(discount.value);
    }
  }
}

import currencyCode from 'my-phorest/utils/currency-code';

export default function supportedMetaCurrency(country) {
  const SUPPORTED_CURRENCIES = [
    'DZD',
    'ARS',
    'AUD',
    'BDT',
    'BOB',
    'BRL',
    'GBP',
    'CAD',
    'CLP',
    'CNY',
    'COP',
    'CRC',
    'CZK',
    'DKK',
    'EGP',
    'EUR',
    'GTQ',
    'HNL',
    'HKD',
    'HUF',
    'ISK',
    'INR',
    'IDR',
    'ILS',
    'JPY',
    'KES',
    'KRW',
    'MOP',
    'MYR',
    'MXN',
    'NZD',
    'NIO',
    'NGN',
    'NOK',
    'PKR',
    'PYG',
    'PEN',
    'PHP',
    'PLN',
    'QAR',
    'RON',
    'RUB',
    'SAR',
    'SGD',
    'ZAR',
    'LKR',
    'SEK',
    'CHF',
    'TWD',
    'THB',
    'TRY',
    'AED',
    'USD',
    'UYU',
    'VEF',
    'VND',
  ];

  let currency = currencyCode(country);

  return SUPPORTED_CURRENCIES.includes(currency) ? currency : 'USD';
}

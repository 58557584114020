const DELIMITER = ', ';

function rectifyZeroHourTime(timeString) {
  if (!timeString) {
    return;
  }

  // XXX handle datetime strings, e.g. 'Wednesday, October 5, 2011 at 00:00:00 AM'
  // Note: applies to English language only as only English language locales use 12-hour time

  const matchesLongFormDatetime = timeString.match(/(^.*\sat\s)(.*)$/);

  if (matchesLongFormDatetime) {
    return (
      matchesLongFormDatetime[1] + findAndReplace00(matchesLongFormDatetime[2])
    );
  }

  return timeString
    .split(DELIMITER)
    .map((slice) => findAndReplace00(slice))
    .map((slice) => findAndReplace24(slice))
    .join(DELIMITER);
}

function findAndReplace00(input) {
  let zeroHourRegEx = /^00?((:\d\d)?(:\d{2}\s|\s)[ap]\.?m\.?)$/i;
  return input?.replace(zeroHourRegEx, '12$1');
}

function findAndReplace24(input) {
  let zeroHourRegEx = /^24(:\d\d)(:\d\d)?$/i;
  return input?.replace(zeroHourRegEx, '00$1$2');
}

export { rectifyZeroHourTime };

import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import updatePatchTestMutation from 'my-phorest/gql/mutations/update-patch-test.graphql';
import createPatchTestMutation from 'my-phorest/gql/mutations/create-patch-test.graphql';

export default class PatchTestSlideOverService extends Service {
  @queryManager apollo;
  @service dataFetchManager;

  @tracked patchTest;

  @tracked isOpen = false;

  openWithPatchTest(clientId, patchTest) {
    this.clientId = clientId;
    this.patchTest = { ...patchTest };
    this.isOpen = true;
  }

  openWithNew(clientId) {
    this.clientId = clientId;
    this.patchTest = {
      result: 'PENDING',
    };
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  get patchTestInput() {
    return {
      clientId: this.clientId,
      description: this.patchTest.description,
      staffMemberId: this.patchTest.staffMember.id,
      result: this.patchTest.result,
    };
  }

  @dropTask
  *savePatchTestTask() {
    const patchTestInput = this.patchTestInput;

    const isNewPatchTest = !this.patchTest.id;

    yield this.apollo.mutate(
      {
        mutation: isNewPatchTest
          ? createPatchTestMutation
          : updatePatchTestMutation,
        variables: {
          patchTestId: this.patchTest.id,
          input: patchTestInput,
        },
      },
      isNewPatchTest ? 'createPatchTest' : 'updatePatchTest'
    );

    this.dataFetchManager.refetchDataFor('patchTests');
    this.isOpen = false;
  }

  @dropTask
  *togglePatchTestArchivedTask(patchTest) {
    const patchTestInput = {
      archived: !patchTest.archived,
    };
    yield this.apollo.mutate(
      {
        mutation: updatePatchTestMutation,
        variables: {
          patchTestId: patchTest.id,
          input: patchTestInput,
        },
      },
      'togglePatchTestArchived'
    );

    this.dataFetchManager.refetchDataFor('patchTests');
    this.isOpen = false;
  }
}

import { subtractMoney } from 'my-phorest/utils/currency';
import { getOwner } from '@ember/application';
import { assert } from '@ember/debug';
import { get } from '@ember/object';

export function calculateBalanceChange({ from } = {}) {
  assert('@calculateBalanceChange - you have to specify "from" property', from);
  return function (target) {
    Object.defineProperties(target.prototype, {
      amountToPay: {
        get() {
          return get(this, from)?.totalToPay.amount;
        },
      },

      remainingToPay: {
        get() {
          let service = getOwner(this).lookup('service:payment-slide-over');
          return subtractMoney(this.amountToPay, service.balance.total || 0);
        },
      },

      absRemainingToPay: {
        get() {
          return Math.abs(this.remainingToPay);
        },
      },

      isMoneyReturned: {
        get() {
          return this.remainingToPay < 0;
        },
      },

      isAnythingToPay: {
        get() {
          if (this.amountToPay > 0) {
            return this.remainingToPay > 0;
          } else {
            return parseInt(this.remainingToPay) !== 0;
          }
        },
      },

      changeDue: {
        get() {
          return this.isMoneyReturned ? this.absRemainingToPay : 0;
        },
      },
    });
  };
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import { queryManager } from 'ember-apollo-client';

import phorestP from 'my-phorest/assets/images/phorest-p.svg';
import branchesQuery from 'my-phorest/gql/queries/branches.graphql';

export default class BranchSelectorComponent extends Component {
  @service('browser/window') window;
  @service electronApp;
  @service embeddedRedirections;
  @service errorHandler;
  @service router;
  @service session;
  @service swingBridge;

  @queryManager apollo;

  phorestPSrc = phorestP;
  targetRoute = 'accounts.account.appointments';

  allBranches = trackedTask(this, this.loadBranchesTask);

  get activeBranches() {
    return this.args.branches
      .filter((branch) => typeof branch.accountId === 'number')
      .sort((a, b) => a.shortName.localeCompare(b.shortName));
  }

  get appointmentsQueryParams() {
    if (this.isOnAppointmentsRouteWithDateParam) {
      return { start: this.router.currentRoute.queryParams.start };
    }
    return {};
  }

  get hasOtherBranches() {
    return this.otherBranches.length > 0;
  }

  get isOnAppointmentsRouteWithDateParam() {
    return (
      this.router.currentRouteName.includes('accounts.account.appointments') &&
      this.router.currentRoute.queryParams?.start
    );
  }

  get manageBranchesLink() {
    const chainStaffPath = this.embeddedRedirections.chainStaffPath;
    const currentUser = this.session.currentUser;
    return `${chainStaffPath}/${currentUser.id}/branches`;
  }

  get otherBranches() {
    const activeBranchesIds = this.activeBranches.map((b) => b.id);
    const allBranches = this.allBranches?.value || [];
    return allBranches
      .filter((b) => !activeBranchesIds.includes(b.id))
      .sort((a, b) => a.shortName.localeCompare(b.shortName));
  }

  get shouldShowOpenAllBranchesButton() {
    return (
      this.activeBranches.length > 1 &&
      this.activeBranches.length <= 20 &&
      !this.swingBridge.isEmbeddedInSwing &&
      !this.electronApp.isRunningInElectron
    );
  }

  @action
  openAllBranches() {
    let branchesUrls = this.activeBranches.map((branch) => {
      if (this.isOnAppointmentsRouteWithDateParam) {
        return this.router.urlFor(this.targetRoute, branch.accountId, {
          queryParams: this.appointmentsQueryParams,
        });
      } else {
        return this.router.urlFor(this.targetRoute, branch.accountId);
      }
    });
    branchesUrls.forEach((url) => {
      if (url !== this.router.currentURL) {
        window.open(url);
      }
    });
  }

  @task
  *loadBranchesTask() {
    try {
      const result = yield this.apollo.query(
        {
          query: branchesQuery,
          variables: {
            first: 500,
          },
        },
        'branches'
      );
      const branches = result.edges.map((edge) => edge.node);
      this.session.branchesList = branches;

      return branches;
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }
}

import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { isAfter } from 'my-phorest/utils/local-datetime-helpers';
export default class TimersService extends Service {
  /*
    The idea is for consumers (callers) to register timers with this service:
      // 1. Turn appointments visually late
      makeAppointmentLate() {
        this.appointment.isLate = true;
      } 

      this.timers.registerTimer({
        ifPastTime: this.appointment.startTime,
        action: this.makeAppointmentLate,
      });

      // 2. Reload calendar at midnight
      reloadCalendar() {
        this.calendar.reload();
      } 

      this.timers.registerTimer({
        ifPastTime: this.midnight,
        action: this.reloadCalendar,
      });

      // 3. Update "now indicator" line in calendar
      this.timers.registerTimer({
        action: this.updateNowIndicatorLine,
        every: 60_000,
      });


      If the caller (the object that registers the timer) gets destroyed,
      it should call `unregisterTimer` (from its `registerDestructor`)

      Further points:
      - Maybe this service can replace the `interval` service by keeping a `now` timer by default?
        => Yes, it can: we'd need to move timeZone to the session service and default to the value
          that the `interval` service's timeZone defaults to
  */

  timers = [];
  nextTimerId = 1;
  tickInterval = 1_000;
  millisecondsPassed = 0;

  time;
  intervalId;

  @service('browser/window') window;
  @service session;
  @service branchTime;

  registerTimer({ action, ifPastTime, every, destroyable }) {
    if (ifPastTime && every) {
      assert('You can either pass `every` or `ifPastTime` but not both', false);
    }

    if (every) {
      assert(
        `You have to pass an "every" value which is a multiple of ${this.tickInterval}`,
        every > 0 && every % this.tickInterval === 0
      );
    }

    if (!this.intervalId) {
      this.intervalId = this.window.setInterval(() => {
        this.tick();
      }, this.tickInterval);
    }

    let timer = { id: this.nextTimerId, action, every, ifPastTime };
    this.timers.push(timer);
    this.nextTimerId++;

    if (destroyable) {
      registerDestructor(destroyable, () => {
        if (this.hasTimer(timer.id)) {
          this.unregisterTimer(timer.id);
        }
      });
    }

    return timer.id;
  }

  unregisterTimer(timerId) {
    let index = this.timers.findIndex(({ id }) => id === timerId);
    assert(
      `Cannot unregister timer (${timerId}) as it wasn't found.`,
      index !== -1
    );
    this.timers.splice(index, 1);

    if (this.timers.length === 0) {
      this.window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  tick() {
    this.millisecondsPassed += this.tickInterval;

    for (let timer of this.timers) {
      let { action, every, ifPastTime } = timer;

      if (every && this.millisecondsPassed % every === 0) {
        action();
      }

      if (ifPastTime && isAfter(this.branchTime.dateAndTime, ifPastTime)) {
        action();
      }
    }
  }

  hasTimer(timerId) {
    return this.timers.find(({ id }) => id === timerId);
  }

  setTickInterval(milliseconds) {
    assert(
      'You cannot set the interval when timers are running',
      !this.isTicking
    );
    this.tickInterval = milliseconds;
  }

  get isTicking() {
    return !!this.intervalId;
  }
}

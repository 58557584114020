import Service from '@ember/service';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';

export default class PurchaseBasketSlideOverService extends Service {
  slideOvers = [];

  @action
  add(slideOver) {
    this.slideOvers.push(slideOver);
    registerDestructor(slideOver, () => this.remove(slideOver));
  }

  @action
  remove(slideOver) {
    let ix = this.slideOvers.findIndex((s) => s === slideOver);
    this.slideOvers.splice(ix, 1);
  }

  @action
  closeAll() {
    this.slideOvers.forEach((s) => (s.isOpen = false));
  }

  get last() {
    return this.slideOvers[this.slideOvers.length - 1];
  }
}

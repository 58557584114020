import Service, { service } from '@ember/service';

export default class PerformanceService extends Service {
  @service('browser/window') window;

  enabled = false;

  get isAvailable() {
    return this.enabled && typeof this.window.performance !== 'undefined';
  }

  markStart(name) {
    if (!this.isAvailable) return;
    this.window.performance.mark(`${name}-start`);
  }

  measureEnd(name) {
    if (!this.isAvailable) return;
    this.window.performance.mark(`${name}-end`);
    this.window.performance.measure(name, `${name}-start`, `${name}-end`);
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  convertToLocalDate,
  durationBetween,
} from 'my-phorest/utils/local-datetime-helpers';
import { todayInTimeZone } from 'my-phorest/utils/local-date-helpers';

export default class ClockInOutService extends Service {
  @tracked isOpen = false;
  @tracked clock;
  @tracked staffName;
  @tracked staffId;

  get clockInTime() {
    return this.clock?.localDateTimeIn;
  }

  get clockOutTime() {
    return this.clock?.localDateTimeOut;
  }

  get isClockedInToday() {
    return (
      durationBetween(
        todayInTimeZone(),
        convertToLocalDate(this.clockInTime)
      ).days() === 0
    );
  }

  get isClockedIn() {
    return !!this.clockInTime && this.isClockedInToday && !this.clockOutTime;
  }

  @action
  checkClockInOut(staff, { showOnlyWhenClockedOut = false } = {}) {
    this.staffName = staff.name;
    this.clock = staff.lastClock;
    this.staffId = staff.id;

    if (!this.clock) return;

    if (!showOnlyWhenClockedOut || !this.isClockedIn) {
      this.open();
    }
  }

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
  }
}

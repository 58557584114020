export function constructReorderRequest({
  reorderedRows,
  pageNumber,
  pageSize,
  moveLastItemToNextPage,
  moveFirstItemToPrevPage,
}) {
  let orderedIds = reorderedRows.map((row) => row.id);
  let moveToNextPageId = null;
  let moveToPrevPageId = null;
  if (moveFirstItemToPrevPage) {
    moveToPrevPageId = orderedIds[0];
    orderedIds = [...orderedIds.slice(1)];
  } else if (moveLastItemToNextPage) {
    moveToNextPageId = orderedIds[orderedIds.length - 1];
    orderedIds = [...orderedIds.slice(0, -1)];
  }

  return {
    pageNumber,
    pageSize,
    orderedIds,
    moveToNextPageId,
    moveToPrevPageId,
  };
}

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import {
  PriceVariant,
  DiscountVariant,
  IntegerVariant,
  NumberVariant,
  PinVariant,
  TimeVariant,
  VoucherVariant,
  PercentVariant,
} from 'my-phorest/components/page/keypad';
import { task, waitForProperty } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';

export default class KeypadService extends Service {
  @queryManager apollo;

  @service currencyFormatter;
  @service intl;
  @service session;
  @service branchTime;

  @tracked variantInstance;
  @tracked headerText;
  @tracked variant;
  @tracked isOpen;
  @tracked onChange;
  @tracked onCancel;
  @tracked cancelDisabled = false;
  @tracked hideBackground = false;
  @tracked value;
  @tracked autoClose = true;
  @tracked allowNegative = true;
  @tracked allowZero = true;
  @tracked allowNull = false;
  @tracked required = false;
  @tracked allowTimeOutsideSocialHours = true;
  @tracked hideFixedDiscounts = false;
  @tracked voucherWorkflow;
  trailingAddonText;
  generateVoucherSerialTask;
  getVoucherBySerialTask;

  reset() {
    this.headerText = null;
    this.trailingAddonText = null;
    this.variant = null;
    this.onChange = null;
    this.onCancel = null;
    this.cancelDisabled = false;
    this.hideBackground = false;
    this.value = null;
    this.isOpen = false;
    this.autoClose = true;
    this.allowNegative = true;
    this.allowZero = true;
    this.allowNull = false;
    this.generateVoucherSerialTask = null;
    this.getVoucherBySerialTask = null;
    this.required = null;
    this.allowTimeOutsideSocialHours = null;
    this.validationSchema = null;
    this.voucherWorkflow = null;
    this.hideFixedDiscounts = false;
  }

  openKeypad(options) {
    this.reset();
    this.headerText = options.headerText;
    this.trailingAddonText = options.trailingAddonText;
    this.onChange = options.onChange;
    this.value = options.value;
    this.variant = options.variant;
    this.autoClose = options.autoClose ?? true;
    this.allowNegative = options.allowNegative ?? true;
    this.allowZero = options.allowZero ?? true;
    this.allowNull = options.allowNull ?? false;
    this.onCancel = options.onCancel;
    this.cancelDisabled = options.cancelDisabled ?? false;
    this.hideBackground = options.hideBackground ?? false;
    this.generateVoucherSerialTask = options.generateVoucherSerialTask;
    this.getVoucherBySerialTask = options.getVoucherBySerialTask;
    this.voucherWorkflow = options.voucherWorkflow;
    this.isOpen = true;
    this.required = options.required ?? false;
    this.allowTimeOutsideSocialHours =
      options.allowTimeOutsideSocialHours ?? true;
    this.validationSchema = options.validationSchema ?? false;
    this.hideFixedDiscounts = options.hideFixedDiscounts ?? false;

    const { variant, value } = this;

    const variants = {
      price: PriceVariant,
      discount: DiscountVariant,
      percent: PercentVariant,
      integer: IntegerVariant,
      number: NumberVariant,
      pin: PinVariant,
      time: TimeVariant,
      voucher: VoucherVariant,
    };
    assert(
      `Allowed keypad variants: ${Object.keys(variants).join(
        ', '
      )}. Got: ${variant}`,
      variants[variant]
    );
    this.variantInstance = new variants[variant](value, {
      currencyFormatter: this.currencyFormatter,
      keypad: this,
      intl: this.intl,
      session: this.session,
      apollo: this.apollo,
    });
    return this.waitForClose.perform();
  }

  isValidVoucher(voucher = null) {
    if (this.voucherWorkflow === 'create') {
      return voucher ? false : true;
    } else if (this.voucherWorkflow === 'use') {
      return voucher ? true : false;
    }
  }

  closeKeypad() {
    this.isOpen = false;
  }

  @task
  *waitForClose() {
    yield waitForProperty(this, 'isOpen', false);
  }
}

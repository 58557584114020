/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import { dropTask } from 'ember-concurrency';
import config from 'my-phorest/config/environment';

import reviewableTicketsQuery from 'my-phorest/gql/queries/reviewable-tickets.graphql';

export default class SupportDialogComponent extends Component {
  @service session;
  @service router;
  @service apollo;
  @service intl;
  @service notifications;

  reviewableTickets = trackedTask(this, this.fetchReviewableTicketsTask, () => [
    this.session.currentAccountId,
    this.session.currentUser.email,
  ]);

  get businessId() {
    return this.session.business.id;
  }

  get branchId() {
    return this.session.branch.id;
  }

  get hasReviewableTickets() {
    return this.reviewableTickets.value?.totalResults;
  }

  get embeddedPath() {
    return `businesses/${this.businessId}/branches/${this.branchId}/support-review`;
  }

  get currentUserName() {
    return this.session.user?.name || this.session.branch?.fullName;
  }

  get currentUserEmail() {
    return this.session.user?.email || this.session.branch?.email;
  }

  get branchAccountId() {
    return this.session.branch.accountId;
  }

  @dropTask
  loadZendeskWidgetTask() {
    if (config.deployTarget && config.deployTarget !== 'production') {
      return; // show only in production
    }

    window.zESettings = {
      webWidget: {
        color: { theme: '#115E59' },
        contactForm: {
          suppress: false,
          attachments: false,
          ticketForms: [{ id: config.zendesk.ticketFormId }],
          fields: [
            { id: 'name', prefill: { '*': this.currentUserName } },
            {
              id: 'email',
              prefill: {
                '*': this.currentUserEmail,
              },
            },
            { id: 360008170354, prefill: { '*': this.branchAccountId } },
          ],
        },
        helpCenter: { suppress: false },
      },
    };

    let script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.zendesk.key}`;

    let timeoutId;

    const handleError = () => {
      clearTimeout(timeoutId);

      this.notifications.failure(
        this.intl.t('app-main-nav.support-dialog.contact-us-error.title'),
        this.intl.t('app-main-nav.support-dialog.contact-us-error.description'),
        {
          sticky: true,
        }
      );
    };

    timeoutId = setTimeout(() => {
      if (typeof window.zE !== 'function') {
        handleError();
      }
    }, 5000);

    script.onload = () => {
      const intervalId = setInterval(() => {
        if (typeof window.zE === 'function') {
          clearInterval(intervalId);
          window.zE(function () {
            window.zE.activate({ hideOnClose: true });
          });
        }
      }, 100);
    };

    script.onerror = handleError;

    document.body.appendChild(script);
  }

  @dropTask
  *fetchReviewableTicketsTask() {
    return yield this.apollo.watchQuery(
      {
        query: reviewableTicketsQuery,
        fetchPolicy: 'cache-and-network',
        variables: {
          accountId: this.session.currentAccountId,
          userEmail: this.session.currentUser.email,
        },
      },
      'reviewableTickets'
    );
  }
}

import Modifier from 'ember-modifier';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';

export default class DraggableModifier extends Modifier {
  @tracked active = false;
  @tracked currentX;
  @tracked currentY;
  @tracked initialX;
  @tracked initialY;
  @tracked xOffset = 0;
  @tracked yOffset = 0;

  dragItem;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.unregisterListeners);
  }

  modify(element, positional, { handler }) {
    this.dragItem = element;
    this.dragHandler = handler;

    this.dragContainer.addEventListener('touchstart', this.dragStart);
    this.dragContainer.addEventListener('touchend', this.dragEnd);
    this.dragContainer.addEventListener('touchmove', this.drag);

    this.dragContainer.addEventListener('mousedown', this.dragStart);
    this.dragContainer.addEventListener('mouseup', this.dragEnd);
    this.dragContainer.addEventListener('mousemove', this.drag);
  }

  get dragItemBoundaries() {
    return this.dragItem.getBoundingClientRect();
  }

  get dragContainer() {
    return this.dragItem.closest('[data-draggable-container]');
  }

  get containerBoundaries() {
    return this.dragContainer.getBoundingClientRect();
  }

  get rightLimit() {
    return this.containerBoundaries.right - this.dragItemBoundaries.right;
  }

  get leftLimit() {
    return this.containerBoundaries.left - this.dragItemBoundaries.left;
  }

  get topLimit() {
    return this.containerBoundaries.top - this.dragItemBoundaries.top;
  }

  get bottomLimit() {
    return this.containerBoundaries.bottom - this.dragItemBoundaries.bottom;
  }

  @action
  dragStart(e) {
    if (!this.dragHandler || e.target === this.dragHandler) {
      this._calcStartPosition(e);

      this.active = true;
    }
  }

  @action
  dragEnd() {
    this.initialX = this.currentX;
    this.initialY = this.currentY;

    this.active = false;
  }

  @action
  drag(e) {
    if (this.active) {
      e.preventDefault();

      if (e.type === 'touchmove') {
        this.currentX = e.touches[0].clientX - this.initialX;
        this.currentY = e.touches[0].clientY - this.initialY;
      } else {
        this.currentX = e.clientX - this.initialX;
        this.currentY = e.clientY - this.initialY;
      }

      if (this.currentX >= this.rightLimit) {
        this.currentX = this.rightLimit;
      } else if (this.currentX <= this.leftLimit) {
        this.currentX = this.leftLimit;
      }

      if (this.currentY >= this.bottomLimit) {
        this.currentY = this.bottomLimit;
      } else if (this.currentY <= this.topLimit) {
        this.currentY = this.topLimit;
      }

      this.xOffset = this.currentX;
      this.yOffset = this.currentY;

      this._setTranslate(this.currentX, this.currentY, this.dragItem);

      if (
        e.clientX > this.containerBoundaries.right ||
        e.clientX < this.containerBoundaries.left ||
        e.clientY < this.containerBoundaries.top ||
        e.clientY > this.containerBoundaries.bottom
      ) {
        this.active = false;
      }
    }
  }

  @action
  unregisterListeners() {
    this.dragContainer.removeEventListener('touchstart', this.dragStart);
    this.dragContainer.removeEventListener('touchend', this.dragEnd);
    this.dragContainer.removeEventListener('touchmove', this.drag);

    this.dragContainer.removeEventListener('mousedown', this.dragStart);
    this.dragContainer.removeEventListener('mouseup', this.dragEnd);
    this.dragContainer.removeEventListener('mousemove', this.drag);
  }

  _calcStartPosition(e) {
    if (e.type === 'touchstart') {
      this.initialX = e.touches[0].clientX - this.xOffset;
      this.initialY = e.touches[0].clientY - this.yOffset;
    } else {
      this.initialX = e.clientX - this.xOffset;
      this.initialY = e.clientY - this.yOffset;
    }
  }

  _setTranslate(xPos, yPos, el) {
    el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)';
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask, timeout } from 'ember-concurrency';
import timeoutForEnv from 'my-phorest/utils/timeout-for-env';

export default class InternetStatusComponent extends Component {
  @service internet;
  @service('browser/window') window;

  @tracked showMessage = false;

  constructor() {
    super(...arguments);
    this.internet;
    this.window.addEventListener('disconnected', this.handleDisconnection);
    this.window.addEventListener(
      'reconnected',
      this.handleReconnection.perform
    );
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.window.removeEventListener('disconnected', this.handleDisconnection);
    this.window.removeEventListener(
      'reconnected',
      this.handleReconnection.perform
    );
  }

  @action
  handleDisconnection() {
    this.showMessage = true;
  }

  @dropTask
  *handleReconnection() {
    yield timeout(timeoutForEnv(2000));
    this.showMessage = false;
  }
}

import Service, { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import * as calendarCache from 'my-phorest/utils/calendar-cache';

import appointmentsForClientQuery from 'my-phorest/gql/queries/appointments-for-client.graphql';
import createAppointment from 'my-phorest/gql/mutations/create-appointment.graphql';
import createServiceGroupAppointment from 'my-phorest/gql/mutations/create-service-group-appointment.graphql';

export default class AppointmentTasksService extends Service {
  @service apollo;

  @dropTask
  *createAppointmentTask(variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: createAppointment,
      variables,
      update: (cache, { data: { createAppointment } }) => {
        const { appointment } = createAppointment;
        if (appointment) {
          calendarCache.addEventsToCalendarCache(cache, appointment);
        }
      },
    });
    return response.createAppointment;
  }

  @dropTask
  *createPackageAppointmentTask(variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: createServiceGroupAppointment,
      variables,
      update: (cache, { data: { createServiceGroupAppointment } }) => {
        const { appointments } = createServiceGroupAppointment;
        if (appointments) {
          calendarCache.addEventsToCalendarCache(cache, appointments);
        }
      },
    });
    return response.createServiceGroupAppointment;
  }

  @dropTask
  *fetchVisitsTask({ clientId, date }, options = {}) {
    if (!clientId) {
      throw new Error('`clientId` is required to fetch visits');
    }

    if (!date) {
      throw new Error('`date` is required to fetch visits');
    }

    const variables = {
      clientId,
      date,
    };

    const { appointments } = yield this.apollo.query({
      query: appointmentsForClientQuery,
      variables,
      ...options,
    });

    return appointments.edges.map((edge) => edge.node);
  }
}

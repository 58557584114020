/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { trackedRef } from 'ember-ref-bucket';

const MIN_WIDTH_SHOW_ERROR_EXCLAMATION_CIRCLE = 75;

export default class UiInputGroupComponent extends Component {
  @trackedRef('$label') $label;

  get showErrorExclamationCircle() {
    return this.$label?.offsetWidth > MIN_WIDTH_SHOW_ERROR_EXCLAMATION_CIRCLE;
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DataFetchManager extends Service {
  @tracked counters = {};

  refetchDataFor(type) {
    let currentCount = this.counters[type];
    let newCount = currentCount ? currentCount + 1 : 1;
    this.counters = { ...this.counters, [type]: newCount };
  }
}

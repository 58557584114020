import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, waitForProperty } from 'ember-concurrency';
import { action } from '@ember/object';

export default class MarketingConsentModalService extends Service {
  @service session;

  @tracked isOpenModalEuropeanSalon = false;
  @tracked isOpenModalUsOrCanadaSalon = false;
  @tracked chosenValue = null;

  @task
  *askIfAllowed(client) {
    if (this.#shouldOpenModal(client)) {
      if (this.isUsOrCanadaSalon) {
        this.isOpenModalUsOrCanadaSalon = true;
        yield waitForProperty(this, 'isOpenModalUsOrCanadaSalon', false);
      } else {
        this.isOpenModalEuropeanSalon = true;
        yield waitForProperty(this, 'isOpenModalEuropeanSalon', false);
      }
      return this.chosenValue;
    }
  }

  @action
  close() {
    this.isOpenModalUsOrCanadaSalon = false;
    this.isOpenModalEuropeanSalon = false;
  }

  get isAustralianSalon() {
    return this.session.locale.countryCode === 'AU';
  }

  get isUsOrCanadaSalon() {
    let countryCode = this.session.locale.countryCode;
    return countryCode === 'US' || countryCode === 'CA';
  }

  @action
  closeWithValue(value) {
    this.chosenValue = value;
    this.close();
  }

  #shouldOpenModal({ marketingEmailOptout, marketingSmsOptout }) {
    if (marketingEmailOptout && marketingSmsOptout) {
      if (this.isAustralianSalon) {
        return false;
      } else if (this.isUsOrCanadaSalon) {
        return true;
      } else {
        return true;
      }
    }
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class PageNotificationsComponent extends Component {
  @service notifications;

  @action
  removeNotification(notification) {
    this.notifications.removeNotification.perform(notification);
  }
}

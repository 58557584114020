export function appVersionString(appConfig, swingBridge, electronApp) {
  let appVersionSegments = ['browser'];

  let embeddedIn;
  if (swingBridge.isEmbeddedInSwing) {
    embeddedIn = 'swing';
  } else if (electronApp.isRunningInElectron) {
    embeddedIn = 'electron';
  } else {
    embeddedIn = 'web';
  }

  appVersionSegments.push(embeddedIn);

  const { deployRevision, UNSPECIFIED_REVISION_KEY } = appConfig;
  if (deployRevision === UNSPECIFIED_REVISION_KEY) {
    appVersionSegments.push('local');
  } else {
    appVersionSegments.push(deployRevision);
  }

  return appVersionSegments.join('-');
}

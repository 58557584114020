import { setContext } from '@apollo/client/link/context/index';
import { InMemoryCache } from '@apollo/client/cache/inmemory/inMemoryCache';
import { ApolloClient } from '@apollo/client/core';
import ApolloService from 'ember-apollo-client/services/apollo';

import { service } from '@ember/service';

import { appVersionString } from 'my-phorest/utils/app-version';
import {
  mergeCalendarDaysPolicy,
  mergeFeedConnectionPolicy,
} from 'my-phorest/utils/graphql';
import config from 'my-phorest/config/environment';

import { ApolloLink, Observable, split } from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { variation } from 'ember-launch-darkly';

class QueryCache {
  constructor(cache) {
    this.cache = cache;
  }

  async insertInto(target, item) {
    const serialize = ({ __typename, id }) => {
      return JSON.stringify({ __typename, id });
    };
    let draft = await this._getDraft();
    let items = target(draft);
    let match = items.map(serialize).includes(serialize(item));
    if (!match) {
      items.push(item);
    }
    return this._saveDraft(draft);
  }

  async move({ from, to }, item) {
    let draft = await this._getDraft();
    let _from = from(draft);
    let toRemove = _from.findIndex((i) => i.id === item.id);
    _from.splice(toRemove, 1);

    to(draft).push(item);
    return this._saveDraft(draft);
  }

  async _getDraft() {
    let data = await this.cache.getData();
    return JSON.parse(JSON.stringify(data));
  }

  async _saveDraft(draft) {
    return this.cache.saveData(draft);
  }
}

export const queryCache = function (cache) {
  return new QueryCache(cache);
};

export default class OverriddenApolloService extends ApolloService {
  @service electronApp;
  @service errorHandler;
  @service intl;
  @service notifications;
  @service pendo;
  @service session;
  @service swingBridge;
  @service versionTracker;

  constructor() {
    super(...arguments);

    const { client, pendo } = this;
    const originalQuery = client.query.bind(client);
    const originalWatchQuery = client.watchQuery.bind(client);

    client.query = async function () {
      if (variation('release-track-cache-utilization')) {
        pendo.trackEvent('Cache utilization - querying');
      }
      return originalQuery(...arguments);
    };
    client.watchQuery = function () {
      if (variation('release-track-cache-utilization')) {
        pendo.trackEvent('Cache utilization - querying');
      }
      return originalWatchQuery(...arguments);
    };
  }

  reinitClient() {
    this.client = new ApolloClient(this.clientOptions());
  }

  get options() {
    let { path, ...rest } = super.options;

    let host = this.session.isAuthenticated
      ? this.apiGatewayHost
      : 'https://dev.null'; // At present, only authenticated users can use GraphQL API

    return {
      ...rest,
      apiURL: `${host}/${path}`,
    };
  }

  clientOptions() {
    let clientOptions = super.clientOptions();

    return {
      ...clientOptions,
      connectToDevTools: config.apollo.connectToDevTools,
      name: 'my-phorest',
    };
  }

  cache() {
    return new InMemoryCache({
      possibleTypes: {
        RecurrenceUnion: [
          'MonthlyDateRecurrence',
          'MonthlyWeekDayRecurrence',
          'WeeklyRecurrence',
        ],
        BasketItemUnion: [
          'AppointmentBasketItem',
          'AppointmentCourseSessionBasketItem',
          'AppointmentPackageBasketItem',
          'AppointmentServiceRewardBasketItem',
          'AppointmentSpecialOfferBasketItem',
          'CancellationChargeBasketItem',
          'CourseBasketItem',
          'CourseSessionBasketItem',
          'CreditAccountAppointmentDepositBasketItem',
          'CreditAccountBasketItem',
          'MembershipBasketItem',
          'NoShowChargeBasketItem',
          'OfflineDepositBasketItem',
          'OnlineDepositBasketItem',
          'OpenSaleBasketItem',
          'PackageBasketItem',
          'ProductBasketItem',
          'ProductRewardBasketItem',
          'SaleFeeBasketItem',
          'ServiceBasketItem',
          'ServiceRewardBasketItem',
          'SpecialOfferBasketItem',
          'VoucherBasketItem',
          'VoucherTopUpBasketItem',
        ],
        BasketPaymentUnion: [
          'BasketIntegratedPayment',
          'BasketNonIntegratedPayment',
        ],
        CalendarEvent: ['Appointment', 'Break'],
        StaffCalendarEvent: ['Appointment', 'Break'],
      },
      typePolicies: {
        Query: {
          fields: {
            chainServiceCategories: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            clientCategories: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            clients: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            clientSources: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            departments: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            products: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy', 'branchId'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            brandsConnection: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            chainBrands: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            globalBrands: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            productCategories: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            chainProductCategories: {
              keyArgs: ['filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            saleFees: {
              keyArgs: ['branchId'],
              merge: mergeFeedConnectionPolicy,
            },
            staffConnection: {
              keyArgs: ['filterBy', 'branchId'],
              merge: mergeFeedConnectionPolicy,
            },
            serviceCategories: {
              keyArgs: ['branchId'],
              merge: mergeFeedConnectionPolicy,
            },
            clientPhotos: {
              keyArgs: ['clientId', 'date'],
              merge: mergeFeedConnectionPolicy,
            },
            productRewards: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy', 'branchId'];
                if (context.variables?._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            serviceRewards: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy', 'branchId'];
                if (context.variables?._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            serviceHistory: {
              keyArgs: (args, context) => {
                let keyArgs = ['clientId', 'filterBy'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            services: {
              keyArgs: [
                'filterBy',
                'servicePriceContext',
                'serviceDurationContext',
                'branchId',
              ],
              merge: mergeFeedConnectionPolicy,
            },
            stockTakeList: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            taxRates: {
              keyArgs: (args, context) => {
                let keyArgs = ['filterBy', 'branchId'];
                if (context.variables?._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            tills: {
              keyArgs: (args, context) => {
                let keyArgs = ['branchId'];
                if (context.variables?._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            vouchersByClientId: {
              keyArgs: (args, context) => {
                let keyArgs = ['clientId'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            waitlistBookings: {
              keyArgs: ['branchId', 'filterBy'],
              merge: mergeFeedConnectionPolicy,
            },
            memberships: {
              keyArgs: (args, context) => {
                let keyArgs = ['branchId', 'filterBy'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
            purchasedProducts: {
              keyArgs: (args, context) => {
                let keyArgs = ['clientId', 'filterBy'];

                if (context.variables._paginated) {
                  keyArgs = [...keyArgs, 'after', 'first'];
                }
                return keyArgs;
              },
              merge: mergeFeedConnectionPolicy,
            },
          },
        },
        Basket: {
          fields: {
            items: {
              keyArgs: false,
              merge: false,
            },
          },
        },
        Branch: {
          fields: {
            payment: {
              merge: true,
            },
          },
        },
        Client: {
          fields: {
            treatCard: {
              merge: false,
            },
          },
        },
        ServiceGroup: {
          fields: {
            // `color` is a [local-only field](https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/).
            color: {
              read() {
                // Packages don't have colors but are displayed in the same way as other items on the lists - with an avatar.
                // This value sets the same (background) color to all packages.
                return {
                  hex: '#F3F4F6',
                };
              },
            },
          },
        },
        Staff: {
          fields: {
            calendarDays: {
              keyArgs: [],
              merge: mergeCalendarDaysPolicy,
            },
          },
        },
        Machine: {
          fields: {
            calendarDays: {
              keyArgs: [],
              merge: mergeCalendarDaysPolicy,
            },
          },
        },
        Room: {
          fields: {
            calendarDays: {
              keyArgs: [],
              merge: mergeCalendarDaysPolicy,
            },
          },
        },
        WaitlistBooking: {
          fields: {
            items: {
              keyArgs: false,
              merge: false,
            },
            availabilityPreferences: {
              keyArgs: false,
              merge: false,
            },
          },
        },
        ClientMembership: {
          fields: {
            chainMembership: {
              merge: false,
            },
          },
        },
        // These types can be returned with null id, so use branch.id as a fallback
        CourseBranchOverride: {
          keyFields: ['id', 'branch', ['id']],
        },
        ServiceGroupBranchOverride: {
          keyFields: ['id', 'branch', ['id']],
        },
        ChainServiceGroup: {
          fields: {
            items: {
              keyArgs: false,
              merge: false,
            },
          },
        },
        ProductBranchOverride: {
          keyFields: ['id', 'branch', ['id']],
        },
        ProductSpecialOfferBranchOverride: {
          keyFields: ['id', 'branch', ['id']],
        },
        StockTakeItem: {
          keyFields: ['id', 'product', ['id']],
        },
        StockTransferItem: {
          keyFields: ['id', 'fromProduct', ['id']],
        },
      },
    });
  }

  link() {
    const httpLink = super.link();

    let authMiddleware = setContext(async (_request, context) => {
      let token = await this.session.freshAccessToken();
      let securityScope = this.session.securityScope;
      let appVersion = appVersionString(
        config.APP,
        this.swingBridge,
        this.electronApp
      );

      context.headers = {
        ...context.headers,
        authorization: `Bearer ${token}`,
        'x-memento-security-context': securityScope, // TODO: rename header key to: 'x-security-business-scope'
        'x-app-version': appVersion,
        'x-phorest-application-id': 'my-phorest',
        'x-phorest-application-instance-id':
          this.versionTracker.applicationInstanceId,
      };

      return context;
    });

    const networkLoggerLink = new ApolloLink((operation, forward) => {
      if (variation('release-track-cache-utilization')) {
        const isMutation = operation.query.definitions.some(
          (definition) => definition.operation === 'mutation'
        );
        if (!isMutation) {
          this.pendo.trackEvent('Cache utilization - XHR generated');
        }
      }
      return forward(operation);
    });

    const retryLink = new RetryLink({
      attempts: (count, operation, error) => {
        const isErrorWorthRetrying =
          !!error && (error.statusCode > 500 || error.networkError);

        if (isErrorWorthRetrying) {
          this.errorHandler.handle(error, {
            showError: false,
            sentryLevel: 'warning',
            sentryTags: {
              requestRetryAttempt: count,
              requestRetryOperation: operation.operationName,
            },
          });
        }

        return count <= 3 && isErrorWorthRetrying;
      },
      delay: (count) => {
        return Math.min(count * 1000, 5 * 1000);
      },
    });

    if (!this.session || !this.session.silo) {
      return authMiddleware.concat(httpLink);
    } else {
      let { webSocketsUrl } = this;
      let wsLink = new WebSocketLink({
        url: webSocketsUrl,
        connectionParams: () => {
          const { accessToken, securityScope } = this.session;
          return {
            authorization: `Bearer ${accessToken}`,
            'x-security-business-scope': securityScope,
          };
        },
      });

      return split(
        ({ query }) => {
          let { kind, operation } = getMainDefinition(query);

          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        authMiddleware
          .concat(networkLoggerLink)
          .concat(retryLink)
          .concat(httpLink)
      );
    }
  }

  get apiGatewayHost() {
    const { host } = config.apollo;
    if (host) {
      return host;
    }
    const { apiGatewayEU, apiGatewayUS } = config.APP;
    const { silo } = this.session;

    if (!silo) {
      throw new Error('Cannot choose API Gateway without a silo!');
    }

    return {
      eu: apiGatewayEU,
      us: apiGatewayUS,
    }[silo.toLowerCase()];
  }

  get webSocketsUrl() {
    const { hostEU, hostUS, path } = config.apollo.webSockets;
    const { silo } = this.session;

    if (!silo) {
      throw new Error('Cannot choose WebSockets URL without a silo!');
    }

    return {
      eu: `${hostEU}/${path}`,
      us: `${hostUS}/${path}`,
    }[silo.toLowerCase()];
  }
}

class WebSocketLink extends ApolloLink {
  client;

  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}

// import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

export const formatNational = (phone) => {
  return phone;
  /*
  // TODO: Restore this but with more caution.
  // See my notes at https://phorest.jira.com/browse/WT-1780
  if (phone && isValidPhoneNumber(`+${phone}`)) {
    return parsePhoneNumber(`+${phone}`).formatNational();
  }
  return phone;
  */
};

export const phoneGetUri = (phone) => {
  // TODO: Restore this but with more caution.
  // See my notes at [Github PR URL]
  if (phone) {
    let uri = `tel:${phone}`;
    return uri;
    /*
    // TODO: Restore this but with more caution.
    // See my notes at https://phorest.jira.com/browse/WT-1780
    if (isValidPhoneNumber(`+${phone}`)) {
      uri = parsePhoneNumber(`+${phone}`).getURI();
    }
    return uri;
    */
  }
};

import Modifier from 'ember-modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { schedule } from '@ember/runloop';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';

export default class WithCurrency extends Modifier {
  @tracked disabled = false;

  elt;
  money;
  allowNegative;
  onInput;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.unregisterListeners);
  }

  modify(
    element,
    [money],
    {
      allowNegative = false,
      allowNull = false,
      disabled = false,
      onInput,
      onBlur,
    }
  ) {
    this.elt = element;
    this.allowNegative = allowNegative;
    this.allowNull = allowNull;
    this.onInput = onInput;
    this.notifyBlur = onBlur;
    this.disabled = disabled;

    if (!this.disabled) {
      this.elt.addEventListener('focus', this.extractAmount);
      this.elt.addEventListener('blur', this.onBlur);
      this.elt.addEventListener('input', this.sanitizeUserInput);
      this.elt.addEventListener('keydown', this.onKeydown);

      // this._initialize will now run every time an arg changes
      // so make sure whatever happens inside can happen multiple times
      // and doesn't break the intended behavior
      // Oh, `didUpdateArguments` ran this._initialize so it seems
      // we do want to run modify again, then.
      this._initialize(money);
    } else {
      this.unregisterListeners();
    }
  }

  @action
  unregisterListeners() {
    this.elt.removeEventListener('focus', this.extractAmount);
    this.elt.removeEventListener('blur', this.onBlur);
    this.elt.removeEventListener('input', this.sanitizeUserInput);
    this.elt.removeEventListener('keydown', this.onKeydown);
  }

  @service
  currencyFormatter;

  @action
  extractAmount() {
    this.elt.value = this.money?.amount || '';
  }

  @action
  onBlur() {
    this.formatValue();
    this.notifyBlur?.();
  }

  @action
  formatValue() {
    if (this.allowNull && !this.money) {
      this.elt.value = '';
      return;
    }
    if (this.allowNegative) {
      this.money.amount = this._sanitizeNegativeValues(this.money.amount);
    }
    this.elt.value = this.currencyFormatter.format(this.money);
  }

  @action
  sanitizeUserInput(event) {
    let value = event.target.value;
    if (this.allowNull && value === '') {
      this.onInput(null, event);
      return;
    }
    value = value.replace(/,/g, '.');
    value = this._parseNegatives(value);
    value = this._truncateRedundantDots(value);
    value = this._truncateRedundantDecimals(value);
    value = this._removeLeadingZeros(value);

    this.onInput(value, event);
    this._ifIsSafariPreserveCaretPosition();
  }

  @action
  onKeydown(event) {
    if (event.key === '.' || event.key === ',') {
      if (this._hasSeparator(event.target.value)) {
        event.preventDefault();
      }
    }
  }

  _initialize(money) {
    this.money =
      money == null && this.allowNull
        ? null
        : this.currencyFormatter.ensureIsMoneyObj(money);

    if (document.activeElement === this.elt) {
      this.extractAmount();
    } else {
      this.formatValue();
    }
  }

  _parseNegatives(value) {
    if (this.allowNegative) {
      return value
        .replace(/[^0-9.-]/g, '')
        .replace(/(?!^)-/g, '')
        .replace(/^-00$/g, '-0');
    } else {
      return value.replace(/[^0-9.]/g, '').replace(/^00$/, '0');
    }
  }

  _truncateRedundantDots(value) {
    let split = value.split('.');
    return split.length > 2 ? split.slice(0, 2).join('.') : value;
  }

  _truncateRedundantDecimals(value) {
    let split = value.split('.');
    let decimals = split[1] || [];
    let integer = split[0] || [];
    let first2Decimals = decimals.slice(0, 2);
    return first2Decimals.length > 0 ? integer + '.' + first2Decimals : value;
  }

  _removeLeadingZeros(value) {
    return value.replace(/^0+(?!\.|$)/, '');
  }

  _sanitizeNegativeValues(value) {
    return String(value).replace(/^-0*\.*0*$/g, '');
  }

  _hasSeparator(string) {
    return string.includes('.') || string.includes(',');
  }

  _ifIsSafariPreserveCaretPosition() {
    let isSafari = navigator.userAgent.includes('Safari');
    if (isSafari) {
      let cursorPosition = this.elt.selectionStart;
      schedule('afterRender', () => {
        this.elt.setSelectionRange(cursorPosition, cursorPosition);
      });
    }
  }
}

import { isEmpty, isPresent } from '@ember/utils';

import config from 'my-phorest/config/environment';

const hasMirage = config['ember-cli-mirage'].enabled;

export function extractMirageAutostartFromUrl(hashString) {
  if (isEmpty(hashString)) {
    return null;
  }

  let regex = /m=(?<useMirage>[\w/+=-]+)/;

  let matches = hashString.match(regex);

  return matches?.groups?.useMirage;
}

export function initialize() {
  let { location, sessionStorage } = window;

  let useMirage = extractMirageAutostartFromUrl(location.hash);

  if (isPresent(useMirage)) {
    sessionStorage.setItem(config.mirageAutostartStorageKey, useMirage);
  }
}

export default {
  ...(hasMirage
    ? { initialize, before: 'ember-cli-mirage' }
    : { initialize: () => {} }),
};

import Service from '@ember/service';

export default class PopperService extends Service {
  refs = {};

  saveRef(id, ref) {
    this.refs[id] = ref;
  }

  removeRef(id) {
    delete this.refs[id];
  }

  refresh(id) {
    this.refs[id]?.update();
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import * as yup from 'yup';

const MAX_CUSTOM_URL_LENGTH = 512;

const GDPR_COUNTRIES = [
  'AT',
  'AX',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GF',
  'GP',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MQ',
  'MR',
  'MT',
  'NL',
  'PL',
  'RE',
  'RO',
  'SE',
  'SI',
  'SK',
  'YT',
  'GB',
];

export default class UiEditorCustomLinkModalComponent extends Component {
  @service session;
  @service router;

  get validationSchema() {
    const customUrlValidation = yup
      .string()
      .required()
      .url()
      .max(MAX_CUSTOM_URL_LENGTH, {
        key: 'validations.over-maximum',
        context: {
          max: MAX_CUSTOM_URL_LENGTH,
        },
      });
    const customUrlTextValidation = yup.string().required();

    return yup.object().shape({
      customUrl: customUrlValidation,
      customUrlText: customUrlTextValidation,
    });
  }

  get isGDPRCountry() {
    return GDPR_COUNTRIES.includes(this.session.locale.countryCode);
  }

  get isCalifornia() {
    return this.session.branch.address.htmlFormattedAddress.includes(
      'California'
    );
  }

  @action
  validationModel() {
    return {
      customUrlText: this.args.textInputValue,
      customUrl: this.args.linkInputValue,
    };
  }

  @action
  goToGuidelines() {
    window.open(
      'https://support.phorest.com/hc/en-us/articles/4402193978770-What-is-the-difference-between-a-Transactional-message-and-a-Marketing-message#insertlinks',
      '_blank'
    );
  }
}

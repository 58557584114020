import { getOwner } from '@ember/application';

class Cache {
  constructor(query, cache, variables) {
    this.query = query;
    this.cache = cache;
    this.variables = variables;
  }

  async getData() {
    let query = this.query;
    let variables = await this.variables();
    return this.cache.readQuery({ query, variables });
  }

  async saveData(data) {
    let query = this.query;
    let variables = await this.variables();
    return this.cache.writeQuery({
      query,
      variables,
      data,
    });
  }
}

export function cacheFor({ variables, query }) {
  return () => {
    return {
      initializer() {
        let service = getOwner(this).lookup(`service:apollo`);
        let cache = service.client.cache;
        return new Cache(query, cache, () => variables.call(this));
      },
    };
  };
}

import Service, { service } from '@ember/service';

export default class LocalSettingsService extends Service {
  @service session;
  @service('browser/local-storage') localStorage;

  _storageKey(key) {
    return `${key}-${this.session.currentAccountId}`;
  }

  get(key, defaultValue) {
    const setting = this.localStorage.getItem(this._storageKey(key));

    return setting ?? defaultValue;
  }

  set(key, value) {
    this.localStorage.setItem(this._storageKey(key), value);
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';

export default class TooltipComponent extends Component {
  @service tooltip;

  get options() {
    return {
      placement: this.tooltip.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 8,
          },
        },
      ],
    };
  }
}

const SUPPORTED_LOCALES = [
  'de',
  'en',
  'en-au',
  'en-ca',
  'en-us',
  'fi',
  'fr',
  'nl',
];

export const INTL_LOCALE_STORAGE_KEY = 'intl-locale';

export async function loadTranslations(intl, locales) {
  const locale = pickLocale(locales);
  let translations;

  if (locale === 'de') {
    translations = await import('/translations/de.json');
  } else if (locale === 'en-au') {
    translations = await import('/translations/en-au.json');
  } else if (locale === 'en-ca') {
    translations = await import('/translations/en-ca.json');
  } else if (locale === 'en-us') {
    translations = await import('/translations/en-us.json');
  } else if (locale === 'fi') {
    translations = await import('/translations/fi.json');
  } else if (locale === 'fr') {
    translations = await import('/translations/fr.json');
  } else if (locale === 'nl') {
    translations = await import('/translations/nl.json');
  } else {
    translations = await import('/translations/en.json');
  }

  if (translations) {
    intl.addTranslations(locale, translations);
  }
}

export function pickLocale(locales) {
  for (let i = 0, len = locales.length; i < len; i++) {
    let locale = locales[i];
    if (SUPPORTED_LOCALES.includes(locale)) {
      return locale;
    }
  }
  return 'en';
}

export function prepareIntlLocale({ lang, countryCode } = {}) {
  let locales = [];
  if (lang && countryCode) {
    locales.push(`${lang}-${countryCode}`.toLowerCase());
  }
  if (lang) {
    locales.push(lang);
  }
  if (!locales.includes('en')) {
    locales.push('en');
  }
  return locales;
}

import { tracked } from '@glimmer/tracking';

const { assign } = Object;

const isObject = function (value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const buildNestedProxies = function (obj) {
  for (let property in obj) {
    if (isObject(obj[property])) {
      obj[property] = trackProps(obj[property]);
    }
  }
};

const traverseNestedProxies = function (obj) {
  for (let property in obj) {
    if (isObject(obj[property]) && obj[property].hasChanges) {
      return true;
    }
  }
  return obj.hasChanges;
};

const setNestedHasChanges = function (obj, value) {
  for (let property in obj) {
    if (isObject(obj[property])) {
      obj[property].hasChanges = value;
    }
  }
};

class TrackedHasChanges {
  @tracked
  hasChanges = false;
}

export default function trackProps(obj) {
  let copy = JSON.parse(JSON.stringify(obj));
  let trackedHasChanges = new TrackedHasChanges();
  assign(trackedHasChanges, copy);
  buildNestedProxies(trackedHasChanges);

  const handler = {
    set: function (obj, prop, _value) {
      let value = isObject(_value) ? trackProps(_value) : _value;
      if (prop === 'hasChanges') {
        setNestedHasChanges(obj, value);
      } else {
        obj.hasChanges = true;
      }
      obj[prop] = value;
      return true;
    },
    get: function (obj, prop) {
      if (prop === 'hasChanges') {
        if (obj.hasChanges) return true;
        return traverseNestedProxies(obj);
      } else {
        return obj[prop];
      }
    },
  };
  return new Proxy(trackedHasChanges, handler);
}

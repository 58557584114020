import { classify } from '@ember/string';

export const DEFAULT_CATEGORY = 'FINANCIAL';
export const DEFAULT_REPORT = 'TOTALS';

export const CATEGORIES = [
  'FINANCIAL',
  'STAFF',
  'CLIENTS',
  'SERVICES',
  'PRODUCTS',
  'VOUCHERS',
  'OTHERS',
  'MULTI_BRANCH',
];
export const CATEGORY_ICON_MAP = {
  FINANCIAL: 'receipt-tax',
  STAFF: 'user',
  CLIENTS: 'users',
  SERVICES: 'clipboard',
  PRODUCTS: 'shopping-bag',
  VOUCHERS: 'ticket',
  OTHERS: 'document-report',
  MULTI_BRANCH: 'office-building',
  DEFAULT: 'document-report',
};

export function getCategoryIcon(category) {
  const icon = CATEGORY_ICON_MAP[category.toUpperCase()];
  return icon ?? CATEGORY_ICON_MAP.DEFAULT;
}

/**
 * Converts filter.id into resource type. Example: client_card_id => ClientCard
 * Some exceptions attached (e.g. Product should be sent as BranchProduct).
 *
 * @param {string} filterId
 * @returns {string}
 */
export function getResourceType(filterId) {
  let exceptions = {
    ProductCat: 'BranchProductCategory',
    Product: 'BranchProduct',
    ServiceCat: 'BranchServiceCategory',
    Service: 'BranchService',
  };
  let type = classify(filterId.replace('_id', ''));
  return exceptions[type] ? exceptions[type] : type;
}

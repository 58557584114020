export function valueFromPath(obj, path) {
  const pathParts = path.split('.');

  return pathParts.reduce((currentObj, part) => {
    if (currentObj && currentObj[part] != null) {
      return currentObj[part];
    } else {
      return undefined;
    }
  }, obj);
}

export function setValueForPath(obj, path, value) {
  const pathParts = path.split('.');

  const pathPartsExceptLast = pathParts.slice(0, -1);

  const lastPart = pathPartsExceptLast.reduce((currentObj, part) => {
    if (currentObj && currentObj[part] != null) {
      return currentObj[part];
    } else {
      return undefined;
    }
  }, obj);

  lastPart[pathParts[pathParts.length - 1]] = value;
}

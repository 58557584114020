import * as yup from 'yup';

const ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}$/;

function yupISO8601DateFormat() {
  return this.transform(function (value, originalValue) {
    if (originalValue instanceof Date) return originalValue;

    return originalValue.match(ISO_8601_REGEX)
      ? new Date(originalValue)
      : new Date('');
  });
}

export function initialize() {
  yup.addMethod(yup.date, 'iso', yupISO8601DateFormat);
}

export default {
  initialize,
};

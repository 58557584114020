import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { helper } from '@ember/component/helper';

export class StateValue {
  @tracked value;

  constructor(value) {
    this.setState(value);
  }

  @action
  setState(value) {
    this.value = value;
  }
}

export default helper(function ([value]) {
  return new StateValue(value);
});

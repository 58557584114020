import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { restartableTask, timeout } from 'ember-concurrency';

import { durationBetween } from 'my-phorest/utils/local-datetime-helpers';
import { registerDestructor } from '@ember/destroyable';

const ACTIVITY_EVENTS = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
];

const USER_IDLENESS_INTERVAL_MIN = 30; // in minutes
export default class UserIdlenessService extends Service {
  @service branchTime;
  @service('browser/document') document;
  @service('browser/local-storage') localStorage;

  lastActivity;

  constructor() {
    super(...arguments);
    this.lastActivity = this.branchTime.dateAndTime;
    this.setupEventHandlers();
    registerDestructor(this, this.teardownEventHandlers);
  }

  idleTime({ granularity = 'minutes' } = {}) {
    let inIdleness = durationBetween(
      this.branchTime.dateAndTime,
      this.lastActivity
    );
    if (!inIdleness) {
      return null;
    }
    return inIdleness.as(granularity);
  }

  isUserIdle() {
    let idleTime = this.idleTime();
    if (!idleTime) {
      return false;
    }
    return idleTime > USER_IDLENESS_INTERVAL_MIN;
  }

  @restartableTask
  *trackActivityTask() {
    if (this.idleTime({ granularity: 'seconds' }) <= 5) {
      yield timeout(1_000);
    }
    this.lastActivity = this.branchTime.dateAndTime;
  }

  setupEventHandlers() {
    ACTIVITY_EVENTS.forEach((eventName) => {
      this.document.addEventListener(eventName, this.trackActivityTask.perform);
    });
  }

  @action
  teardownEventHandlers() {
    ACTIVITY_EVENTS.forEach((eventName) => {
      this.document.removeEventListener(
        eventName,
        this.trackActivityTask.perform
      );
    });
  }
}

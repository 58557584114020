import Service, { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { queryManager } from 'ember-apollo-client';
import tillSessionQuery from 'my-phorest/gql/queries/till-session.graphql';
import closeTillSessionMutation from 'my-phorest/gql/mutations/close-till-session.graphql';
import openTillSessionMutation from 'my-phorest/gql/mutations/open-till-session.graphql';
import {
  convertToUTCDatetime,
  convertToLocalDate,
  convertToLocalTime,
  convertToTimeZone,
} from 'my-phorest/utils/local-datetime-helpers';
import {
  isSame as isSameDay,
  addDays,
} from 'my-phorest/utils/local-date-helpers';
export default class TillSessionsService extends Service {
  @service session;
  @service branchTime;
  @service keypad;
  @service intl;

  /** @type import('ember-apollo-client/addon/services/apollo').default */
  @queryManager apollo;

  async prepareTillSession(tillId) {
    const currentSession = await this.apollo.query(
      {
        query: tillSessionQuery,
        variables: {
          tillId,
        },
        fetchPolicy: 'network-only', // make sure to always get the latest
      },
      'currentTillSession'
    );

    if (!currentSession) {
      const startingAmount = await this.askForOpeningFloatAmount();
      return this.openTillSession(tillId, { amount: startingAmount.amount });
    }

    const branchTimeZone = this.session.branchTimeZone;
    const today = this.branchTime.today;

    const startTime = convertToTimeZone(
      currentSession.startTime,
      branchTimeZone
    );
    const timePart = convertToLocalTime(startTime);
    const datePart = convertToLocalDate(startTime);

    const sameTimeTomorrow = convertToUTCDatetime(
      `${addDays(datePart, 1)}T${timePart}`
    );

    // We already have a session for today
    if (isSameDay(startTime, today) && isEmpty(currentSession.endTime)) {
      return;
    }

    if (this.session.branch.advancedCashUp) {
      if (isEmpty(currentSession.endTime)) {
        // With advanced cash up, you can have a long running session. So as long
        // as the current session is not closed yet, we are good.
        return;
      } else {
        let amount = currentSession.endCashAmount;
        if (isEmpty(amount)) {
          amount = (await this.askForOpeningFloatAmount()).amount;
        }
        const startTime = this.branchTime.dateAndTime;

        return this.openTillSession(tillId, { amount, startTime });
      }
    }

    await this.apollo.mutate(
      {
        mutation: closeTillSessionMutation,
        variables: {
          tillId,
          tillSessionId: currentSession.id,
          endingStaffId: this.session.currentStaffMemberId,
          endTime: convertToLocalDate(sameTimeTomorrow),
        },
      },
      'closeTillSession'
    );

    await this.openTillSession(tillId);
  }

  openTillSession(tillId, options = {}) {
    const variables = {
      tillId,
      startingStaffId: this.session.currentStaffMemberId,
    };

    if (isPresent(options?.startTime)) {
      variables.startTime = options.startTime;
    } else {
      const today = this.branchTime.today;
      const startOfToday = convertToUTCDatetime(`${today}T00:00:00`);
      variables.startTime = convertToLocalDate(startOfToday);
    }

    if (isPresent(options?.amount)) {
      variables.amount = options.amount;
    }

    return this.apollo.mutate(
      {
        mutation: openTillSessionMutation,
        variables,
      },
      'openTillSession'
    );
  }

  async askForOpeningFloatAmount() {
    return new Promise((resolve) => {
      this.keypad.openKeypad({
        value: 0,
        headerText: this.intl.t('till-sessions.set-opening-float'),
        variant: 'price',
        allowNegative: false,
        required: true,
        onChange: resolve,
        onCancel: () => {},
      });
    });
  }
}

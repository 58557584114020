import Modifier from 'ember-modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';

function onlyKeepDigits(string) {
  return string.replace(/[^\d.]+/g, '').replace(/(\..*)\./g, '$1');
}

/*
The `value` passed into the modifier should be a decimal (non-percentage) value
Internally, the `value` attribute of the `input` should always be a percentage
value. The conversion from percentage (25%) to decimal (0.25) happens in `yieldDecimal`
(every time the input changes) and it's the `onInput` function that should take care
of using that value in the context.
*/
export default class WithPercentage extends Modifier {
  @service intl;
  @tracked disabled = false;

  elt;
  onInput;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.unregisterListeners);
  }

  modify(element, [value], { onInput, disabled }) {
    this.elt = element;
    this.onInput = onInput;
    this.disabled = disabled;

    if (!this.disabled) {
      element.addEventListener('input', this.yieldDecimal);
      element.addEventListener('focus', this.stripNonDigits);
      element.addEventListener('blur', this.formatToPercentage);

      let decimalValue = this._multiplyValueBy(value, 100);
      element.value = decimalValue;
      if (document.activeElement !== element) {
        this.formatToPercentage();
      }
    } else {
      this.unregisterListeners();
    }
  }

  _multiplyValueBy(value, factor) {
    let newValue = value * factor;

    // Fixes a floating-point arithmetic concept explained here https://en.wikipedia.org/wiki/Floating-point_arithmetic
    // where for example: 0.14 * 100 = 14.000000000000002
    if (factor === 100) {
      newValue = newValue.toFixed(2);
      newValue = String(newValue).split('.00')[0];
    }

    return Number(newValue);
  }

  _formatValue() {
    let value = onlyKeepDigits(String(this.elt.value));
    this.elt.value = this.intl.formatNumber(value / 100, {
      style: 'percent',
      maximumFractionDigits: 2,
    });
  }

  @action
  yieldDecimal(event) {
    let value = onlyKeepDigits(event.target.value);
    if (value.split('.')[1]?.length > 2) {
      value = value.slice(0, -1);
    }

    if (value.slice(-1) !== '.') {
      this.onInput?.(this._multiplyValueBy(value, 0.01), event);
    }

    this.elt.value = value;
  }

  @action
  stripNonDigits() {
    this.elt.value = onlyKeepDigits(this.elt.value);
  }

  @action
  formatToPercentage() {
    this._formatValue();
  }

  @action
  unregisterListeners() {
    this.elt.removeEventListener('input', this.yieldDecimal);
    this.elt.removeEventListener('focus', this.stripNonDigits);
    this.elt.removeEventListener('blur', this.formatToPercentage);
  }
}

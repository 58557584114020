import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, waitForProperty } from 'ember-concurrency';

import {
  isAllResourcesDayView,
  isDayView,
  isMachineView,
  isRoomView,
  isStaffView,
  isWeekView,
} from 'my-phorest/utils/calendar';
import { ActionsHistory } from 'my-phorest/utils/actions-history';
import { CalendarEvent } from 'my-phorest/utils/calendar-events';

export default class FullCalendarService extends Service {
  @service localSettings;

  @tracked calendarApi;

  @tracked view;
  @tracked viewSingleResource;
  @tracked departmentId;

  @tracked _selectedAppointments = [];
  @tracked _selectedBreak;
  @tracked selectedResource;

  @tracked actionsHistory = new ActionsHistory();
  @tracked hideAppointmentIcons = false;
  @tracked limitTimeSlotsRange = true;

  onRegisterListeners = [];
  viewDatesInfo;

  addOnRegisterEventListener(func) {
    if (typeof func === 'function') {
      this.onRegisterListeners.push(func);
    }
  }

  clearActionsHistory() {
    this.actionsHistory.clear();
  }

  clearSelected() {
    this._selectedAppointments = [];
    this._selectedBreak = null;
  }

  getAllAppointmentsForGroupBooking(groupBookingId) {
    if (!this.calendarApi) {
      console.error(
        'Trying to access the calendar API while it is not available yet.' +
          ' Please consider using `waitForCalendarApiTask` to ensure it has been registered first.'
      );
      return [];
    }

    return this.calendarApi
      .getEvents()
      .filter(
        (appointment) =>
          appointment.extendedProps.groupBookingId === groupBookingId
      )
      .map((e) => CalendarEvent.fromFCEvent(e));
  }

  registerApi(calendar) {
    this.calendarApi = calendar;

    this.onRegisterListeners.forEach((func) => {
      func();
    });
    this.onRegisterListeners = [];
  }

  unregisterApi() {
    this.calendarApi = null;
    this.onRegisterListeners = [];
  }

  /**
   * Wait for the calendar API to be fully registered.
   * The task finishes as soon as the API is available.
   */
  @task
  *waitForCalendarApiTask() {
    yield waitForProperty(this, 'calendarApi', (c) => !!c);
  }

  get firstSelectedAppointment() {
    // XXX guaranteed to be the service that the user selected
    return this.hasSelectedAppointments ? this._selectedAppointments[0] : null;
  }

  get selectedAppointmentsByUserSelection() {
    // XXX guaranteed that the service the user clicked is first in list
    return this._selectedAppointments;
  }

  get selectedAppointments() {
    return [...this._selectedAppointments];
  }

  get selectedBreak() {
    return this._selectedBreak;
  }

  set selectedAppointments(selectedAppointments) {
    this.clearSelected();
    this._selectedAppointments = selectedAppointments;

    if (selectedAppointments.length) {
      this.selectedResource = selectedAppointments[0].resource;
    }
  }

  set selectedBreak(selectedBreak) {
    this.clearSelected();
    this._selectedBreak = selectedBreak;

    this.selectedResource = selectedBreak.resource;
  }

  get hasSelectedAppointments() {
    return this._selectedAppointments?.length > 0;
  }

  get hasSelectedBreak() {
    return !!this._selectedBreak;
  }

  get hasSelectedResource() {
    return !!this.selectedResource;
  }

  get isAllResourcesDayView() {
    return isAllResourcesDayView(this.view);
  }

  get isDayView() {
    return isDayView(this.view);
  }

  get isRoomView() {
    return isRoomView(this.view);
  }

  get isStaffView() {
    return isStaffView(this.view);
  }

  get isMachineView() {
    return isMachineView(this.view);
  }

  get isWeekView() {
    return isWeekView(this.view);
  }

  get appointmentsWithDifferentClientsSelected() {
    let clientId = this.firstSelectedAppointment?.clientId;
    return this.selectedAppointments.some((a) => a.clientId !== clientId);
  }
}

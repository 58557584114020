import Service, { service } from '@ember/service';
import currencyCode from 'my-phorest/utils/currency-code';
import { typeOf } from '@ember/utils';

export default class CurrencyFormatterService extends Service {
  @service intl;
  @service session;

  format(value, maxDigits = 2) {
    let money = this.ensureIsMoneyObj(value);
    return this.intl.formatNumber(money.amount, {
      style: 'currency',
      currency: money.currency,
      maximumFractionDigits: maxDigits,
    });
  }

  getCurrencySymbol(value) {
    let formatted = this.format(value);
    return formatted.replace(/[,.\d-]/g, '').trim();
  }

  ensureIsMoneyObj(value) {
    if (typeOf(value) === 'object') {
      let { amount, currency } = value;
      amount = amount ?? '0.00';
      return { amount, currency };
    }

    let { locale } = this.session;
    let currency = currencyCode(locale.countryCode);
    return { amount: value ?? '0.00', currency };
  }
}

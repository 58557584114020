export const BREAKPOINT_SM = 640;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 1024;
export const BREAKPOINT_XL = 1280;
export const BREAKPOINT_2XL = 1536;

export function isBelowBreakpoint(breakpoint) {
  return document.body.offsetWidth <= breakpoint;
}

export function isAboveBreakpoint(breakpoint) {
  return document.body.offsetWidth > breakpoint;
}

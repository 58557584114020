export function formatCssRgba({ r, g, b, a }) {
  return `rgba(${r},${g},${b},${a})`;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function changeBrightness(color, amount) {
  if (!color) return;

  let usePound = false;

  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);
  const { min, max } = Math;

  const r = max(0, min((num >> 16) + amount, 255));
  const b = max(0, min(((num >> 8) & 0x00ff) + amount, 255));
  const g = max(0, min((num & 0x0000ff) + amount, 255));

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

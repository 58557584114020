import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class extends Helper {
  @service router;

  compute([route, model]) {
    const embeddedPath = this.router.currentRoute?.params.embedded_path;

    if (model) {
      if (embeddedPath) {
        return embeddedPath.includes(model);
      }
      try {
        return this.router.isActive(route, model);
      } catch {
        return false;
      }
    }

    return this.router.isActive(route);
  }
}

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import staffCategoriesQuery from 'my-phorest/gql/queries/staff-categories.graphql';
import { fetchAllPaginatedResults } from 'my-phorest/utils/graphql';
import { action } from '@ember/object';

export default class StaffCategoryPricesSlideOverService extends Service {
  @queryManager apollo;
  @service notifications;
  @service intl;

  @tracked fallbackPrice;
  @tracked staffCategories;
  @tracked staffCategoryPricesByCategoryId;
  underlyingStaffCategoryPricesByCategoryId;
  parent;

  @tracked isOpen = false;

  async openWithStaffCategoryPrices(
    parent,
    staffCategoryPrices = [],
    underlyingStaffCategoryPrices = [],
    fallbackPrice
  ) {
    this.isOpen = true;
    this.parent = parent;
    this.fallbackPrice = fallbackPrice;
    this.staffCategories = await this.loadStaffCategories();
    this.staffCategoryPricesByCategoryId = Object.fromEntries(
      staffCategoryPrices.map((scp) => [scp.staffCategoryId, scp.price])
    );
    this.underlyingStaffCategoryPricesByCategoryId = Object.fromEntries(
      underlyingStaffCategoryPrices.map((scp) => [
        scp.staffCategoryId,
        scp.price,
      ])
    );
  }

  close() {
    this.isOpen = false;
  }

  get allStaffCategoriesWithPrice() {
    return this.staffCategories?.map((sc) => {
      return {
        staffCategory: sc,
        price: this.staffCategoryPricesByCategoryId?.[sc.id],
        underlyingPrice:
          this.underlyingStaffCategoryPricesByCategoryId?.[sc.id],
      };
    });
  }

  get staffCategoryPrices() {
    return Object.entries(this.staffCategoryPricesByCategoryId).map(
      ([staffCategoryId, price]) => {
        return {
          staffCategoryId,
          price,
        };
      }
    );
  }

  @action
  changePrice(staffCategoryId, price) {
    const newPrices = { ...this.staffCategoryPricesByCategoryId };
    delete newPrices[staffCategoryId];

    if (price != null) {
      newPrices[staffCategoryId] = price;
    }

    this.staffCategoryPricesByCategoryId = newPrices;
  }

  async loadStaffCategories() {
    return fetchAllPaginatedResults(
      this.apollo,
      {
        query: staffCategoriesQuery,
        variables: {
          first: 500,
        },
      },
      'staffCategories'
    );
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PrinterSetupService extends Service {
  @tracked showPrinterSelectorDialog = false;

  #callback;

  onSelect(printerName) {
    this.showPrinterSelectorDialog = false;
    this.#callback?.(printerName);
  }

  async selectReceiptPrinter() {
    this.showPrinterSelectorDialog = true;
    return new Promise((resolve) => {
      this.#callback = resolve;
    });
  }
}

import { TrackedArray } from 'tracked-built-ins';

export class ActionsHistory {
  history = new TrackedArray([]);

  get hasHistory() {
    return this.history.length > 0;
  }

  /**
   * Adds history point to the history list.
   *
   * @param {object} point
   * @param {object} point.context - context in which "undo" function should be executed
   * @param {function} point.undo - undo function, called when undo action is performed
   */
  addHistoryPoint(point) {
    this.history.push({
      date: new Date(),
      ...point,
    });
  }

  /**
   * Clears the history of actions
   */
  clear() {
    this.history.length = 0;
  }

  /**
   * Does the undo action
   *
   * @returns {null|*}
   */
  undo() {
    if (this.hasHistory) {
      const historyPoint = this.history.pop();
      const undoBind = historyPoint.undo.bind(historyPoint.context);
      return undoBind();
    }
    return null;
  }
}

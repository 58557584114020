/* eslint-disable phorest/moment-import */
import { assert } from '@ember/debug';
import moment from 'moment';
import {
  enhanceFormatOptions,
  removeDotsInAmPm,
} from 'my-phorest/utils/local-time-helpers';
import { rectifyZeroHourTime } from 'my-phorest/utils/rectify-zero-hour-time';

const DATETIME_FORMAT_PRESETS = {
  short: { dateStyle: 'medium', timeStyle: 'short' },
  long: { dateStyle: 'full', timeStyle: 'medium' },
  numeric: { dateStyle: 'short', timeStyle: 'medium' },
};

/** format: format a datetime using in the preferred style for the current user's locale
 * @param theDate ISO date string or Date object
 * @param intlService
 * @param formatOptions
 *  a string datetime format preset (see https://my-dev.phorest.com/styleguide?s=i18n&ss=Date%2FTime%20Presets)
 *  or, an object with Intl format options (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat)
 * @returns a formatted datetime string
 */
export function format(aDatetime, intlService, formatOptions = 'short') {
  assert(
    'You must provide the intl service in order to use this function',
    intlService
  );

  assert(
    `You must pass aDatetime as a string or Date object; you passed ${typeof aDate}`,
    typeof aDatetime === 'string' || aDatetime instanceof Date
  );

  if (typeof aDatetime === 'string') {
    aDatetime = convertToUTCDatetime(aDatetime);
  } else {
    aDatetime = aDatetime.toISOString();
  }

  if (typeof formatOptions === 'string') {
    if (DATETIME_FORMAT_PRESETS[formatOptions]) {
      formatOptions = DATETIME_FORMAT_PRESETS[formatOptions];
    }
  }

  let formattedDatetimeString = new Intl.DateTimeFormat(
    intlService.defaultLocale,
    enhanceFormatOptions(formatOptions, intlService)
  ).format(new Date(aDatetime));

  return removeDotsInAmPm(rectifyZeroHourTime(formattedDatetimeString));
}

/* convertToTimeZone: Converts the passed in ISO datetime string to the same time in the given timezone
 * @param datetime ISO datetime string e.g. '2020-04-12T08:30:00Z'
 * @param timezone string e.g. 'Europe/Dublin'
 * @returns local datetime string e.g. '2020-04-12T07:30:00'
 */
export function convertToTimeZone(datetime, timeZone) {
  assert(
    `Expecting an ISO datetime string (e.g. '2022-10-21T12:09Z'). You passed '${datetime}'`,
    typeof datetime === 'string' &&
      datetime.length >= 17 &&
      datetime.endsWith('Z')
  );

  const formatterOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  // It doesn't matter what locale we pass as the first parameter as long as
  // we know what the resulting output format is.
  const formatted = new Intl.DateTimeFormat('en-ie', {
    ...formatterOptions,
    timeZone,
  }).format(new Date(datetime));

  let [date, time] = formatted.split(', ');
  // The date format is dd/mm/yyyy so reversing it gives us the
  // correct ISO format: 14/02/2023 => 2023/02/14
  date = date.split('/').reverse().join('-');
  return `${date}T${time}`;
}

export function asMoment(datetimeString) {
  if (typeof datetimeString === 'undefined') return moment.utc();

  return moment.utc(datetimeString, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
}

export function isAfter(thisDate, thatDate) {
  return moment(thisDate).isAfter(moment(thatDate));
}

export function isBefore(thisDate, thatDate) {
  return moment(thisDate).isBefore(moment(thatDate));
}

export function isBetween(timeAndDate, { start, end }) {
  return isAfter(timeAndDate, start) && isBefore(timeAndDate, end);
}

export function durationBetween(thisDate, thatDate) {
  if (!thisDate || !thatDate) {
    return null;
  }
  return moment.duration(moment(thisDate).diff(moment(thatDate)));
}

/* convertToLocalDatetime: Converts an ISO datetime to local datetime
 * @param datetime string e.g. '2022-10-21T12:09:24.000Z')
 * @returns string e.g. '2022-10-21T12:09:24'
 */
export function convertToLocalDatetime(datetime) {
  assert(
    `Expecting an ISO datetime string (e.g. '2022-10-21T12:09:24.000Z'). You passed '${datetime}'`,
    typeof datetime === 'string' && datetime.length >= 19
  );

  return datetime.substring(0, 19);
}

/* convertToLocalDatetimeNoSeconds: Converts an ISO datetime to local datetime (no seconds)
 * @param datetime string e.g. '2022-10-21T12:09:24.000Z')
 * @returns string e.g. '2022-10-21T12:09'
 */
export function convertToLocalDatetimeNoSeconds(datetime) {
  assert(
    `Expecting an ISO datetime string (e.g. '2022-10-21T12:09:24.000Z'). You passed '${datetime}'`,
    typeof datetime === 'string' && datetime.length >= 16
  );

  return datetime.substring(0, 16);
}

/* convertToUTCDatetime: Converts an ISO datetime to UTC datetime
 * @param datetime string e.g. '2022-10-21T12:09:24')
 * @returns string e.g. '2022-10-21T12:09:24.000Z'
 */
export function convertToUTCDatetime(datetime) {
  assert(
    `Expecting an ISO datetime string (e.g. '2022-10-21T12:09:24'). You passed '${datetime}'`,
    typeof datetime === 'string' && datetime.length >= 16
  );

  if (datetime.length === 16) {
    return datetime + ':00.000Z';
  } else if (datetime.length === 19) {
    return datetime + '.000Z';
  } else {
    return datetime;
  }
}

/* convertToLocalTime: Returns time constituent of a datetime
 * @param datetime string e.g. '2022-10-21T12:09:24')
 * @returns string e.g. '12:09:24.000'
 */
export function convertToLocalTime(datetime) {
  assert(
    `Expecting an ISO datetime string (e.g. '2022-10-21T12:09:24'). You passed '${datetime}'`,
    typeof datetime === 'string' && datetime.length >= 16
  );

  return convertToUTCDatetime(datetime).substring(11, 19) + '.000';
}

/* convertToLocalDate: Returns date constituent of a datetime
 * @param datetime string e.g. '2022-10-21T12:09:24')
 * @returns string e.g. '2022-10-21'
 */
export function convertToLocalDate(datetime) {
  assert(
    `Expecting an ISO datetime string (e.g. '2022-10-21T12:09:24'). You passed '${datetime}'`,
    typeof datetime === 'string' && datetime.length >= 10
  );

  return datetime.substring(0, 10);
}

export function now() {
  return convertToLocalDatetime(new Date().toISOString());
}

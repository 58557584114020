import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import userByPinQuery from 'my-phorest/gql/queries/user-by-pin.graphql';
import { isBlank } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import { schedule } from '@ember/runloop';
import { tracked } from 'tracked-built-ins';

export default class AccessService extends Service {
  @service session;
  @service router;
  @service keypad;
  @service fullCalendar;
  @service intl;
  @service notifications;
  @service swingBridge;

  @queryManager apollo;

  @tracked requiredPermission = null;
  attemptedTransition = null;
  permissionPromise = null;
  #activePermissions = tracked(Set);

  addActivePermission(permission) {
    this.#activePermissions.add(permission);
  }

  removeActivePermission(permission) {
    this.#activePermissions.delete(permission);
  }

  get activePermissionsValid() {
    return [...this.#activePermissions].every((p) => this.hasPermission(p));
  }

  requirePermission(permission, transition) {
    if (this.hasPermission(permission)) {
      return Promise.resolve(true);
    }

    this.requiredPermission = permission;
    this.openPinPad();

    if (transition) {
      this.attemptedTransition = transition;
      transition.abort();

      if (!transition.from) {
        this.router.transitionTo(
          'accounts.account.noaccess',
          this.session.accountId
        );
      }
      return Promise.resolve(false);
    }

    return new Promise((resolve) => {
      this.permissionPromise = resolve;
    });
  }

  hasPermission(permission, user = null) {
    if (this.session.isGlobalUser) return true;
    if (isBlank(permission)) return true;

    let permissions;
    if (user) {
      const { branchId } = this.session;
      const staffMember = user.staff.find((s) => s.branch.id === branchId);
      if (!staffMember) return false;

      permissions = staffMember.accessLevel?.frontendPermissions;
    } else {
      if (!this.session.currentUserHasAccessToCurrentAccount) {
        return false;
      }
      permissions = this.session.currentUser?.permissions;
    }

    if (permission.startsWith('FIXED_DISCOUNT_ID_')) {
      return permissions?.includes(permission);
    }

    return permissions?.some((p) => AVAILABLE_PERMISSIONS[permission] === p);
  }

  @action
  cancelRequest() {
    if (this.permissionPromise) {
      this.permissionPromise(false);
    }
    this.keypad.reset();
    this._clearState();
  }

  openPinPad() {
    this.keypad.openKeypad({
      headerText: this.intl.t('access.pin-pad-header'),
      value: '',
      variant: 'pin',
      onChange: this.handlePin.perform,
      onCancel: this.cancelRequest,
      autoClose: false,
    });
  }

  @action
  openPinPadAndSwitchUser(options = {}) {
    return this.keypad.openKeypad({
      headerText: this.intl.t('access.pin-pad-header'),
      value: '',
      variant: 'pin',
      onChange: this.switchUserTask.perform,
      onCancel: options.onCancel ?? this.cancelRequest,
      cancelDisabled: options.cancelDisabled,
      hideBackground: options.hideBackground,
      autoClose: true,
    });
  }

  @dropTask
  *switchUserTask(pin, { notifySwing = true } = {}) {
    if (isBlank(pin)) return false;

    const newUser = yield this.apollo.query(
      {
        query: userByPinQuery,
        variables: {
          pin,
        },
        fetchPolicy: 'network-only',
      },
      'user'
    );

    if (!newUser) {
      this.notifications.failure(
        this.intl.t('access.error-title'),
        this.intl.t('access.not-found-error')
      );
      return false;
    }

    yield this.session.switchUser(newUser);
    this.fullCalendar.clearActionsHistory();

    if (notifySwing && this.swingBridge.isEmbeddedInSwing) {
      this.swingBridge.notifyLoggedInStaffChanged(pin);
    }

    return true;
  }

  @dropTask
  *handlePin(pin, { notifySwing = true } = {}) {
    if (isBlank(pin)) return false;

    const newUser = yield this.apollo.query(
      {
        query: userByPinQuery,
        variables: {
          pin,
        },
        fetchPolicy: 'network-only',
      },
      'user'
    );

    if (!newUser) {
      this.notifications.failure(
        this.intl.t('access.error-title'),
        this.intl.t('access.not-found-error')
      );
      return false;
    }

    if (!this.hasPermission(this.requiredPermission, newUser)) {
      this.notifications.failure(
        this.intl.t('access.error-title'),
        this.intl.t('access.no-permission-error')
      );
      return false;
    }

    yield this.session.switchUser(newUser);

    if (notifySwing && this.swingBridge.isEmbeddedInSwing) {
      this.swingBridge.notifyLoggedInStaffChanged(pin);
    }

    this.keypad.reset();

    if (this.attemptedTransition) {
      this.attemptedTransition.retry();
    }

    if (this.permissionPromise) {
      schedule('afterRender', () => {
        this.permissionPromise(true);
        this._clearState();
      });
    }

    return true;
  }

  @action
  switchUser() {
    return this.openPinPadAndSwitchUser({
      onCancel: this.switchUser,
    });
  }

  _clearState() {
    this.requiredPermission = null;
    this.attemptedTransition = null;
    this.permissionPromise = null;
  }
}

export const AVAILABLE_PERMISSIONS = {
  'appointments.cancel': 'AC_CANCEL_APPOINTMENT',
  'appointments.check-in': 'AC_ACTIVATE_APPOINTMENT',
  'appointments.copy': 'AC_CLIPBOARD',
  'appointments.delete': 'AC_DELETE_APPOINTMENT',
  'appointments.details.change-duration': 'AB_CHANGE_DURATION',
  'appointments.details.change-price': 'AB_CHANGE_PRICE',
  'appointments.details.create-client': 'AB_NEW_CLIENT',
  'appointments.details.remove-service': 'AB_DELETE_APPOINTMENT',
  'appointments.details.reschedule': 'AB_MOVE_APPOINTMENT',
  'appointments.details.swap-service': 'AB_CHANGE_SERVICE',
  'appointments.details.view': 'AB_VIEW_APPOINTMENT',
  'appointments.pay': 'AC_PAY_APPOINTMENT',
  'appointments.print-calendar': 'AC_PRINT_APPOINTMENT',
  'appointments.print-appointment-details': 'AB_PRINT_APPOINTMENT',
  'appointments.reschedule': 'AC_MOVE_APPOINTMENT',
  'appointments.view': 'AC_VIEW_CALENDAR',
  'appointments.view-client-contact-details': 'AB_CLIENT_CONTACT_DESKTOP',
  'appointments.view-history': 'AC_PREVIOUS_APPOINTMENTS',
  'breaks.edit': 'AC_MANAGE_BREAK',
  'business.view': 'MANAGER_BUSINESS',
  'cash-up.view': 'MANAGER_CASH_UP',
  'chain-courses.view': 'CHAIN_COURSES_VIEW',
  'chain-courses.edit': 'CHAIN_COURSES_MANAGE',
  'chain-library.view': 'MANAGER_CHAIN_LIBRARY',
  'chain-memberships.view': 'CHAIN_MEMBERSHIPS_VIEW',
  'chain-memberships.edit': 'CHAIN_MEMBERSHIPS_MANAGE',
  'chain-packages.view': 'CHAIN_PACKAGES_VIEW',
  'chain-packages.edit': 'CHAIN_PACKAGES_MANAGE',
  'chain-products.view': 'CHAIN_PRODUCTS_VIEW',
  'chain-products.edit': 'CHAIN_PRODUCTS_MANAGE',
  'chain-settings.edit': 'MANAGER_CHAIN_SETTINGS',
  'chain-special-offers.view': 'CHAIN_SPECIAL_OFFERS_VIEW',
  'chain-special-offers.edit': 'CHAIN_SPECIAL_OFFERS_MANAGE',
  'clients.booking-history': 'CI_SH_VIEW',
  'clients.bulk-archive': 'CL_CLIENT_BULK_ARCHIVE',
  'clients.communications': 'CI_EMAIL_SMS_HISTORY',
  'clients.contact-information.view-and-edit': 'CI_CONTACT',
  'clients.consultation-forms': 'CI_MEDICAL_HISTORY',
  'clients.consultations-forms.view': 'CONSULTATION_FORMS_CLIENT_CARD',
  'clients.courses.history.view': 'CI_COURSE_HISTORY',
  'clients.credit-terms': 'CI_CREDIT_TERMS',
  'clients.edit': 'CI_CONTACT',
  'clients.export': 'CI_EXPORT',
  'clients.forget': 'CI_FORGET',
  'clients.memberships.history.view': 'CI_MEMBERSHIP_HISTORY',
  'clients.merge': 'MANAGER_CLIENT_MERGE',
  'clients.notes': 'CI_NOTES',
  'clients.patch-tests': 'CI_PATCH_TEST',
  'clients.print-client-details': 'CI_PRINT_CLIENT_DETAILS',
  'clients.product-history': 'CI_PRODUCT_HISTORY',
  'clients.treat-card.edit-number': 'CI_CHANGE_LOYALTY_CARD_SERIAL',
  'clients.treat-card.edit-points': 'CI_ADD_LOYALTY_POINTS',
  'clients.treat-card.view': 'CI_LOYALTY_VIEW',
  'clients.view': 'CL_VIEW',
  'clients.view-contact-details': 'CI_CONTACT',
  'clients.view-service-history': 'CI_SERVICE_HISTORY',
  'clients.vouchers': 'CI_VOUCHERS',
  'client-courses.view': 'CLIENT_COURSE_VIEW',
  'client-courses.edit': 'CLIENT_COURSE_MANAGE',
  'client-memberships.view': 'CLIENT_MEMBERSHIP_VIEW',
  'client-memberships.edit': 'CLIENT_MEMBERSHIP_MANAGE',
  'commissions.view': 'COMMISSION_VIEW',
  'commissions.edit': 'COMMISSION_MANAGE',
  'courses.view': 'COURSE_VIEW',
  'courses.edit': 'COURSE_MANAGE',
  'csv.export': 'MBUS_CSV_EXPORT',
  login: 'WEB_LOGIN',
  'loyalty.view': 'MA_LOYALTY',
  'manager.view': 'NAV_MANAGER',
  'manager.reports': 'MANAGER_REPORTS',
  'manager.reports.view': 'REPORTS_VIEW',
  'manager.additional-reports.view': 'REPORTS_VIEW',
  'manager.settings': 'MANAGER_SETTINGS',
  'marketing.client-reconnect': 'MA_CLIENT_RECONNECT',
  'marketing.appointment-reminders': 'MA_APPOINTMENT_REMINDERS',
  'marketing.compose-email': 'MA_COMPOSE_EMAIL',
  'marketing.compose-sms': 'MA_COMPOSE_SMS',
  'marketing.treatcard-referral': 'MA_LOYALTY',
  'marketing.ads-manager': 'MA_META_ADS',
  'marketing.view': 'NAV_MARKETING',
  'marketing.welcome-email': 'MA_FACEBOOK',
  'memberships.view': 'MEMBERSHIP_VIEW',
  'memberships.edit': 'MEMBERSHIP_MANAGE',
  'memberships-billing.edit': 'MMSP_MEMBERSHIP_BILLING',
  'orders.view': 'ORDERS_VIEW',
  'orders.edit': 'ORDERS_MANAGE',
  'orders.delete': 'ORDERS_DELETE',
  'packages.view': 'PACKAGE_VIEW',
  'packages.edit': 'PACKAGE_MANAGE',
  'products.view': 'STOCK_VIEW',
  'products.edit': 'STOCK_MANAGE',
  'purchase.change-price': 'PUR_PRICE',
  'purchase.create': 'PUR_VIEW_PURCHASE',
  'purchase.professional': 'PUR_PROFESSIONAL',
  'purchase.walk-in': 'PUR_WALKIN',
  'purchase.petty-cash': 'PUR_SUNDRIES',
  'payroll-reports.view': 'REPORTS_PAYROLL_VIEW',
  'payroll-reports.view-multi-branch': 'REPORTS_PAYROLL_MULTISITE',
  'sales.petty-cash.view': 'SUNDRIES_VIEW',
  'sales.petty-cash.edit': 'SUNDRIES_MANAGE',
  'sales.till-floats.edit': 'TILL_FLOATS_MANAGE',
  'sales.till-floats.view': 'TILL_FLOATS_VIEW',
  'sales.till-transactions.details.more': 'TRANSACTIONS_SUPERVISOR_DELETE',
  'sales.till-transactions.view': 'TRANSACTIONS_VIEW',
  'sales.till-transactions.view-reprint-or-email-receipt':
    'TRANSACTIONS_RECEIPT',
  'sales.view': 'MANAGER_SALES',
  'settings.client-notifications.appointment-confirmation': 'MTF_CONFIRMATIONS',
  'special-offers.view': 'SPECIAL_OFFER_VIEW',
  'special-offers.edit': 'SPECIAL_OFFER_MANAGE',
  'stock-takes.view': 'STOCK_TAKE_VIEW',
  'stock-takes.edit': 'STOCK_TAKE_MANAGE',
  'stock-takes.apply': 'STOCK_TAKE_APPLY',
  'stock-transfers.view': 'STOCK_TRANSFER_VIEW',
  'stock-transfers.edit': 'STOCK_TRANSFER_MANAGE',
  'stock-transfers.apply': 'STOCK_TRANSFER_APPLY',
  'suppliers.view': 'SUPPLIERS_VIEW',
  'suppliers.edit': 'SUPPLIERS_MANAGE',
  'discounts.flat': 'DISCOUNTS_FLAT',
  'discounts.percent': 'DISCOUNTS_PERCENTAGE',
  'vouchers.view': 'MANAGER_VOUCHERS',
  'vouchers.edit': 'VOUCHERS_MANAGE',
  'vouchers.change-amount': 'VOUCHER_CHANGE_AMOUNT',
  'waitlist.add-booking': 'AB_VIEW_APPOINTMENT',
  'waitlist.delete-booking': 'AC_CANCEL_APPOINTMENT',
  'waitlist.remove-service': 'AB_DELETE_APPOINTMENT',
  'waitlist.add-service': 'AB_CHANGE_SERVICE',
};

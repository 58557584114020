export function initialize(app) {
  if (typeof window.electronAPI !== 'undefined') {
    const electronApp = app.lookup('service:electron-app');

    electronApp.api?.notifyDidBoot?.();
    electronApp.loadReceiptPrinter();
  }
}

export default {
  initialize,
};

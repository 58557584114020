import Service from '@ember/service';
import config from 'my-phorest/config/environment';
import { service } from '@ember/service';

export default class SpellcheckerService extends Service {
  @service('browser/window') window;

  get doesWebSpellCheckerExists() {
    return typeof this.window.WEBSPELLCHECKER !== 'undefined';
  }

  /**
   * Injects loading script for Webspellchecker SDK. We inject it asynchronously, on demand.
   * (not by injecting to every page as a typical way of doing these things)
   *
   */
  async injectWebSpellChecker() {
    if (!this.window.WEBSPELLCHECKER_CONFIG) {
      this.#webSpellCheckerConfig();
    }

    return new Promise(function (resolve) {
      (async function (d, s, id) {
        var js,
          wjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          resolve();
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.defer = true;
        js.crossOrigin = 'anonymous';
        js.src =
          'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js';
        wjs.parentNode.insertBefore(js, wjs);
        js.addEventListener('load', () => {
          resolve();
        });
      })(document, 'script', 'wsc-jssdk');
    });
  }

  #webSpellCheckerConfig() {
    this.window.WEBSPELLCHECKER_CONFIG = {
      autoSearch: true,
      autoDestroy: true,
      autocorrect: false,
      autocomplete: false,
      disableOptionsStorage: ['autocorrect', 'autocomplete'],
      serviceId: config.webSpellChecker.serviceId,
      serviceProtocol: 'https',
      servicePort: '443',
      serviceHost: 'svc.webspellchecker.net',
      servicePath: 'api',
    };
  }
}

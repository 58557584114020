import Service, { service } from '@ember/service';

const EXCLUDE_FROM_ROUTER_HISTORY = ['_loading', 'create', 'embed.app.mirror'];
const ROUTER_HISTORY_MAX_STACK = 10;

export default class RouteHistoryService extends Service {
  @service router;

  routeHistory = [];

  initialize() {
    this.router.on('routeDidChange', (transition) => {
      if (transition.isAborted) return;

      let isExcluded = EXCLUDE_FROM_ROUTER_HISTORY.some((route) =>
        transition.from?.name.includes(route)
      );

      let notSameRoute = transition.to?.name !== transition.from?.name;

      let isReplacing =
        transition.urlMethod === 'replace' &&
        !transition.isCausedByAbortingTransition;

      if (!isExcluded && !isReplacing && notSameRoute && transition.from) {
        if (this.routeHistory.length >= ROUTER_HISTORY_MAX_STACK) {
          this.routeHistory.shift();
        }

        this.routeHistory.push(transition.from);
      }
    });
  }

  get hasHistory() {
    return this.routeHistory.length > 0;
  }

  goToPreviousRoute() {
    if (!this.hasHistory) return;

    let previousRoute = this.routeHistory[this.routeHistory.length - 1];
    let route = '';
    let routeName = previousRoute.name;
    let params = previousRoute.parent?.params || '';
    let queryParams = previousRoute.parent?.queryParams;

    if (Object.keys(previousRoute.params).length !== 0) {
      params = previousRoute.params;
    }

    if (routeName) {
      if (Object.keys(params).length) {
        route = this.router.urlFor(routeName, params, {
          queryParams,
        });
      } else if (Object.keys(queryParams).length) {
        route = this.router.urlFor(routeName, {
          queryParams,
        });
      } else {
        route = this.router.urlFor(routeName);
      }

      this.router.replaceWith(route).then(() => {
        this.routeHistory.pop();
      });
    }
  }
}

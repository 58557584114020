import Service, { inject as service } from '@ember/service';
import { task, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { onErrorCode } from 'my-phorest/decorators/on-error-code';

const ABORT_POLLING = 'ABORT_POLLING';

export default class TerminalPaymentService extends Service {
  @service currencyFormatter;
  @service paymentSlideOver;
  @service router;
  @service errorHandler;

  @tracked showNotificationModal = false;
  @tracked showError = false;
  @tracked partialAmount;
  @tracked totalNumberOfCards;
  @tracked currentCardIndex;

  @tracked failureAction = null;
  VALID_FAILURE_ACTIONS = {
    TRY_AGAIN: 'TRY_AGAIN',
    CANCEL: 'CANCEL',
    CONVERT_CASH: 'CONVERT_CASH',
    CONVERT_ACCOUNT: 'CONVERT_ACCOUNT',
  };

  @task
  *handleNextCard(payments, basketId) {
    this.resetState();
    let integratedPayments = this.#integratedPayments(payments);
    const payment = this.#nextPaymentToProcess(integratedPayments);

    this.#setLabels(payments, payment);
    yield this.openModalAndTriggerPayment.perform(payment, basketId);
  }

  @task
  *openModalAndTriggerPayment(payment, basketId) {
    this.showNotificationModal = true;

    yield waitForProperty(this, 'showNotificationModal', false);
    yield this.paymentSlideOver.triggerTerminalPayment.perform(
      payment.id,
      basketId
    );
  }

  @action
  closeNotificationModal() {
    this.showNotificationModal = false;
  }

  @action
  isMultipleTerminalPayment(payments) {
    let terminalPayments =
      payments?.filter(
        (payment) =>
          payment.__typename === 'BasketIntegratedPayment' &&
          payment.isTerminalPayment
      ) ?? [];
    return terminalPayments.length > 1;
  }

  @action
  hasAnyStoredPayment(payments = []) {
    let integrated = this.#integratedPayments(payments);
    let stored = this.#nextPaymentToProcess(integrated);
    if (stored) return true;
    return false;
  }

  @action
  isPaymentConvertible(payment) {
    return payment.isConvertible;
  }

  @action
  setAction(action) {
    if (this.VALID_FAILURE_ACTIONS[action]) {
      this.failureAction = action;
      this.closeError();
    }
  }

  @action
  closeError() {
    this.showError = false;
  }

  @task
  *tryAgain(failedPayment, basket) {
    return yield this.paymentSlideOver.triggerTerminalPayment.perform(
      failedPayment.id,
      basket.id
    );
  }

  @task
  *cancel(failedPayment, basket) {
    return yield this.paymentSlideOver.cancelBasketCheckout.perform(basket);
  }

  @task
  *convertIntoCash(failedPayment, basket) {
    return yield this.convertFailedTerminalInto.perform(
      'CASH',
      failedPayment,
      basket
    );
  }

  @task
  *convertIntoAccount(failedPayment, basket) {
    return yield this.convertFailedTerminalInto.perform(
      'ACCOUNT',
      failedPayment,
      basket
    );
  }

  @task
  @onErrorCode('CREDIT_LIMIT_EXCEEDED', {
    *execute() {
      let error = arguments[arguments.length - 1];
      yield this.errorHandler.showErrorNotification(error);
      return ABORT_POLLING;
    },
  })
  *convertFailedTerminalInto(type, failedPayment, basket) {
    return yield this.paymentSlideOver.convertTerminalPayment.perform(
      basket.id,
      failedPayment.id,
      type,
      Number(failedPayment.amount.amount)
    );
  }

  @task
  *showErrorModal(failedPayment, basket) {
    this.#setLabels(basket.payments, failedPayment);
    this.showError = true;
    this.failureAction = null;

    yield waitForProperty(this, 'failureAction');

    const tasks = {
      [this.VALID_FAILURE_ACTIONS.TRY_AGAIN]: this.tryAgain,
      [this.VALID_FAILURE_ACTIONS.CANCEL]: this.cancel,
      [this.VALID_FAILURE_ACTIONS.CONVERT_CASH]: this.convertIntoCash,
      [this.VALID_FAILURE_ACTIONS.CONVERT_ACCOUNT]: this.convertIntoAccount,
    };

    let actionResponse = yield tasks[this.failureAction].perform(
      failedPayment,
      basket
    );

    this.resetState();
    return {
      shouldAbortPolling:
        this.VALID_FAILURE_ACTIONS.CANCEL === this.failureAction ||
        actionResponse === ABORT_POLLING,
    };
  }

  #setLabels(payments, currentProcessPayment) {
    let integratedPayments = this.#integratedPayments(payments);
    this.totalNumberOfCards = integratedPayments.length;
    let authorized = integratedPayments.filter(
      (p) => p.status === 'AUTHORISED'
    );
    this.currentCardIndex = authorized.length + 1;
    this.partialAmount = this.currencyFormatter.format(
      currentProcessPayment.amount
    );
  }

  #nextPaymentToProcess(payments) {
    return payments.find((payment) => payment.status === 'STORED');
  }

  #integratedPayments(payments) {
    return payments.filter((p) => p.__typename === 'BasketIntegratedPayment');
  }

  resetState() {
    this.totalNumberOfCards = null;
    this.currentCardIndex = null;
    this.partialAmount = null;
  }
}

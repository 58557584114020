import Service from '@ember/service';
import { action } from '@ember/object';

export default class NowIndicatorService extends Service {
  get timeIndicatorElement() {
    return document.querySelector(
      '[data-appointment-calendar-container] .ph-timegrid-now-indicator-line'
    );
  }

  get canScrollToIndicator() {
    return !!this.timeIndicatorElement;
  }

  @action
  scrollToIndicator() {
    if (this.canScrollToIndicator) {
      this.timeIndicatorElement.scrollIntoView({
        block: 'center',
      });
    }
  }
}

import { helper } from '@ember/component/helper';

export function nameInitials([name] /*, hash*/) {
  if (typeof name !== 'string') {
    return '';
  }

  let parts = name
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ');

  if (parts.length > 1) {
    let first = parts[0].charAt(0).toUpperCase();
    let second = parts[1].charAt(0).toUpperCase();
    return `${first}${second}`;
  } else {
    return name.substring(0, 2).toUpperCase();
  }
}

export default helper(nameInitials);

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import SuccessNotification from './-success';
import FailureNotification from './-failure';

export default class UiNotificationComponent extends Component {
  get notificationComponent() {
    let { type } = this.args.notification;
    return {
      success: SuccessNotification,
      failure: FailureNotification,
    }[type];
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AccountsIndexRoute extends Route {
  @service session;
  @service router;

  accountRoute = 'accounts.account';

  redirect() {
    let { lastAccessedAccountId, branchList } = this.session;
    if (branchList.length === 0) {
      return;
    }
    let [firstBranch] = branchList;
    let { accountId } = firstBranch;

    if (lastAccessedAccountId) {
      let lastAccessedBranch = branchList.find(
        (b) => b.accountId === lastAccessedAccountId
      );

      if (lastAccessedBranch) {
        accountId = lastAccessedBranch.accountId;
      } else {
        this.session.forgetLastAccessedAccountId();
      }
    }

    return this.router.transitionTo(this.accountRoute, accountId);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.branchList = this.session.branchList;
  }
}

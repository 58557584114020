/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { format as formatDate } from 'my-phorest/utils/local-date-helpers';
import { service } from '@ember/service';

export default class UiDatePickerComponent extends Component {
  @service intl;

  get displayValue() {
    if (isEmpty(this.args.selectedDate)) return '';

    if (this.args.multiSelect) {
      return this.intl.t('ui.date-picker.number-of-selected-days', {
        count: this.args.selectedDate.length,
      });
    } else {
      return formatDate(
        this.args.selectedDate,
        this.intl,
        this.args.displayedDateFormat ?? 'long'
      );
    }
  }

  @action
  clearDate(e) {
    e.preventDefault();
    this.args.clearDate?.();
  }
}

import { format as formatDate } from 'my-phorest/utils/local-date-helpers';
import {
  convertToLocalDate,
  convertToLocalTime,
  format as formatDatetime,
} from 'my-phorest/utils/local-datetime-helpers';

export default function formatTillSessionStartDate(intl, tillSession) {
  const { startDateTime } = tillSession;

  if (convertToLocalTime(startDateTime) === '00:00:00.000') {
    return formatDate(convertToLocalDate(startDateTime), intl, 'long');
  } else {
    return formatDatetime(startDateTime, intl, 'short');
  }
}

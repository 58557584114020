import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class DayAndTimePeriodsSlideOver extends Service {
  @tracked dayAndTimePeriods;

  @tracked isOpen = false;

  openWithDayAndTimePeriods(dayAndTimePeriods) {
    this.dayAndTimePeriods = dayAndTimePeriods;
    if (!dayAndTimePeriods.length) {
      this.addDayAndTimePeriod();
    }
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  get allPeriodsValid() {
    return this.dayAndTimePeriods.every((period) => period.days.length);
  }

  @action
  addDayAndTimePeriod() {
    this.dayAndTimePeriods = [
      ...this.dayAndTimePeriods,
      {
        days: [],
        allDay: true,
      },
    ];
  }

  @action
  setDaysOfWeek(dayAndTimePeriod, daysOfWeek) {
    const newPeriod = {
      ...dayAndTimePeriod,
      days: daysOfWeek,
    };

    this.#replaceDayAndTimePeriod(dayAndTimePeriod, newPeriod);
  }

  #replaceDayAndTimePeriod(dayAndTimePeriod, newPeriod) {
    this.dayAndTimePeriods = this.dayAndTimePeriods.map((period) =>
      period === dayAndTimePeriod ? newPeriod : period
    );
  }

  @action
  toggleAllDay(dayAndTimePeriod) {
    let newAllDay = !dayAndTimePeriod.allDay;
    const newPeriod = {
      ...dayAndTimePeriod,
      allDay: newAllDay,
      start: newAllDay ? undefined : '09:00:00.000',
      end: newAllDay ? undefined : '18:00:00.000',
    };

    this.#replaceDayAndTimePeriod(dayAndTimePeriod, newPeriod);
  }

  @action
  setFromTime(dayAndTimePeriod, time) {
    const newPeriod = {
      ...dayAndTimePeriod,
      start: time,
    };

    this.#replaceDayAndTimePeriod(dayAndTimePeriod, newPeriod);
  }

  @action
  setToTime(dayAndTimePeriod, time) {
    const newPeriod = {
      ...dayAndTimePeriod,
      end: time,
    };

    this.#replaceDayAndTimePeriod(dayAndTimePeriod, newPeriod);
  }

  @action
  removeDayAndTimePeriod(dayAndTimePeriod) {
    this.dayAndTimePeriods = this.dayAndTimePeriods.filter(
      (period) => period !== dayAndTimePeriod
    );
  }
}

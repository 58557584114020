import Modifier from 'ember-modifier';
import { createPopper } from '@popperjs/core';
import { registerDestructor } from '@ember/destroyable';
import { inject as service } from '@ember/service';

export default class Popper extends Modifier {
  @service popper;

  modify($element, [destination], { options, saveRefAs } = {}) {
    if (options.noop) return;

    let el = destination;
    if (typeof destination === 'string') {
      el = document.querySelector(destination);
    }
    this.instance = createPopper(el, $element, options);
    if (saveRefAs) {
      this.popper.saveRef(saveRefAs, this.instance);
    }

    registerDestructor(this, () => {
      this.instance.destroy();
      this.popper.removeRef(saveRefAs);
    });
  }
}

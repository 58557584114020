import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class FormatCurrency extends Helper {
  @service currencyFormatter;

  compute([value, maxDigits]) {
    return this.currencyFormatter.format(value, maxDigits);
  }
}

import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { asMoment, isSame } from 'my-phorest/utils/local-date-helpers';

const INTL_KEYS_PAST = {
  YEARS: 'global.years-ago',
  MONTHS: 'global.months-ago',
  YEARS_AND_MONTHS: 'global.years-and-months-ago',
};

const INTL_KEYS_FUTURE = {
  YEARS: 'global.years-away',
  MONTHS: 'global.months-away',
  YEARS_AND_MONTHS: 'global.years-and-months-away',
};

export default class extends Helper {
  @service intl;

  compute([dateString]) {
    if (!dateString) {
      return '';
    }

    let now = asMoment(new Date()).startOf('month');
    let then = asMoment(dateString).startOf('month');

    if (isSame(now, then)) {
      return this.intl.t('global.this-month');
    }

    let diffMonths = then.diff(now, 'months');
    let keys;

    if (diffMonths < 0) {
      diffMonths = Math.abs(diffMonths);
      keys = INTL_KEYS_PAST;
    } else {
      keys = INTL_KEYS_FUTURE;
    }

    if (diffMonths > 12) {
      const diffYears = Math.floor(diffMonths / 12);
      diffMonths = diffMonths % 12;

      if (diffMonths === 0) {
        return this.intl.t(keys.YEARS, {
          years: diffYears,
        });
      }

      return this.intl.t(keys.YEARS_AND_MONTHS, {
        months: diffMonths,
        years: diffYears,
      });
    }

    return this.intl.t(keys.MONTHS, {
      months: diffMonths,
    });
  }
}

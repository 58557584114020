import Service, { inject as service } from '@ember/service';
import {
  restartableTask,
  waitForProperty,
  keepLatestTask,
} from 'ember-concurrency';
import {
  getDatesForViewQuery,
  getCurrentStaffBusinessHours,
} from 'my-phorest/utils/calendar';

import branchTimeSlotsQuery from 'my-phorest/gql/queries/branch-time-slots.graphql';
import { queryManager } from 'ember-apollo-client';
import { action } from '@ember/object';

class SpecialDaysSelector {
  constructor(mapOfTimeSlots) {
    this.mapOfTimeSlots = mapOfTimeSlots;
  }

  #onlySpecialDays() {
    return Object.values(this.mapOfTimeSlots)
      .filter((timeSlots) => {
        return timeSlots.some((timeSlot) => timeSlot.isWorkingSpecialDay);
      })
      .flat();
  }

  get nonWorkingEntireDay() {
    return this.#onlySpecialDays().filter((day) => {
      return (
        day.startTime === '00:00' &&
        day.startTime === day.endTime &&
        day.type === 'NON_WORKING'
      );
    });
  }
}

export default class BranchOpeningHoursService extends Service {
  @service fullCalendar;
  @service session;

  @queryManager apollo;

  @keepLatestTask
  *getHoursTask(fetchInfo) {
    const view = this.fullCalendar.view;
    if (fetchInfo) {
      let variables = getDatesForViewQuery(view, fetchInfo);
      variables.branchId = this.session.branchId;

      const branchTimeSlots = yield this.apollo.query(
        { query: branchTimeSlotsQuery, variables },
        'branchTimeSlots'
      );
      return this.#branchTimeSlotsToOpeningHours(branchTimeSlots);
    }
  }

  @action
  applyCalendarBusinessHours(weekStartDate, weekEndDate) {
    this.#setBasicBusinessHours(weekStartDate, weekEndDate);

    this.setSpecialWorkingDays.perform(weekStartDate, weekEndDate);
  }

  get calendarApi() {
    return this.fullCalendar.calendarApi;
  }

  #branchTimeSlotsToOpeningHours(branchTimeSlots) {
    let hours = {};
    branchTimeSlots?.forEach((timeSlot) => {
      if (!hours[timeSlot.date]) {
        hours[timeSlot.date] = [];
      }

      hours[timeSlot.date].push(timeSlot);
    });
    return hours;
  }

  #setBasicBusinessHours(weekStartDate, weekEndDate) {
    const { viewSingleResource: calendarResource } = this.fullCalendar;
    if (calendarResource?.calendarDays) {
      let businessHours = getCurrentStaffBusinessHours(
        weekStartDate,
        weekEndDate,
        calendarResource
      );
      this.calendarApi.setOption('businessHours', businessHours);
    }
  }

  #overrideBusinessHoursWithSpecialDays(businessHours, specialDays) {
    specialDays.forEach((special) => {
      let found = businessHours.find((item) => item.date === special.date);
      if (found) {
        found.startTime = special.startTime;
        found.endTime = special.endTime;
        found.type = special.type;
      }
    });
  }

  @restartableTask
  *setSpecialWorkingDays(weekStartDate, weekEndDate) {
    const { viewSingleResource: calendarResource, isWeekView } =
      this.fullCalendar;

    if (calendarResource?.calendarDays && isWeekView) {
      yield waitForProperty(this.getHoursTask, 'lastSuccessful');
      let value = this.getHoursTask.lastSuccessful?.value;
      let specialDays = new SpecialDaysSelector(value);
      let businessHours = getCurrentStaffBusinessHours(
        weekStartDate,
        weekEndDate,
        calendarResource
      );

      this.#overrideBusinessHoursWithSpecialDays(
        businessHours,
        specialDays.nonWorkingEntireDay
      );

      this.calendarApi.setOption('businessHours', businessHours);
    }
  }
}

import { isTesting } from '@embroider/macros';

const TIMEOUT_FOR_TEST = 0;

/**
 * Returns a different duration of time when in the testing environment.
 * @param {number} timeout The duration used by dev and prod environments.
 * @param {number} [timeoutForTest] The duration used by test environment.
 * @returns {number}
 */
export default function timeoutForEnv(
  timeout,
  timeoutForTest = TIMEOUT_FOR_TEST
) {
  return isTesting() ? timeoutForTest : timeout;
}

import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { isNone } from '@ember/utils';
import decimalSymbolByLocale from 'my-phorest/utils/decimal-symbol-by-locale';

export default class ToLocaleString extends Helper {
  @service intl;

  compute([value]) {
    if (value == null) return '';
    const decimalSymbol = decimalSymbolByLocale(this.intl);
    let startsWithDecimalSymbol = new RegExp(`^[${decimalSymbol}]`, 'g');
    value = String(value).replace(startsWithDecimalSymbol, `0${decimalSymbol}`);

    if (value === '') {
      return value;
    }

    let [integer, fractional] = value.split('.');
    let integerFormatted = parseInt(integer);
    integerFormatted = integerFormatted.toLocaleString(this.intl.primaryLocale);

    if (isNone(fractional)) {
      return integerFormatted;
    } else {
      // Since we have to manually add the floating point based on the locale,
      // we need to get which character is it by using formatToParts
      return `${integerFormatted}${decimalSymbol}${fractional}`;
    }
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { initialize } from 'ember-launch-darkly';
import config from 'my-phorest/config/environment';
import {
  INTL_LOCALE_STORAGE_KEY,
  loadTranslations,
  prepareIntlLocale,
} from 'my-phorest/utils/intl';

export default class ApplicationRoute extends Route {
  @service errorHandler;
  @service intl;
  @service('browser/local-storage') localStorage;
  @service performance;
  @service revisionKey;
  @service('browser/window') window;
  @service versionTracker;

  async beforeModel() {
    this.errorHandler.initialize();

    const { deployRevision } = config.APP;
    const { clientSideId, ...rest } = config.launchDarkly;
    let user = {
      key: 'anonymous-user',
      anonymous: true,
      custom: {
        appVersion: deployRevision,
      },
    };

    await Promise.all([
      initialize(clientSideId, user, rest),
      this.#setIntlLocale(),
    ]);
    this.versionTracker.initialize();

    // Enable gathering performance metrics if `perfMode` query params is in the URL
    const queryParamsOnInit = new URLSearchParams(window.location.search);
    this.performance.enabled = queryParamsOnInit.has('perfMode');

    // Save revision key if `revision` query params is in the URL
    if (queryParamsOnInit.has('revision')) {
      this.revisionKey.key = queryParamsOnInit.get('revision');
    }
  }

  /**
   * Load user's language file on start up based on `intl-locale` info (if saved in localStorage) or load language
   * file based on browser language settings.
   *
   * @returns {Promise<void>}
   */
  async #setIntlLocale() {
    const [lang, countryCode] = this.window.navigator.language.split('-');
    const fallbackLocales = prepareIntlLocale({ lang, countryCode });

    let locales = this.localStorage.getItem(INTL_LOCALE_STORAGE_KEY);
    locales = locales ? JSON.parse(locales) : fallbackLocales;
    await loadTranslations(this.intl, locales);
    this.intl.setLocale(locales);
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import UiInputIntegerWithKeypadComponent from '../input-integer-with-keypad';

export default class UiInputNumberWithKeypadComponent extends UiInputIntegerWithKeypadComponent {
  @action
  openKeypad() {
    this.keypad.openKeypad({
      variant: 'number',
      headerText: this.args.headerText || this.intl.t('global.enter-amount'),
      value: this.args.amount,
      onChange: this.onKeypadChange,
    });
  }

  @action
  onSanitizedInput(amount) {
    let number = parseFloat(amount);
    this.args.onInput(
      isNaN(number) ? (this.args.allowNull ? null : 0) : number
    );
  }

  @action
  handleKeyDown(event) {
    if (event.key === '.' || event.key === ',') {
      if (this.#hasSeparator(event.target.value)) {
        event.preventDefault();
      }
    }
  }

  #hasSeparator(string) {
    return string.includes('.') || string.includes(',');
  }
}

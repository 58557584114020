import Modifier from 'ember-modifier';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import { cancel, later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

const DEFAULT_OPEN_DELAY_MS = 0;

export default class TooltipModifier extends Modifier {
  @service tooltip;

  elem;
  text;
  placement;
  styles;
  openDelayMs;
  timerId;

  @tracked disabled = false;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.destroyTooltip);
  }

  modify(
    element,
    positional,
    { text, placement = 'top', styles, openDelayMs, disabled }
  ) {
    this.elem = element;
    this.text = text;
    this.placement = placement;
    this.styles = styles;
    this.openDelayMs = openDelayMs;
    this.disabled = disabled;

    this.elem.addEventListener('mouseover', this.showTooltip);
    this.elem.addEventListener('mouseout', this.hideTooltip);
    this.elem.addEventListener('touchstart', this.showTooltip);
    this.elem.addEventListener('touchend', this.hideTooltip);
  }

  @action
  showTooltip() {
    if (!this.disabled) {
      let delay = this.openDelayMs ?? DEFAULT_OPEN_DELAY_MS;
      this.timerId = later(() => {
        this.tooltip.showTooltip(
          this.elem,
          this.text,
          this.placement,
          this.styles
        );
      }, delay);
    }
  }

  @action
  hideTooltip() {
    cancel(this.timerId);
    this.tooltip.hideTooltip();
  }

  @action
  destroyTooltip() {
    this.tooltip.hideTooltip();
    this.elem.removeEventListener('mouseover', this.showTooltip);
    this.elem.removeEventListener('mouseout', this.hideTooltip);
    this.elem.removeEventListener('touchstart', this.showTooltip);
    this.elem.removeEventListener('touchend', this.hideTooltip);
  }
}

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const NEW_ITEM = -1;

export default class ChainServiceGroupItemSlideOverService extends Service {
  @service notifications;
  @service intl;

  @tracked chainServiceGroupItem;
  itemIndex;

  @tracked isOpen = false;
  @tracked isServiceSelectionOpen = false;

  get isNewItem() {
    return this.itemIndex === NEW_ITEM;
  }

  openWithServiceGroupItem(chainServiceGroupItem, itemIndex) {
    this.chainServiceGroupItem = { ...chainServiceGroupItem };
    this.itemIndex = itemIndex;
    this.isOpen = true;
    this.isServiceSelectionOpen = false;
  }

  openWithNew() {
    this.chainServiceGroupItem = {
      options: [],
    };
    this.itemIndex = NEW_ITEM;
    this.isOpen = true;
    this.isServiceSelectionOpen = true;
  }

  close() {
    this.isOpen = false;
    this.isServiceSelectionOpen = false;
  }

  @action
  addOption() {
    this.isServiceSelectionOpen = true;
  }

  @action
  closeServiceSelection() {
    this.isServiceSelectionOpen = false;
    if (this.chainServiceGroupItem.options.length === 0) {
      this.isOpen = false;
    }
  }

  @action
  onServiceChoose(chainService) {
    if (this.#itemAlreadyContainsService(chainService)) {
      return this.notifications.failure(
        this.intl.t(
          'chain-service-group-item-slide-over.existing-service-error-title'
        ),
        this.intl.t(
          'chain-service-group-item-slide-over.existing-service-error-description'
        )
      );
    }

    this.chainServiceGroupItem = {
      ...this.chainServiceGroupItem,
      options: [
        ...this.chainServiceGroupItem.options,
        {
          chainService,
        },
      ],
    };

    this.isServiceSelectionOpen = false;
  }

  #itemAlreadyContainsService(chainService) {
    return this.chainServiceGroupItem.options.some(
      (option) => option.chainService?.id === chainService.id
    );
  }

  @action
  changeOptionPrice(option, price) {
    const newOption = {
      ...option,
      price,
    };

    this.#replaceOption(option, newOption);
  }

  @action
  onUpdatedStaffCategoryPrices(option, staffCategoryPrices) {
    const newOption = {
      ...option,
      staffCategoryPrices,
    };

    this.#replaceOption(option, newOption);
  }

  #replaceOption(option, newOption) {
    this.chainServiceGroupItem = {
      ...this.chainServiceGroupItem,
      options: this.chainServiceGroupItem.options.map((o) => {
        return o === option ? newOption : o;
      }),
    };
  }
}

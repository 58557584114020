import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ApplicationController extends Controller {
  @service keypad;
  @service electronApp;
  @service intl;

  @tracked electronAppVersion;

  constructor() {
    super(...arguments);

    if (this.electronApp.isRunningInElectron) {
      (async () => {
        this.electronAppVersion = await this.electronApp.getVersion();
      })();
    }
  }

  get appTitle() {
    if (this.electronApp.isRunningInElectron) {
      let title = this.intl.t('electron.title');

      if (this.electronAppVersion) {
        return title + ' ' + this.electronAppVersion;
      } else {
        return title;
      }
    } else {
      return this.intl.t('title');
    }
  }
}

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { ACCEPTED_ISSUES } from 'my-phorest/utils/calendar';
import { BOOKING_INTENT_TYPE, BookingIntent } from 'my-phorest/utils/planner';
import { fetchPolicy } from 'my-phorest/utils/enums/fetch-policy';
import ChainServiceWithServices from 'my-phorest/gql/queries/chain-service-with-services.graphql';
import serviceQuery from 'my-phorest/gql/queries/service.graphql';

export default class AppointmentPlannerService extends Service {
  @service appointmentSlideOver;
  @service appointmentTasks;
  @service errorHandler;
  @service packageTasks;
  @service router;
  @service session;

  @queryManager apollo;

  @tracked availableTimesForDate = null;
  @tracked bookingIntents = new TrackedArray([]); // services and packages selected to be booked
  @tracked bookingIntentContext;
  @tracked branchId = this.session.branchId;
  @tracked client = null;
  @tracked date = null;
  @tracked packageOptionIntentContext;
  @tracked staffMember = undefined;
  @tracked startTime = null;

  get isPlanningWithPackages() {
    return this.bookingIntents.some(
      (bookingIntent) => bookingIntent.type === BOOKING_INTENT_TYPE.PACKAGE
    );
  }

  get isSearchingInOtherBranch() {
    return (
      this.branchId !== this.session.branchId && !this.isPlanningWithPackages
    );
  }

  addBookingIntent(bookingIntent) {
    this.bookingIntents.push(bookingIntent);
    return bookingIntent;
  }

  close() {
    this.router.transitionTo('accounts.account.appointments');
  }

  createBookingIntent(obj) {
    return new BookingIntent(obj, this);
  }

  async getServiceIdForChainService(service) {
    if (this.isSearchingInOtherBranch) {
      const chainService = await this.apollo.query(
        {
          query: ChainServiceWithServices,
          variables: {
            id: service.parentId,
            fetchServicesPolicy: fetchPolicy.ACTIVE,
          },
        },
        'chainService'
      );
      const branchService = chainService.services.find(
        (s) => s.owningBranchId === this.branchId
      );
      return branchService?.id ?? null;
    } else {
      return service.id;
    }
  }

  openAppointmentSlideOver(appointments) {
    this.appointmentSlideOver.open({
      appointments,
      client: this.client,
      staffMember: this.staffMember,
      screen: 'appointment-details',
    });
  }

  removeBookingIntent(bookingIntent) {
    const index = this.bookingIntents.indexOf(bookingIntent);
    this.bookingIntents.splice(index, 1);
    this.bookingIntentContext = this.bookingIntents[0];
  }

  reset() {
    this.availableTimesForDate = null;
    this.bookingIntents = new TrackedArray([]);
    this.bookingIntentContext = undefined;
    this.branchId = this.session.branchId;
    this.client = null;
    this.date = null;
    this.staffMember = undefined;
    this.startTime = null;
    this.appointmentSlideOver.close();
  }

  @dropTask
  *createAppointmentsTask() {
    const clientId = this.client.id;
    const issues = ACCEPTED_ISSUES;
    const date = this.date;

    let createdAppointments = [];
    let leadingServiceSchedule;
    let serviceSchedulesAfterLeading;

    for (const bookingIntent of this.bookingIntents) {
      const index = this.bookingIntents.indexOf(bookingIntent);
      const serviceId =
        bookingIntent.type === BOOKING_INTENT_TYPE.SERVICE
          ? yield this.getServiceIdForChainService(bookingIntent.service)
          : bookingIntent.selectedPackageOptions[0].serviceId;

      let selectedTime;
      let staffMemberId;

      if (index === 0) {
        leadingServiceSchedule = this.availableTimesForDate.find(
          (s) => s.serviceId === serviceId && s.startTime === this.startTime
        );
        serviceSchedulesAfterLeading = this.availableTimesForDate.slice(
          this.availableTimesForDate.indexOf(leadingServiceSchedule) + 1
        );

        // First service is created at the time picked by a user
        selectedTime = this.startTime;
        staffMemberId = leadingServiceSchedule.staffMemberId;
      } else {
        // Other services are created at times proposed by Planner,
        // and with a Staff Member proposed by Planner based on multi-service split setting.
        let serviceSchedule = serviceSchedulesAfterLeading.find(
          (s) => s.serviceId === serviceId
        );
        selectedTime = serviceSchedule.startTime;
        staffMemberId = serviceSchedule.staffMemberId;
      }

      const appointment = {
        clientId,
        date,
        issues,
        selectedTime,
        serviceId,
        staffMemberId,
      };

      if (this.isSearchingInOtherBranch) {
        appointment.branchId = this.branchId;
      }

      if (bookingIntent.staffRequested) {
        appointment.staffRequested = bookingIntent.staffRequested;
      }

      if (bookingIntent.type === BOOKING_INTENT_TYPE.PACKAGE) {
        appointment.serviceGroupId = bookingIntent.selectedPackage.id;
        appointment.serviceGroupItemOptionIds =
          bookingIntent.selectedPackageOptions.map(
            (option) => option.serviceGroupItemOptionId
          );

        let response =
          yield this.appointmentTasks.createPackageAppointmentTask.perform(
            appointment
          );

        createdAppointments.push(...response.appointments);
      } else {
        let response =
          yield this.appointmentTasks.createAppointmentTask.perform(
            appointment
          );

        createdAppointments.push(response.appointment);
      }
    }

    return createdAppointments;
  }

  @dropTask
  *reloadBookingIntentTask(bookingIntent) {
    if (bookingIntent.type === BOOKING_INTENT_TYPE.PACKAGE) {
      return yield this.reloadPackageTask.perform(bookingIntent);
    } else {
      return yield this.reloadServiceTask.perform(bookingIntent);
    }
  }

  @dropTask
  *reloadPackageTask(bookingIntent) {
    try {
      const staffMemberContext = {
        categoryId: bookingIntent.staffMember.categoryId,
        userId: bookingIntent.staffMember.userId,
      };
      const packageObj = yield this.packageTasks.fetchPackageTask
        .linked()
        .perform(bookingIntent.selectedPackage.id, staffMemberContext);

      packageObj.serviceGroupOptions.forEach((optionGroup, index) => {
        const selectedPackageOption =
          bookingIntent.selectedPackageOptions[index];
        const newOption = optionGroup.find(
          (option) =>
            option.id === selectedPackageOption.serviceGroupItemOptionId
        );

        selectedPackageOption.option = newOption;
      });
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }

  @dropTask
  *reloadServiceTask(bookingIntent) {
    try {
      bookingIntent.service = yield this.apollo.query(
        {
          query: serviceQuery,
          variables: {
            id: bookingIntent.service.id,
            servicePriceContext: {
              staffCategoryId: bookingIntent.staffMember.categoryId,
            },
            serviceDurationContext: {
              userId: bookingIntent.staffMember.userId,
            },
          },
        },
        'service'
      );
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }
}

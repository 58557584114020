import {
  addDays,
  addWeeks,
  asWeekDay,
  datesBetween,
  firstOfMonth,
  isAfter,
  isBefore,
  lastOfMonth,
  month,
  subtractDays,
  WEEK_OF_MONTH,
  year,
} from './local-date-helpers';

export function getMonthlyWeekDayDetails(date) {
  const weekDay = asWeekDay(date, 'dddd');
  const datesInMonth = datesBetween(firstOfMonth(date), lastOfMonth(date), {
    inclusive: true,
  });

  let weekOfMonth;
  const position = datesInMonth.indexOf(date);
  if (position >= 0 && position < 7) {
    weekOfMonth = WEEK_OF_MONTH.first;
  } else if (position >= 7 && position < 14) {
    weekOfMonth = WEEK_OF_MONTH.second;
  } else if (position >= 14 && position < 21) {
    weekOfMonth = WEEK_OF_MONTH.third;
  } else if (position >= 21 && position < 28) {
    weekOfMonth = WEEK_OF_MONTH.fourth;
  } else if (position >= 28 && position < 31) {
    weekOfMonth = WEEK_OF_MONTH.last;
  } else {
    throw new Error(`Cannot get details for date: ${date}`);
  }

  return { weekDay, weekOfMonth };
}

export function calculateWeeklyRecurrenceEndDateFromUnits({
  start,
  units,
  unitType,
  appointmentDuration,
}) {
  // XXX only applicable for default recurrence of 1 day per week
  if (unitType === 'SESSIONS') {
    return addWeeks(start, units);
  } else if (unitType === 'MINUTES') {
    return addWeeks(start, Math.floor(units / appointmentDuration));
  }
}

export function generateRecurringDates({ start, recurrenceInput }) {
  if (!recurrenceInput) {
    return [];
  } else if (recurrenceInput.__typename === 'WeeklyRecurrenceInput') {
    return generateRecurringDatesFromWeekly({
      start,
      ...recurrenceInput,
    });
  } else if (recurrenceInput.__typename === 'MonthlyWeekDayRecurrenceInput') {
    return generateRecurringDatesFromMonthlyByWeekDay({
      start,
      ...recurrenceInput,
    });
  } else {
    return [];
  }
}

export function generateRecurringDatesFromWeekly({
  start,
  end,
  interval,
  weekDays,
}) {
  return datesBetween(start, end, { inclusive: true })
    .filter((date) => {
      return weekDays.includes(asWeekDay(date, 'dddd'));
    })
    .filter((_date, index) => {
      return index % interval === interval - 1;
    });
}

export function generateRecurringDatesFromMonthlyByWeekDay({
  start,
  end,
  interval,
  ordinal,
  weekDay,
}) {
  const allDatesInExtendedRange = datesBetween(
    firstOfMonth(start),
    lastOfMonth(end),
    {
      inclusive: true,
    }
  );

  const selectedWeekDays = allDatesInExtendedRange.filter((date) => {
    return weekDay === asWeekDay(date, 'dddd');
  });

  const groupedByMonth = selectedWeekDays.reduce((groups, date) => {
    const key = `${year(date)}-${month(date)}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(date);
    return groups;
  }, {});

  const ordinalDays = Object.values(groupedByMonth).map((list) => {
    if (ordinal === WEEK_OF_MONTH.first) {
      return list[0];
    } else if (ordinal === WEEK_OF_MONTH.second) {
      return list[1];
    } else if (ordinal === WEEK_OF_MONTH.third) {
      return list[2];
    } else if (ordinal === WEEK_OF_MONTH.fourth) {
      return list[3];
    } else if (ordinal === WEEK_OF_MONTH.last) {
      return list[list.length - 1];
    }
  });
  ordinalDays.sort();

  const datesInActualRange = ordinalDays.filter((date) => {
    return (
      isAfter(date, subtractDays(start, 1)) && isBefore(date, addDays(end, 1))
    );
  });

  const datesWithInterval = datesInActualRange.filter((_date, index) => {
    return index % interval === interval - 1;
  });

  return datesWithInterval;
}

import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';

import packageByIdQuery from 'my-phorest/gql/queries/package-by-id.graphql';

export default class PackageTasksService extends Service {
  @service session;
  @queryManager apollo;

  @task
  *fetchPackageTask(packageId, staffMemberContext) {
    const variables = {
      branchId: this.session.branchId,
      id: packageId,
    };

    const staffCategoryId = staffMemberContext?.categoryId;
    if (staffCategoryId) {
      variables.servicePriceContext = { staffCategoryId };
      variables.staffCategoryId = staffCategoryId;
    }

    const userId = staffMemberContext?.userId;
    if (userId) {
      variables.serviceDurationContext = { userId };
    }

    return yield this.apollo.query(
      {
        query: packageByIdQuery,
        variables,
      },
      'serviceGroup'
    );
  }
}

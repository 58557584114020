import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ClientSearchService extends Service {
  @tracked searchProperties = {};

  get isSearchPropertiesPresent() {
    const { firstName, lastName, mobile } = this.searchProperties;
    return firstName || lastName || mobile;
  }

  reset() {
    this.searchProperties = {};
  }
}

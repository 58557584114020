/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import * as yup from 'yup';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class ModalScanTreatCardComponent extends Component {
  @service scanTreatCardModal;
  @service intl;

  @tracked serialToValidate;

  get validationSchema() {
    return yup.object().shape({
      serial: yup
        .string()
        .required()
        .oneOf(
          [this.serial],
          this.intl.t('ui.modal.scan-treat-card.wrong-number')
        ),
    });
  }

  get serial() {
    return this.args.client?.treatCard?.serial;
  }

  get clientId() {
    return this.args.client?.id;
  }

  @action
  cleanUp(form) {
    this.serialToValidate = '';
    form.clearValidationMessages();
  }

  @action
  onSubmit() {
    this.scanTreatCardModal.addClientIdToPresentedCards(this.clientId);
    this.scanTreatCardModal.close();
  }

  @task
  *barcodeScanTask(form, barcode) {
    this.serialToValidate = barcode;
    yield form.validate();
  }
}

export const onErrorCode = function (errorCode, options = {}) {
  return function onErrorCodeDecorator(target, name, descriptor) {
    const original = descriptor.value;

    if (typeof original === 'function') {
      descriptor.value = function* (...args) {
        try {
          yield* original.bind(this)(...args);
        } catch (e) {
          if (e?.errors?.[0]?.extensions?.validationCode === errorCode) {
            let res = yield* options.execute.bind(this)(...args, e);
            if (options.then === 'retry') {
              yield* original.bind(this)(...args, e);
            }
            return res;
          } else {
            throw e;
          }
        }
      };
    }

    return descriptor;
  };
};

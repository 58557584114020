/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UiInputCurrencyWithKeypadComponent extends Component {
  @service keypad;
  @service intl;

  @tracked depositType = 'amount';

  get showPercentage() {
    if (!this.args.withPercentageOption) return false;

    return this.depositType !== 'amount';
  }

  @action
  updateDepositType(type) {
    if (type === this.depositType) {
      return;
    }
    this.depositType = type;
    this.args.updateDepositType(type);

    // Keep value of the input after changing the type
    if (type === 'amount') {
      let currencyAmount = (this.args.currencyAmount * 100).toFixed(2);
      this.args.onInput(currencyAmount);
    } else {
      let currencyAmount = this.args.currencyAmount * 0.01;
      this.args.onInput(currencyAmount);
    }
  }

  @action
  openKeypad() {
    if (this.depositType === 'amount') {
      this.keypad.openKeypad({
        value: this.args.currencyAmount || '0.00',
        headerText: this.intl.t('global.enter-amount'),
        variant: 'price',
        allowNull: this.args.allowNull,
        allowNegative: this.args.allowNegative || false,
        onChange: this.onKeypadChange,
      });
    } else {
      this.keypad.openKeypad({
        value: this.args.currencyAmount * 100 || '0',
        headerText: this.intl.t('global.enter-amount'),
        variant: 'percent',
        allowNull: this.args.allowNull,
        onChange: this.onKeypadChange,
      });
    }
  }

  @action
  onKeypadChange(value) {
    if (this.depositType === 'amount') {
      this.args.onInput(value?.amount);
    } else {
      this.args.onInput(value / 100);
    }
  }

  @action
  handleKeyUp(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.args.form?.submit(event);
    }
  }
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const NEW_CONTACT_IDX = -1;

export default class SupplierContactSlideOverService extends Service {
  @tracked contact;
  @tracked contactIndex;

  @tracked isOpen = false;

  get isNewContact() {
    return this.contactIndex === NEW_CONTACT_IDX;
  }

  openWithContact(contact, contactIndex) {
    this.contact = { ...contact };
    this.contactIndex = contactIndex;
    this.isOpen = true;
  }

  openWithNew() {
    this.contact = {};
    this.contactIndex = NEW_CONTACT_IDX;
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  @action
  changeContactField(fieldName, value) {
    this.contact = {
      ...this.contact,
      [fieldName]: value,
    };
  }
}

import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  convertToLocalDate,
  convertToLocalDatetime,
  convertToLocalTime,
  convertToTimeZone,
} from 'my-phorest/utils/local-datetime-helpers';

export default class BranchTimeService extends Service {
  @service session;
  @service intl;
  @service timers;

  @tracked now;

  constructor() {
    super(...arguments);
    this.updateCurrentTime();

    this.timers.registerTimer({
      action: () => {
        this.updateCurrentTime();
      },
      every: 1000,
      destroyable: this,
    });
  }

  updateCurrentTime() {
    const currentTime = new Date().toISOString();
    this.now = convertToTimeZone(currentTime, this.session.branchTimeZone);
  }

  get time() {
    return convertToLocalTime(this.now);
  }

  get today() {
    return convertToLocalDate(this.now);
  }

  get dateAndTime() {
    return convertToLocalDatetime(this.now);
  }
}

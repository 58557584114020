import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import { dropTask } from 'ember-concurrency';
import clientRewardsQuery from 'my-phorest/gql/queries/client-rewards.graphql';
import updateClientRewardsMutation from 'my-phorest/gql/mutations/update-client-rewards.graphql';

export default class LoyaltyRewardsService extends Service {
  @tracked clientRewards = [];
  @queryManager apollo;

  @dropTask
  *updateClientRewards(clientId, mutationInput) {
    return yield this.apollo.mutate({
      mutation: updateClientRewardsMutation,
      variables: { clientId: clientId, input: mutationInput },
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'clientRewards',
        });
        cache.gc();
      },
    });
  }

  @dropTask
  *fetchClientRewards(clientId) {
    const result = yield this.apollo.query({
      variables: { clientId: clientId },
      query: clientRewardsQuery,
      fetchPolicy: 'network-only',
    });
    return result.clientRewards;
  }
}
